import { Component, OnInit } from '@angular/core';
 
import { NgComponentOutlet } from '@angular/common';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms'; // Import FormsModule
 
import { HttpClient, HttpParams } from '@angular/common/http';
import { Globals } from '../../globals'; 

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [NgComponentOutlet,CommonModule,FormsModule],
  templateUrl: './signup.component.html' 
})
export class SignupComponent implements OnInit {

    isPostDataRoute: boolean = false;
    public name ='';
    public mobile =  '';
    public email =  '';
    public password = '';
    public apiUrl=''; 
    public responseMessage='';

    constructor(private http: HttpClient,private route: ActivatedRoute, private router: Router ,private globals: Globals) {}

    ngOnInit() {
        this.route.url.subscribe(url => {
            this.isPostDataRoute = this.router.url.includes('postdata');
        });
    }

    signup() {
        // Implement signup logic here
        console.log('Signup function called');
    }

    postData() {
        // Implement post data logic here
        console.log('PostData function called : '+this.name);

        const postData = {
            name: this.name,
            mobile: this.mobile,
            email: this.email,
            password: this.password
        };
      //  this.apiUrl = 'http://localhost:3000/api/user/signup';
      this.apiUrl = this.globals.APIURL+'/api/user/signup';
        this.http.post<any>(this.apiUrl, postData, {
            headers: { 'Content-Type': 'application/json' }
        })
        .subscribe(
            response => {
                // Store the response data in the component state
                console.log("Signup response : ", response);

                this.responseMessage = response.message; // Assume the response has a 'message' property
              //  res.status(200).json({ status: "alert",alert_type:"error",alert_head:"Registration Failed!", message:errormsg });

            },
            error => {
                console.error('An error occurred:', error);
                this.responseMessage = 'An error occurred during signup';
            }
        );
    }

    
}
