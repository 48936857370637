import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { Globals } from './globals';


@Component({
  selector: 'app-root',
  providers: [ Globals ],
  standalone: true,
  imports: [RouterOutlet],
  templateUrl: './app.component.html',
  styleUrl: './app.component.css'
}) 
export class AppComponent {
  title = 'gia-travel';

  constructor(public globals: Globals){}

}

