<section class="profile_section pb-5" style="background-color: #f1f1f1;">
    <main id="profile" class="site-main ">
        <div class="container mt-5">
            <!-- Profile Page Start -->
            <section class="section profile">
                <div   class="row">
                    <div class="col-xl-4"> 

                        <div *ngIf="profile.length > 0"  class="card">
                            <div class="card-body profile-card pt-4 d-flex flex-column align-items-center">

                            <img src="assets/images/Tour3.png" alt="Profile" class="rounded-circle">
                            <h2 >{{profile[0].name}}</h2>
                            <h3>{{sess_email}}</h3>
                            <!-- <div class="social-links mt-2">
                                <a href="#" class="twitter"><i class='bx bxl-twitter'></i></a>
                                <a href="#" class="facebook"><i class='bx bxl-facebook' ></i></a>
                                <a href="#" class="instagram"><i class='bx bxl-instagram' ></i></a>
                                <a href="#" class="linkedin"><i class='bx bxl-linkedin' ></i></a>
                            </div> -->
                            </div>
                        </div>

                    </div>

                    <div *ngIf="profile.length > 0" class="col-xl-8">

                        <div class="card">
                            <div class="card-body pt-3">
                            <!-- Bordered Tabs -->
                            <ul class="nav nav-tabs nav-tabs-bordered" role="tablist">

                                <li class="nav-item" role="presentation">
                                    <button class="nav-link active" data-bs-toggle="tab" data-bs-target="#profile-overview" aria-selected="true" role="tab">Overview</button>
                                </li>

                                <li class="nav-item" role="presentation">
                                    <button class="nav-link" data-bs-toggle="tab" data-bs-target="#profile-edit" aria-selected="false" role="tab" tabindex="-1">Edit Profile</button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link" data-bs-toggle="tab" data-bs-target="#profile-change-password" aria-selected="false" role="tab" tabindex="-1">Change Password</button>
                                </li>

                            </ul>
                            <div class="tab-content pt-2">

                                <div class="tab-pane fade profile-overview active show" id="profile-overview" role="tabpanel">
                                <h5 class="card-title mt-3 mb-3">Profile Details</h5>

                                <div class="row">
                                    <div class="col-lg-3 col-md-4 label ">User Name:</div>
                                    <div class="col-lg-9 col-md-8">{{profile[0].name}}</div>
                                </div>

                                <div class="row">
                                    <div class="col-lg-3 col-md-4 label">Email:</div>
                                    <div class="col-lg-9 col-md-8">{{sess_email}}</div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-3 col-md-4 label">Phone:</div>
                                    <div class="col-lg-9 col-md-8">{{profile[0].mobile}}</div>
                                </div>

                                <div class="row">
                                    <div class="col-lg-3 col-md-4 label">Country:</div>
                                    <div class="col-lg-9 col-md-8">USA</div>
                                </div>

                                <div class="row">
                                    <div class="col-lg-3 col-md-4 label">Address:</div>
                                    <div class="col-lg-9 col-md-8">{{profile[0].address}}</div>
                                </div>



                                </div>

                                <div class="tab-pane fade profile-edit pt-3" id="profile-edit" role="tabpanel">

                                <!-- Profile Edit Form -->
                                <form>
                                    <div class="row mb-3">
                                    <label for="profileImage" class="col-md-4 col-lg-3 col-form-label">Profile Image</label>
                                    <div class="col-md-8 col-lg-9 profile-card">
                                        <img src="assets/images/Tour3.png" alt="Profile">
                                        <div class="pt-2">
                                        <a href="#" class="btn btn-primary fs-5" title="Upload new profile image"><i class="fa-solid fa-upload" aria-hidden="true"></i></a>
                                        <a href="#" class="btn btn-danger fs-5" title="Remove my profile image"><i class="fa-solid fa-trash-can" aria-hidden="true"></i></a>
                                        </div>
                                    </div>
                                    </div>

                                    <div class="row mb-3">
                                    <label for="fullName" class="col-md-4 col-lg-3 col-form-label">User Name:*</label>
                                    <div class="col-md-8 col-lg-9">
                                        <div class="row mx-0">
                                            <div class="col-md-2 px-1">
                                                <div class="form-group">
                                                    <button class="btn form_toggle_c w-100" type="button" data-bs-toggle="dropdown" aria-expanded="false">Title <i class="bx bxs-chevron-down chev_d"></i></button>
                                                    <ul class="dropdown-menu">
                                                        <li><a class="dropdown-item" href="#">Mr.</a></li>
                                                        <li><a class="dropdown-item" href="#">Mrs.</a></li>
                                                        <li><a class="dropdown-item" href="#">Miss</a></li>
                                                        <li><hr class="dropdown-divider"></li>
                                                        <li><a class="dropdown-item" href="#">Ms.</a></li>
                                                    </ul> 
                                                    <script>
                                                        document.querySelectorAll('.dropdown-item').forEach(item => {
                                                            item.addEventListener('click', event => {
                                                                const selectedText = event.target.textContent;
                                                                const button = event.target.closest('.dropdown-menu').previousElementSibling;
                                                                button.innerHTML = selectedText + ' <i class="bx bxs-chevron-down chev_d"></i>';
                                                            });
                                                        });
                                                    </script>
                                                </div>
                                            </div>
                                            <div class="col-md-5 px-1">
                                                <input name="firstName" type="text" class="form-control" id="firstName" value="{{profile[0].name}}">
                                            </div>
                                            
                                        </div>
                                       
                                    </div>
                                    </div>

                                    <div class="row mb-3">
                                        <label for="email" class="col-md-4 col-lg-3 col-form-label">Email:</label>
                                        <div class="col-md-8 col-lg-9">
                                            <input name="email" type="text" class="form-control" id="email" value="{{sess_email}}">
                                        </div>
                                    </div>

                                    <div class="row mb-3">
                                        <label for="phone" class="col-md-4 col-lg-3 col-form-label">Phone:*</label>
                                        <div class="col-md-8 col-lg-9">
                                            <div class="row mx-0">
                                                <div class="col-md-6 px-1">
                                                    <input name="number" type="text" class="form-control" id="phone" value="{{profile[0].mobile}}">
                                                </div>
                                                <div class="col-md-6 px-1">
                                                    <span class="text-danger">Change Phone No.</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row mb-3">
                                        <label for="Job" class="col-md-4 col-lg-3 col-form-label">Address:*</label>
                                        <div class="col-md-8 col-lg-9">
                                            <div class="row mx-0">
                                                <div class="col-md-6 px-1 mb-2">
                                                        <div class="form-group">
                                                            <button class="btn form_toggle_c w-100" type="button" data-bs-toggle="dropdown" aria-expanded="false">Select Country <i class="bx bxs-chevron-down chev_d"></i></button>
                                                            <ul class="dropdown-menu">
                                                                <li><a class="dropdown-item" href="#">Afghanistan</a></li>
                                                                <li><a class="dropdown-item" href="#">Albania</a></li>
                                                                <li><a class="dropdown-item" href="#">Algeria</a></li>
                                                                <li><a class="dropdown-item" href="#">Brazil</a></li>
                                                                <li><a class="dropdown-item" href="#">Canada</a></li>
                                                                <li><a class="dropdown-item" href="#">Denmark</a></li>
                                                                <li><a class="dropdown-item" href="#">Egypt</a></li>
                                                                <li><a class="dropdown-item" href="#">Finland</a></li>
                                                                <li><a class="dropdown-item" href="#">India</a></li>
                                                                <li><a class="dropdown-item" href="#">Japan</a></li>
                                                                <li><a class="dropdown-item" href="#">Greece</a></li>
                                                                <li><a class="dropdown-item" href="#">United States</a></li>
                                                                <li><a class="dropdown-item" href="#">United Arab Emirates</a></li>
                                                                <li><a class="dropdown-item" href="#">United States</a></li>

                                                            </ul> 
                                                        </div>
                                                </div>  
                                                <div class="col-md-6 px-1 mb-2">
                                                        <div class="form-group">
                                                            <button class="btn form_toggle_c w-100" type="button" data-bs-toggle="dropdown" aria-expanded="false">Select State <i class="bx bxs-chevron-down chev_d"></i></button>
                                                            <ul class="dropdown-menu">
                                                                <li><a class="dropdown-item" href="#">State 1</a></li>
                                                                <li><a class="dropdown-item" href="#">State 2</a></li>
                                                                <li><a class="dropdown-item" href="#">State 3</a></li>
                                                                <li><a class="dropdown-item" href="#">State 4</a></li>
                                                                <li><a class="dropdown-item" href="#">State 5</a></li>
                                                                <li><a class="dropdown-item" href="#">State 6</a></li>
                                                                <li><a class="dropdown-item" href="#">State 7</a></li>
                                                            </ul> 
                                                        </div>
                                                </div>    
                                                <div class="col-md-6 px-1 mb-2">
                                                    <input name="city" type="text" class="form-control" id="city" value="{{profile[0].city}}">
                                                </div>   
                                                <div class="col-md-6 px-1 mb-2">
                                                    <input name="zipcode" type="text" class="form-control" id="zipcode" value="{{profile[0].zip_code}}">
                                                </div>   
                                                <div class="col-12 px-1">
                                                    <textarea name="address" class="form-control" id="address" style="height: 100px" placeholder="Enter Address">{{profile[0].address}}</textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row mb-3">
                                        <label for="Country" class="col-md-4 col-lg-3 col-form-label">My Subscriptions</label>
                                        <div class="col-md-8 col-lg-9">
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" id="smsalerts">
                                                <label class="form-check-label" for="smsalerts">
                                                    SMS Alerts
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" id="lowfare">
                                                <label class="form-check-label" for="lowfare">
                                                    Low Fare Alerts
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" id="detailsOnWhatsapp" checked="">
                                                <label class="form-check-label" for="detailsOnWhatsapp">
                                                    I would also like to receive my booking details on Whatsapp.
                                                    <i class="bx bxl-whatsapp text-success fs-5"></i>
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" id="subscribe" checked="">
                                                <label class="form-check-label" for="subscribe">
                                                    Subscribe to receive special promotions offers by Fly380.
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="text-center">
                                    <button type="submit" class="btn btn-primary">Save Changes</button>
                                    </div>
                                </form><!-- End Profile Edit Form -->

                                </div>
                                <div class="tab-pane fade pt-3" id="profile-change-password" role="tabpanel">
                                    <!-- Change Password Form -->
                                    <form>

                                        <div class="row mb-3">
                                        <label for="currentPassword" class="col-md-4 col-lg-3 col-form-label">Current Password</label>
                                        <div class="col-md-8 col-lg-9">
                                            <input name="password" type="password" class="form-control" id="currentPassword">
                                        </div>
                                        </div>

                                        <div class="row mb-3">
                                        <label for="newPassword" class="col-md-4 col-lg-3 col-form-label">New Password</label>
                                        <div class="col-md-8 col-lg-9">
                                            <input name="newpassword" type="password" class="form-control" id="newPassword">
                                        </div>
                                        </div>

                                        <div class="row mb-3">
                                        <label for="renewPassword" class="col-md-4 col-lg-3 col-form-label">Re-enter New Password</label>
                                        <div class="col-md-8 col-lg-9">
                                            <input name="renewpassword" type="password" class="form-control" id="renewPassword">
                                        </div>
                                        </div>

                                        <div class="text-center">
                                        <button type="submit" class="btn btn-primary">Change Password</button>
                                        </div>
                                    </form><!-- End Change Password Form -->

                                </div>

                            </div><!-- End Bordered Tabs -->

                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </div>
        <!-- Profile Page html end -->
    </main>  
</section>