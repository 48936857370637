import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Globals } from '../../globals'; 
import { AuthService } from '../services/auth.service';
import { NgComponentOutlet } from '@angular/common';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms'; // Import FormsModule
import { SessionService } from '../services/session.service';

@Component({
  selector: 'app-profile',
  standalone: true,
  imports: [NgComponentOutlet, CommonModule, FormsModule],
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  //profile: any = {};
  profile: any[] = [];
  error: string | null = null;
  isGetDataRoute: boolean = false;
  token: string | null = null;
  sess_email: string | null = null;
  public apiUrl = ''; 
  public email = '';
  private tokenKey = 'user-token';


  constructor(
    private sessionService: SessionService,
    private authService: AuthService,
    private http: HttpClient,
    private route: ActivatedRoute, 
    private router: Router,
    private globals: Globals, 
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  ngOnInit() {
    if (!this.sessionService.isAuthenticated()) {
      this.route.url.subscribe(url => {
        console.log("Welcome to profile");
        this.getProfileData();
      });
    } else {
      console.error("Not logged in");
    }
  }

  getProfileData() {
    if(!this.sessionService.isAuthenticated()) {
      console.log("Welcome to profile data");
      this.apiUrl = this.globals.APIURL + '/api/user/profile';
     // this.token = this.authService.getToken();
     this.token = this.sessionService.getToken();
      this.email = this.token as string;
      this.sess_email = this.token;
      console.log('apiUrl:', this.apiUrl); 
      console.log('Email:', this.sess_email); 

      if (this.email) {
        let params = new HttpParams().set('email', this.email);
        this.http.get(this.apiUrl, {
          headers: { Authorization: `Bearer ${this.sess_email}` },
          params: params,
          withCredentials: true
        }).subscribe(
          (response: any) => {
            
            this.profile =  Array.from(response.data);
            console.log('Profile Data:', this.profile);
          },
          (error) => {
            this.error = 'Failed to load profile details';
            console.error('Error:', error);
          }
        );
      } else {
        this.error = 'Invalid email or session token';
        console.error('Invalid email or session token');
      }
    } else {
      console.error("getProfileData not executed in the browser environment");
    }
  }
}
