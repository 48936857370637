<section class="section myBookings pt-5 min-vh-100" style="background-color: #f1f1f1;">
    <div class="container pb-5">
      <div class="myBookings_inner">
            <div class="">
                <h5 class="card-title mb-4">My Bookings</h5>
            </div>
            <!-- <div class="col-lg-3 col-md-4">
                <div class="card border-0">
                    <div class="card-body">
                        <ul class="list-unstyled pp_menu">
                            <li class="menu_for_p">
                                <a href="#" class="menu_for_p_item p-2 py-2 rounded-1 d-flex justify-content-between ali">
                                    My Profile
                                </a>
                            </li>
                            <li class="menu_for_p">
                                <a href="#" class="menu_for_p_item p-2 py-2 rounded-1 d-block">
                                    My Bookings
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div> -->
            <div class="">
                <div class="card border-0 mb-3">
                    <div class="card-body">
                        <div class="d-flex justify-content-between align-items-center mb-3">
                            <span class="fs-5">Filters</span>
                            <a href="#" class="clear_all_link">Clear All</a>
                        </div>
                        <form class="" method="">
                            <div class="category_filter">
                                
                                <div class="row">
                                    <div class="col-md-6">
                                        
                                        <div class="form-group">
                                            <label class="form-label d-block mb-2 text-muted">Category</label>
                                            <button class="btn form_toggle_c w-50" type="button" data-bs-toggle="dropdown" aria-expanded="false">Category <i class="fa-solid fa-chevron-down chev_d" aria-hidden="true"></i></button>
                                            <ul class="dropdown-menu">
                                                <li><a class="dropdown-item" href="#">Option 1</a></li>
                                                <li><a class="dropdown-item" href="#">Option 2</a></li>
                                                <li><a class="dropdown-item" href="#">Option 3</a></li>
                                                <li><hr class="dropdown-divider"></li>
                                                <li><a class="dropdown-item" href="#">Option 4</a></li>
                                            </ul> 
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <label class="form-label d-block mb-2 text-muted">&nbsp;</label>
                                        <div class="input-group position-relative">
                                            <button class="btn form_toggle_c rounded-start" type="button" data-bs-toggle="dropdown" aria-expanded="false">Dropdown <i class="fa-solid fa-chevron-down chev_d" aria-hidden="true"></i></button>
                                            <ul class="dropdown-menu">
                                                <li><a class="dropdown-item" href="#">Option 1</a></li>
                                                <li><a class="dropdown-item" href="#">Option 2</a></li>
                                                <li><a class="dropdown-item" href="#">Option 3</a></li>
                                                <li><hr class="dropdown-divider"></li>
                                                <li><a class="dropdown-item" href="#">Option 4</a></li>
                                            </ul>
                                            <input type="text" class="form-control custom_search rounded-end" aria-label="" placeholder="Search By PNR Number">
                                            <i class="fa-solid fa-magnifying-glass pos_seach" aria-hidden="true"></i>
                                        </div>

                                        <script>
                                            document.querySelectorAll('.dropdown-item').forEach(item => {
                                                item.addEventListener('click', event => {
                                                    const selectedText = event.target.textContent;
                                                    const button = event.target.closest('.dropdown-menu').previousElementSibling;
                                                    button.innerHTML = selectedText + ' <i class="fa-solid fa-chevron-down chev_d"></i>';
                                                });
                                            });
                                        </script>
                                    </div>
                                    <div class="col-12 mt-3">
                                        <div class="row filter_auto_col">
                                            <div class="col">
                                               
                                                <div class="form-group">
                                                    <label class="form-label d-block mb-2 text-muted">Status</label>
                                                    <button class="btn form_toggle_c w-100" type="button" data-bs-toggle="dropdown" aria-expanded="false">Booking Status <i class="fa-solid fa-chevron-down chev_d" aria-hidden="true"></i></button>
                                                    <ul class="dropdown-menu">
                                                        <li><a class="dropdown-item" href="#">Option 1</a></li>
                                                        <li><a class="dropdown-item" href="#">Option 2</a></li>
                                                        <li><a class="dropdown-item" href="#">Option 3</a></li>
                                                        <li><hr class="dropdown-divider"></li>
                                                        <li><a class="dropdown-item" href="#">Option 4</a></li>
                                                    </ul> 
                                                </div>
                                            </div>
                                            <div class="col form-group date_flat_picker">
                                                <div class="form-group_inner">
                                                    <label class="form-label d-block mb-2 text-muted">Trip Date Start</label>
                                                    <div class="date_flat_picker position-relative">
                                                        <input class="flatpickr form-control" type="text" name="calendar" placeholder="Select Date">
                                                        <i class="fa-regular fa-calendar-days" aria-hidden="true"></i>
                                                    </div>
                                                </div>      
                                            </div>
                                            <div class="col form-group">
                                                <div class="form-group_inner">
                                                    <label class="form-label d-block mb-2 text-muted">Trip Date End</label>
                                                    <div class="date_flat_picker position-relative">
                                                        <input class="flatpickr form-control" type="text" name="calendar" placeholder="Select Date">
                                                        <i class="fa-regular fa-calendar-days" aria-hidden="true"></i>
                                                    </div>
                                                </div>  
                                            </div>
                                            <div class="col">
                                                <div class="form-group_inner">
                                                        <label class="form-label d-block mb-2 text-muted">Booking Date Start</label>
                                                        <div class="date_flat_picker position-relative">
                                                            <input class="flatpickr form-control" type="text" name="calendar" placeholder="Select Date">
                                                            <i class="fa-regular fa-calendar-days" aria-hidden="true"></i>
                                                        </div>
                                                </div>   
                                            </div>
                                            <div class="col">
                                                <div class="form-group_inner">
                                                    <label class="form-label d-block mb-2 text-muted">Booking Date End</label>
                                                    <div class="date_flat_picker position-relative">
                                                        <input class="flatpickr form-control" type="text" name="calendar" placeholder="Select Date">
                                                        <i class="fa-regular fa-calendar-days" aria-hidden="true"></i>
                                                    </div>
                                                </div>   
                                            </div>
                                        </div>
                                    </div>

                                </div>
                               
                            </div>
                        </form>
                        
                    </div>
                </div>
                <div class="d-flex justify-content-between align-items-center mb-3">
                    <h5 class="card-title">Recent Bookings</h5>
                    <a href="#" class="d_csv d-flex align-items-center d-block fs-6">
                        <i class="fa-solid fa-download me-2" aria-hidden="true"></i>
                        <span class="">Download PDF</span>
                    </a>
                </div>
                <div class="table-responsive">
                    <table class="table recent_bookings_table align-middle bdr">
                        <thead class="">
                            <tr>
                                <th scope="col">Booking Code</th>
                                <th scope="col">Lead Pax Name</th>
                                <th scope="col">Date of Travel</th>
                                <th scope="col">Destination</th>
                                <th scope="col">Booking Status</th>
                                <th scope="col">Invoice</th>
                                <th scope="col">Amount</th>
                                <th scope="col">Download</th>
                            </tr>
                        </thead>
                        <tbody class="bg-white">

                            <tr *ngFor="let book of booking; let j = index;">
                                <td>{{book.id}}</td>
                                <td>{{book.name}}</td>
                                <td>{{book.travel_date}}</td>
                                <td>{{book.source}} - {{book.destination}}
                                    <small class="d-block">Flight</small>
                                </td>
                                <td><span class="text-success">Pending</span></td>
                                <td>
                                    <a href="#" class="d-flex align-items-center text-center text-danger" title="Download Invoice PDF">
                                        <i class="fa-solid fa-file-pdf fs-5 me-2" aria-hidden="true"></i> <span class="">Download</span>
                                    </a>
                                </td>
                                <td>£{{book.TotalFare}}</td>
                                <td>
                                    <!-- <div class="d-flex align-items-center"> -->
                                        <a href="#" class="fs-3 d-block text-center"><i class="fa-solid fa-download" aria-hidden="true"></i></a>
                                    <!-- </div> -->
                                </td>
                            </tr>


                             
                        </tbody>
                    </table>
                </div>
            </div>
      </div>
    </div>
</section>