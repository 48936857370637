import { Routes } from '@angular/router';

import { HotelComponent } from './components/hotel/hotel.component';
import { HotelDetailsComponent } from './components/hotel/hotel-details.component';

import { HomeComponent } from './components/home/home.component';

import { FlightComponent } from './components/flight/flight.component';
import { FlightDetailsComponent } from './components/flight/flight-details.component';

import { PageComponent } from './components/page/page.component';

import { LoginComponent } from './components/login/login.component';
import { SignupComponent } from './components/login/signup.component';

import { ProfileComponent } from './components/profile/profile.component';

import { BookingComponent } from './components/booking/booking.component';




//import { AppComponent } from './app.component';


export const routes: Routes = [
{path: '', component: HomeComponent },    
{path: 'hotel', component: HotelComponent},
{path: 'hotel-details', component: HotelDetailsComponent},

{path: 'flight', component: FlightComponent},
{path: 'flight-details', component: FlightDetailsComponent},
{path: 'page', component: PageComponent},

{path: 'login', component: LoginComponent},

{path: 'signup', component: SignupComponent},
{path: 'signup/postdata', component: SignupComponent},
{path: 'profile', component: ProfileComponent},
{path: 'booking', component: BookingComponent},


];
