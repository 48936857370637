import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Globals } from '../../globals'; 
@Injectable({
  providedIn: 'root'
})
export class FlightService {
  constructor(private http: HttpClient,private globals: Globals) { }
  private apiUrl = this.globals.APIURL+'/api/flightsearch';
 // private apiUrl = 'http://localhost:3000/api/flightsearch';
// private apiUrl ='';
  // constructor(private http: HttpClient,private globals: Globals) {  }
  //constructor(private http: HttpClient) {  }
  

  getFlights(flightFrom : string,flightTo : string, journeyType:string, f_departureDate:string,f_returnDate:string,f_adult:string,f_infant:string,f_child:string,f_youth:string,f_class:string): Observable<any[]> {
    const url = `${this.apiUrl}?flight_from=${flightFrom}&flight_to=${flightTo}&journeyType=${journeyType}&f_departureDate=${f_departureDate}&f_returnDate=${f_returnDate}&f_adult=${f_adult}&f_infant=${f_infant}&f_child=${f_child}&f_youth=${f_youth}&f_class=${f_class}`;

    const responseData=this.http.get<any[]>(url);
    console.log("Response : "+JSON.stringify(responseData));
   // return this.http.get<any[]>(url);
   return responseData;
  }

  getFlightPrice(journeyType : string,Key:string, token:string,supp:string,OptionKeyList:string,f_adult:string,f_infant:string,f_child:string,f_youth:string): Observable<any> {
    
    const DATA = {
      // Add your request payload here
  "TripType":`${journeyType}`,
  "Key" :`${Key}`,
  "OutBoundKey":"outgal",
  "InboundKey":"ingal",
  "CompanyCode":"BS7494",
  "WebsiteName":"giatravel.co.uk",
  "ApplicationAccessMode":"TEST",
  "Token":`${token}`,
  "Supp":`${supp}`,
  "IsFlexibleDate": false,
  "OptionKeyList" :[`${OptionKeyList}`],
  "NoOfAdultPax":`${f_adult}`,
  "NoOfInfantPax":`${f_infant}`,
  "NoOfChildPax":`${f_child}`,
  "NoOfYouthPax":`${f_youth}`,
  "AccountCode":"BS7494"
  
   };

    //const URL = 'Your_API_URL_here';
const UserName = 'BS7494';
const Password = '#b7s494@giatravelTest';
//const DATA = 'Your_JSON_Data';

//const authString = Buffer.from(`${UserName}:${Password}`).toString('base64');
const authString = btoa(`${UserName}:${Password}`);
    const jsonString = JSON.stringify(DATA);
    const encoder = new TextEncoder();
    const byteLength = encoder.encode(jsonString).length;

const headers = new HttpHeaders({
  'Authorization': `Basic ${authString}`,
        'Content-Type': 'application/json',
        'Content-Length': byteLength
});
    const url1 = 'https://api.brightsun.co.uk/api/BSFlight/flightprice';

    console.log("Flight price Request:",DATA);    
    return this.http.post<any>(url1, DATA,{ headers: headers });
  }


}
