 <!---New Design Start-->    
 <!-- {{  jsonString }} -->
 
 <div  class="body flightDetails">
    <div class="titleBar footer-bottom">
        <div class="container">
            <h2>Confirm Details + Book
                <span class="pull-right">
                    <!-- <button (click)="goBack()">Back</button> -->

                   
                    <a href="/flight?f_class=Economy&f_infant={{f_infant}}&f_child={{f_child}}&f_adult={{f_adult}}&f_youth=0&airport_name1={{airport_name1}}&airport_name2={{airport_name2}}&airport_city1={{airport_name1}}&airport_city2={{airport_name2}}&flight_from={{flight_from}}&flight_to={{flight_to}}&f_departureDate={{f_departureDate}}&f_returnDate={{f_returnDate}}&journeyType={{journeyType}}"
                        style="color:#ffffff" class="backtoflightpage">Back to flight result</a></span>
            </h2>
        </div>
    </div>
    <div class="container mt-5" style="display:none">
        <div class="row">
            <div class="col-lg-9 col-sm-12 col-xs-12">
                <div id="FlightDetails">
                    <style type="text/css">
                        .ac_results {
                            padding: 0;
                            border: 1px solid black;
                            background-color: white;
                            overflow: hidden;
                            z-index: 9999;
                            width: 500px !important;
                            position: absolute;
                        }
                        .ac_results ul {
                            width: 100%;
                            list-style-position: outside;
                            list-style: none;
                            padding: 0;
                            margin: 0;
                            box-shadow: 0 0 1px 1px #CCC;
                            background-color: #fff;
                        }
                        .ac_results li {
                            margin: 0;
                            cursor: default;
                            display: block;
                            font: menu;
                            font-size: 14px;
                            overflow: hidden;
                        }
                        .ac_odd {
                            float: left;
                            width: 100%;
                            background: #FFF url("../../Content/images/autocompleteicon/loc1.png") no-repeat scroll 3% 49%;
                            color: #063553;
                            font: 15px/25px DinMedium;
                            padding: 10px 20px 10px 31px;
                            margin-top: 5px;
                        }
                        .ac_even {
                            float: left;
                            width: 100%;
                            padding: 6px 6px 6px 40px;
                            font: 15px DinMedium;
                            color: #0A6473;
                            background: #FFF url("../../Content/images/autocompleteicon/plane1.png") no-repeat scroll 5% 40%;
                        }
                        .ac_over {
                            background-color: #4b9799;
                            color: #ffffff;
                        }
                    </style>
                    <div class="flightDetailsBox wow fadeInUp" style="visibility: visible; animation-name: fadeInUp;">
                        <h3>Flight Details</h3>
                        <div class="flight-info">
                            <h5 class="">Outbound Flight - Economy Value</h5>
                            <div class="airlineLogo">
                                <img src="images/SV.png" alt="logo">
                            </div>
                            <div class="timimgDetails">
                                <div class="box">
                                    <div class="box-inner-one">
                                        <h4>Flight</h4>
                                        <p>SV 106</p>
                                    </div>
                                    <div class="box-inner-two">
                                        <h4>From</h4>
                                        <p>Heathrow (LHR) Terminal: 4</p>
                                    </div>
                                    <div class="box-inner-three">
                                        <h4>Departs </h4>
                                        <p>Sat 13 Jul 2024 <span class="time">21:45</span></p>
                                    </div>
                                </div>
                                <div class="box">
                                    <div class="box-inner-one">
                                        <h4>Class</h4>
                                        <p>Economy</p>
                                    </div>
                                    <div class="box-inner-two">
                                        <h4>To</h4>
                                        <p>Dubai Intl Arpt (DXB) </p>
                                    </div>
                                    <div class="box-inner-three">
                                        <h4>Arrival </h4>
                                        <p>Sun 14 Jul 2024 <span class="time">13:00</span></p>
                                    </div>
                                </div>
                                <p class="bagageDetails">
                                    Adult Baggage Allowance : 1 PC
                                </p>
                            </div>
                        </div>
                        <div class="flight-info">
                            <h5>Return Flight - Economy Value</h5>
                            <div class="airlineLogo">
                                <img src="images/SV.png" alt="logo">
                            </div>
                            <div class="timimgDetails">
                                <div class="box">
                                    <div class="box-inner-one">
                                        <h4>Flight</h4>
                                        <p>SV 599</p>
                                    </div>
                                    <div class="box-inner-two">
                                        <h4>From</h4>
                                        <p>Dubai Intl Arpt (DXB) Terminal: 1</p>
                                    </div>
                                    <div class="box-inner-three">
                                        <h4>Departs </h4>
                                        <p>Tue 16 Jul 2024 <span class="time">04:20</span></p>
                                    </div>
                                </div>
                                <div class="box">
                                    <div class="box-inner-one">
                                        <h4>Class</h4>
                                        <p>Economy</p>
                                    </div>
                                    <div class="box-inner-two">
                                        <h4>To</h4>
                                        <p>Gatwick Arpt (LGW) </p>
                                    </div>
                                    <div class="box-inner-three">
                                        <h4>Arrival </h4>
                                        <p>Tue 16 Jul 2024 <span class="time">13:10</span></p>
                                    </div>
                                </div>
                                <p class="bagageDetails">
                                    Adult Baggage Allowance : 1 PC
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="flight-price-summary wow fadeInUp"
                        style="visibility: visible; animation-name: fadeInUp;">
                        <h3>Pricing Summary</h3>
                        <div class="pricingsummery p-3">
                            <h5>Round Trip from London to Dubai </h5>
                            <div class="pricingList">
                                <div class="row-custom new-custome">
                                    <div class="box-outer">
                                        <div class="bo-text bo-text1">Passenger</div>
                                        <div class="bo-text bo-text2"><i class="fas fa-user"></i> &nbsp;1 Adult</div>
                                    </div>
                                    <div class="box-outer">
                                        <div class="bo-text bo-text1">Fare PP</div>
                                        <div class="bo-text">£ 205.00</div>
                                    </div>
                                    <div class="box-outer">
                                        <div class="bo-text bo-text1">Taxes PP</div>
                                        <div class="bo-text">£ 322.59</div>
                                    </div>
                                    <div class="box-outer">
                                        <div class="bo-text bo-text1">Total PP</div>
                                        <div class="bo-text">£ 527.59</div>
                                    </div>
                                    <div class="box-outer">
                                        <div class="bo-text bo-text1">Subtotal</div>
                                        <div class="bo-text">£ 527.59</div>
                                    </div>
                                </div>
                                <div class="subtotal">
                                    <p>Total Flight Cost for 1 Passenger <span class="grandTotal">£ 527.59</span></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Preview-->
                    <div class="modal fade preview-popup" id="preview" tabindex="-1" role="dialog"
                        aria-labelledby="myModalLabel">
                        <div class="modal-dialog" role="document">
                            <div class="modal-content" style="float: left">
                                <div class="modal-body">
                                    <div class="paxInfo modal-design">
                                        <h3>Preview</h3> <button type="button" class="close" data-dismiss="modal"
                                            aria-label="Close"><span aria-hidden="true">×</span></button>
                                        <div class="paxDetails">
                                            <div class="flightDetailsBox wow fadeInUp animated"
                                                style="visibility: visible; animation-name: fadeInUp;">
                                                <h3>Flight Details</h3>
                                                <div class="flight-info">
                                                    <h5>Return Flight - Economy Value</h5>
                                                    <div class="airlineLogo">
                                                        <img src="images/SV.png" alt="logo">
                                                    </div>
                                                    <div class="timimgDetails">
                                                        <div class="box">
                                                            <div class="box-inner-one">
                                                                <h4>Flight</h4>
                                                                <p>SV 106</p>
                                                            </div>
                                                            <div class="box-inner-two">
                                                                <h4>From</h4>
                                                                <p>Heathrow (LHR) Terminal: 4</p>
                                                            </div>
                                                            <div class="box-inner-three">
                                                                <h4>Departs </h4>
                                                                <p>Sat 13 Jul 2024 <span class="time">21:45</span></p>
                                                            </div>
                                                        </div>
                                                        <div class="box">
                                                            <div class="box-inner-one">
                                                                <h4>Class</h4>
                                                                <p>Economy</p>
                                                            </div>
                                                            <div class="box-inner-two">
                                                                <h4>To</h4>
                                                                <p>Dubai Intl Arpt (DXB) </p>
                                                            </div>
                                                            <div class="box-inner-three">
                                                                <h4>Arrival </h4>
                                                                <p>Sun 14 Jul 2024 <span class="time">13:00</span></p>
                                                            </div>
                                                        </div>
                                                        <p class="bagageDetails">
                                                            Adult Baggage Allowance : 1 PC
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="flight-info">
                                                    <h5>Return Flight - Economy Value</h5>
                                                    <div class="airlineLogo">
                                                        <img src="images/SV.png" alt="logo">
                                                    </div>
                                                    <div class="timimgDetails">
                                                        <div class="box">
                                                            <div class="box-inner-one">
                                                                <h4>Flight</h4>
                                                                <p>SV 599</p>
                                                            </div>
                                                            <div class="box-inner-two">
                                                                <h4>From</h4>
                                                                <p>Dubai Intl Arpt (DXB) Terminal: 1</p>
                                                            </div>
                                                            <div class="box-inner-three">
                                                                <h4>Departs </h4>
                                                                <p>Tue 16 Jul 2024 <span class="time">04:20</span></p>
                                                            </div>
                                                        </div>
                                                        <div class="box">
                                                            <div class="box-inner-one">
                                                                <h4>Class</h4>
                                                                <p>Economy</p>
                                                            </div>
                                                            <div class="box-inner-two">
                                                                <h4>To</h4>
                                                                <p>Gatwick Arpt (LGW) </p>
                                                            </div>
                                                            <div class="box-inner-three">
                                                                <h4>Arrival </h4>
                                                                <p>Tue 16 Jul 2024 <span class="time">13:10</span></p>
                                                            </div>
                                                        </div>
                                                        <p class="bagageDetails">
                                                            Adult Baggage Allowance : 1 PC
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="flight-price-summary wow fadeInUp animated"
                                                style="visibility: visible; animation-name: fadeInUp;">
                                                <h3>Pricing Summary</h3>
                                                <div class="pricingsummery">
                                                    <h5>Round Trip from London to Dubai </h5>
                                                    <div class="pricingList">
                                                        <div class="row-custom heading">
                                                            <div class="box"></div>
                                                            <div class="box">Fare PP</div>
                                                            <div class="box">Taxes PP</div>
                                                            <div class="box">Extras PP</div>
                                                            <div class="box">Total PP</div>
                                                            <div class="box">Travellers</div>
                                                            <div class="box">Subtotal</div>
                                                        </div>
                                                        <div class="row-custom">
                                                            <div class="box title"><i class="fas fa-user"></i> 1 Adult
                                                            </div>
                                                            <div class="box">£ 205.00 </div>
                                                            <div class="box">£ 322.59</div>
                                                            <div class="box">£ 0.00</div>
                                                            <div class="box">£ 527.59</div>
                                                            <div class="box">1</div>
                                                            <div class="box">£ 527.59</div>
                                                        </div>
                                                        <div class="subtotal">
                                                            <p>Total Flight Cost for 1 Passenger <span
                                                                    class="grandTotal">£ 527.59</span></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flight-price-summary paxInfo wow fadeInUp paxdtl"
                    style="visibility: visible; animation-name: fadeInUp;">
                    <h3>Traveller Details</h3>
                    <div class="paxDetails p-3">
                        <p class="note">Note: Enter your name as it is mentioned on your passport or any government
                            approved ID.</p>
                        <div>
                            <h5>Adult 1</h5>
                            <div class="row">
                                <div class="col-sm-2 col-xs-12">
                                    <select class="form-control" id="ddlATitle_1">
                                        <option value="Mr.">Mr.</option>
                                        <option value="Mrs.">Mrs.</option>
                                        <option value="Dr.">Dr.</option>
                                        <option value="Miss">Miss</option>
                                    </select>
                                </div>
                                <div class="col-sm-5 col-xs-12">
                                    <input type="text" class="form-control clsName" id="txtAFName_1"
                                        placeholder="First Name">
                                </div>
                                <div class="col-sm-5 col-xs-12">
                                    <input type="text" class="form-control clsName" id="txtALName_1"
                                        placeholder="Last Name">
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-3 col-xs-12">
                                    <select class="form-control" id="txtADay_1">
                                        <option value="0">Day</option>
                                        <option>01</option>
                                        <option>02</option>
                                        <option>03</option>
                                        <option>04</option>
                                        <option>05</option>
                                        <option>06</option>
                                        <option>07</option>
                                        <option>08</option>
                                        <option>09</option>
                                        <option>10</option>
                                        <option>11</option>
                                        <option>12</option>
                                        <option>13</option>
                                        <option>14</option>
                                        <option>15</option>
                                        <option>16</option>
                                        <option>17</option>
                                        <option>18</option>
                                        <option>19</option>
                                        <option>20</option>
                                        <option>21</option>
                                        <option>22</option>
                                        <option>23</option>
                                        <option>24</option>
                                        <option>25</option>
                                        <option>26</option>
                                        <option>27</option>
                                        <option>28</option>
                                        <option>29</option>
                                        <option>30</option>
                                        <option>31</option>
                                    </select>
                                </div>
                                <div class="col-sm-3 col-xs-12">
                                    <select class="form-control" id="txtAMonth_1">
                                        <option value="0">Month</option>
                                        <option>01</option>
                                        <option>02</option>
                                        <option>03</option>
                                        <option>04</option>
                                        <option>05</option>
                                        <option>06</option>
                                        <option>07</option>
                                        <option>08</option>
                                        <option>09</option>
                                        <option>10</option>
                                        <option>11</option>
                                        <option>12</option>
                                    </select>
                                </div>
                                <div class="col-sm-3 col-xs-12">
                                    <select class="form-control" id="txtAYear_1">
                                        <option value="0">Year</option>
                                        <option>2008</option>
                                        <option>2007</option>
                                        <option>2006</option>
                                        <option>2005</option>
                                        <option>2004</option>
                                        <option>2003</option>
                                        <option>2002</option>
                                        <option>2001</option>
                                        <option>2000</option>
                                        <option>1999</option>
                                        <option>1998</option>
                                        <option>1997</option>
                                        <option>1996</option>
                                        <option>1995</option>
                                        <option>1994</option>
                                        <option>1993</option>
                                        <option>1992</option>
                                        <option>1991</option>
                                        <option>1990</option>
                                        <option>1989</option>
                                        <option>1988</option>
                                        <option>1987</option>
                                        <option>1986</option>
                                        <option>1985</option>
                                        <option>1984</option>
                                        <option>1983</option>
                                        <option>1982</option>
                                        <option>1981</option>
                                        <option>1980</option>
                                        <option>1979</option>
                                        <option>1978</option>
                                        <option>1977</option>
                                        <option>1976</option>
                                        <option>1975</option>
                                        <option>1974</option>
                                        <option>1973</option>
                                        <option>1972</option>
                                        <option>1971</option>
                                        <option>1970</option>
                                        <option>1969</option>
                                        <option>1968</option>
                                        <option>1967</option>
                                        <option>1966</option>
                                        <option>1965</option>
                                        <option>1964</option>
                                        <option>1963</option>
                                        <option>1962</option>
                                        <option>1961</option>
                                        <option>1960</option>
                                        <option>1959</option>
                                        <option>1958</option>
                                        <option>1957</option>
                                        <option>1956</option>
                                        <option>1955</option>
                                        <option>1954</option>
                                        <option>1953</option>
                                        <option>1952</option>
                                        <option>1951</option>
                                        <option>1950</option>
                                        <option>1949</option>
                                        <option>1948</option>
                                        <option>1947</option>
                                        <option>1946</option>
                                        <option>1945</option>
                                        <option>1944</option>
                                        <option>1943</option>
                                        <option>1942</option>
                                        <option>1941</option>
                                        <option>1940</option>
                                        <option>1939</option>
                                        <option>1938</option>
                                        <option>1937</option>
                                        <option>1936</option>
                                        <option>1935</option>
                                        <option>1934</option>
                                        <option>1933</option>
                                        <option>1932</option>
                                        <option>1931</option>
                                        <option>1930</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="paxInfo wow fadeInUp leadpax" style="visibility: visible; animation-name: fadeInUp;">
                    <h3>Contact Details</h3>
                    <div class="paxDetails p-3">
                        <p class="note">Please provide contact details for your Itinerary. Confirmation of the booking
                            and any important travel notices affecting your purchase will be communicated through the
                            information you provide here.</p>
                        <div>
                            <h5>Contact Info</h5>
                            <div class="row">
                                <div class="col-sm-6 col-xs-12">
                                    <div class="row">
                                        <div class="col-sm-12 col-xs-12">
                                            <label>Your name*</label>
                                            <input type="text" id="GuestName" class="form-control clsName">
                                        </div>
                                        <div class="col-sm-12 col-xs-12">
                                            <label>Address Line 1*</label>
                                            <input type="text" id="GuestAddress" class="form-control">
                                        </div>
                                        <div class="col-sm-12 col-xs-12">
                                            <label>Address Line 2</label>
                                            <input type="text" id="GuestAddress1" class="form-control">
                                        </div>
                                        <div class="col-sm-12 col-xs-12">
                                            <label>City*</label>
                                            <input type="text" id="GuestCity" class="form-control clsName">
                                        </div>
                                        <div class="col-sm-12 col-xs-12">
                                            <label>Postcode*</label>
                                            <input type="text" id="GuestPost" class="form-control">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-xs-12">
                                    <div class="row">
                                        <div class="col-sm-12 col-xs-12">
                                            <label>Country*</label>
                                            <select class="form-control" id="GuestCountry">
                                                <option value="0">Select Nationality</option>
                                                <option value="AL~86">Albania </option>
                                                <option value="DZ~98">Algeria </option>
                                                <option value="AD~85">Andorra </option>
                                                <option value="AR~101">Argentina </option>
                                                <option value="AM~95">Armenia </option>
                                                <option value="AU~1">Australia </option>
                                                <option value="AT~2">Austria </option>
                                                <option value="AZ~3">Azerbaijan </option>
                                                <option value="BH~4">Bahrain </option>
                                                <option value="BD~5">Bangladesh </option>
                                                <option value="BE~6">Belgium </option>
                                                <option value="BT~112">Bhutan </option>
                                                <option value="BA~104">Bosnia And Herzegovina </option>
                                                <option value="BR~7">Brazil </option>
                                                <option value="BN~8">Brunei </option>
                                                <option value="BG~9">Bulgaria </option>
                                                <option value="KH~10">Cambodia </option>
                                                <option value="CA~11">Canada </option>
                                                <option value="CL~92">Chile </option>
                                                <option value="CN~12">China </option>
                                                <option value="CO~94">Colombia </option>
                                                <option value="CR~109">Costa Rica </option>
                                                <option value="HR~13">Croatia </option>
                                                <option value="CU~84">Cuba </option>
                                                <option value="CY~14">Cyprus </option>
                                                <option value="CZ~15">Czech Republic </option>
                                                <option value="DK~16">Denmark </option>
                                                <option value="DO~83">Dominican Republic </option>
                                                <option value="EG~17">Egypt </option>
                                                <option value="ET~17">Ethopia </option>
                                                <option value="FI~99">Finland </option>
                                                <option value="FR~18">France </option>
                                                <option value="GE~87">Georgia </option>
                                                <option value="DE~19">Germany </option>
                                                <option value="GH~20">Ghana </option>
                                                <option value="GR~21">Greece </option>
                                                <option value="HK~22">Hong Kong </option>
                                                <option value="HU~23">Hungary </option>
                                                <option value="IS~88">Iceland </option>
                                                <option value="IN~24">India </option>
                                                <option value="ID~25">Indonesia </option>
                                                <option value="IR~26">Iran </option>
                                                <option value="IQ~96">Iraq </option>
                                                <option value="IE~27">Ireland </option>
                                                <option value="IL~28">Israel </option>
                                                <option value="IT~29">Italy </option>
                                                <option value="JM~81">Jamaica </option>
                                                <option value="JP~30">Japan </option>
                                                <option value="JO~31">Jordan </option>
                                                <option value="KZ~32">Kazakhstan </option>
                                                <option value="KE~33">Kenya </option>
                                                <option value="KW~34">Kuwait </option>
                                                <option value="LA~35">Laos </option>
                                                <option value="LV~36">Latvia </option>
                                                <option value="LB~37">Lebanon </option>
                                                <option value="LY~97">Libya </option>
                                                <option value="LU~89">Luxembourg </option>
                                                <option value="MY~38">Malaysia </option>
                                                <option value="MV~39">Maldives </option>
                                                <option value="MT~40">Malta </option>
                                                <option value="MU~106">Mauritius </option>
                                                <option value="MX~41">Mexico </option>
                                                <option value="MD~42">Moldova </option>
                                                <option value="MC~43">Monaco </option>
                                                <option value="MN~93">Mongolia </option>
                                                <option value="ME~107">Montenegro </option>
                                                <option value="MA~44">Morocco </option>
                                                <option value="MZ~105">Mozambique </option>
                                                <option value="MM~45">Myanmar </option>
                                                <option value="NA~102">Namibia </option>
                                                <option value="NP~46">Nepal </option>
                                                <option value="NL~47">Netherlands </option>
                                                <option value="NZ~48">New Zealand </option>
                                                <option value="NG~49">Nigeria </option>
                                                <option value="NO~90">Norway </option>
                                                <option value="OM~50">Oman </option>
                                                <option value="PK~51">Pakistan </option>
                                                <option value="PE~103">Peru </option>
                                                <option value="PH~52">Philippines </option>
                                                <option value="PL~53">Poland </option>
                                                <option value="PT~54">Portugal </option>
                                                <option value="QA~55">Qatar </option>
                                                <option value="RO~56">Romania </option>
                                                <option value="RU~57">Russia </option>
                                                <option value="SA~58">Saudi Arabia </option>
                                                <option value="SN~100">Senegal </option>
                                                <option value="RS~111">Serbia </option>
                                                <option value="SC~82">Seychelles </option>
                                                <option value="SG~59">Singapore </option>
                                                <option value="SK~108">Slovakia </option>
                                                <option value="SL~60">Slovenia </option>
                                                <option value="ZA~61">South Africa </option>
                                                <option value="KR~62">South Korea </option>
                                                <option value="ES~63">Spain </option>
                                                <option value="LK~64">Srilanka </option>
                                                <option value="SD~65">Sudan </option>
                                                <option value="SE~66">Sweden </option>
                                                <option value="CH~67">Switzerland </option>
                                                <option value="CH~655">Syrian </option>
                                                <option value="TW~91">Taiwan </option>
                                                <option value="TZ~68">Tanzania </option>
                                                <option value="TH~69">Thailand </option>
                                                <option value="TN~70">Tunisia </option>
                                                <option value="TR~71">Turkey </option>
                                                <option value="TM~72">Turkmenistan </option>
                                                <option value="UG~110">Uganda </option>
                                                <option value="UA~73">Ukraine </option>
                                                <option value="AE~74">United Arab Emirates </option>
                                                <option value="UK~75">United Kingdom </option>
                                                <option value="UY~76">Uruguay </option>
                                                <option value="US~79">USA </option>
                                                <option value="UZ~77">Uzbekistan </option>
                                                <option value="VN~78">Vietnam </option>
                                                <option value="WS~80">West Indies </option>
                                                <option value="SO~80">Somalia </option>
                                            </select>
                                        </div>
                                        <div class="col-sm-12 col-xs-12">
                                            <label>Contact Number*</label>
                                            <input type="text" id="GuestContact" class="form-control" placeholder=""
                                                onkeypress="return isNumberKey(event)">
                                        </div>
                                        <div class="col-sm-12 col-xs-12">
                                            <label>Email Address*</label>
                                            <input type="text" id="GuestEmail" class="form-control" placeholder="">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="paxInfo wow fadeInUp paxtnc animated"
                    style="display: none; visibility: visible; animation-name: fadeInUp;">
                    <h3>Terms &amp; Conditions</h3>
                    <div class="paxDetails">
                        <div>
                            <label class="checklabel">
                                These terms and conditions of business relate to the travel agency services provided to
                                you by Crown Travel UK Limited trading as Crown Travel (“us”, “we”) and your use of this
                                website (the “travel site”). The travel site consists of computing and information
                                services and software, information and other content provided by Crown Travel UK Limited
                                trading as Crown Travel. Third parties also provide information, software and other
                                content which may be accessed via Crown Travel.
                                <input type="checkbox" checked="">
                                <span class="checkmark"></span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-xs-12" id="PriceDetails">
                <div class="bookingSummery">
                    <h3>Your Booking</h3>
                    <h5>Save this Itinerary</h5>
                    <p class="note">Want to save your basket for later? Create a quote and retrieve it at any time.</p>
                    <button class="btn btn-primary popup-btn btn-info " data-toggle="modal" data-target="#myModal">
                        Share </button>
                    <button class="btn btn-primary popup-btn btn-info " data-toggle="modal" data-target="#preview">
                        Preview </button>
                    <button class="btn btn-primary popup-btn btn-info " id="btnContinueGuest"> Book Now</button>
                    <!-- Button trigger modal -->
                    <div class="selectedFlight">
                        <h5>Outbound Flight</h5>
                        <p class="selectedFlight-details"><span class="iconholder"><i
                                    class="fas fa-plane-departure"></i></span> Heathrow - LHR</p>
                        <p class="selectedFlight-details">Saudi Arabian Airlines</p>
                        <p class="selectedFlight-details"><span class="iconholder"><i
                                    class="far fa-clock"></i></span>12h 15m / 1 connection</p>
                        <p class="selectedFlight-details"><span class="iconholder"><i
                                    class="fas fa-calendar-alt"></i></span>Departs: Sat 13 Jul 2024 <span
                                class="time">21:45</span></p>
                        <p class="selectedFlight-details"><span class="iconholder"><i
                                    class="fas fa-calendar-alt"></i></span>Arrives: Sun 14 Jul 2024 <span
                                class="time">13:00</span></p>
                    </div>
                    <div class="selectedFlight">
                        <h5>Return Flight</h5>
                        <p class="selectedFlight-details"><span class="iconholder"><i
                                    class="fas fa-plane-departure"></i></span> Dubai Intl Arpt - DXB</p>
                        <p class="selectedFlight-details">Saudi Arabian Airlines</p>
                        <p class="selectedFlight-details"><span class="iconholder"><i
                                    class="far fa-clock"></i></span>11h 50m / 1 connection</p>
                        <p class="selectedFlight-details"><span class="iconholder"><i
                                    class="fas fa-calendar-alt"></i></span>Departs: Tue 16 Jul 2024 <span
                                class="time">04:20</span></p>
                        <p class="selectedFlight-details"><span class="iconholder"><i
                                    class="fas fa-calendar-alt"></i></span>Arrives: Tue 16 Jul 2024 <span
                                class="time">13:10</span></p>
                    </div>
                    <div class="loginPannel">
                        <p class="note-1">Debit / Credit Card Fee: £ 0.00</p>
                        <div class="totalAmount">
                            Total:£ 527.59
                        </div>
                        <label class="checklabel">
                            I accept the <a onclick="OpenTermandCondition();" class="terms_conditons">Terms &amp;
                                Conditions.</a>
                            <input type="checkbox" id="chkterm" checked="">
                            <span class="checkmark"></span>
                        </label>
                        <div id="divPassCredential">
                            <button class="btn-login" id="btnLogin" data-toggle="modal"
                                data-target="#divLoginPopup1">Login</button>
                            <p class="option">or</p>
                            <button class="btn-login" id="btnContinueGuest"> CONTINUE AS GUEST</button>
                        </div>
                        <button class="btn-login" id="btnbooking" style="display:none" totalprice="527.59" adult="1"
                            child="0" infant="0" youth="0" token="e97bd0a2-a9ac-4f5a-bff1-c35b51af61bf">Proceed To
                            Payment &gt;</button>
                    </div>
                </div>
                <script>
                    function sendenquiryDetailsBooking() {
                        //debugger
                        if (DetailsBookingValidation()) {
                            //debugger
                            $('#btnRoundTripQuerySend').val("Please wait...");
                            $.ajax({
                                url: '/Flight/BookingMailSend',
                                type: 'POST',
                                data: {
                                    Token: 'e97bd0a2-a9ac-4f5a-bff1-c35b51af61bf',
                                    Id: '',
                                    Email: $("#txtbookingquery").val(),
                                },
                                success: function (Rval) {
                                    //debugger
                                    $('#btnbookingquery').removeAttr("disabled");
                                    $("#btnbookingquery").val('Send');
                                    if (Rval) {
                                        ShowAlertLoad("Success", "Thank you for flight enquiry", "success",
                                            location.href);
                                    } else {
                                        ShowAlertLoad("Error",
                                            "Due to some technical problems we are unable to process your request currently. please try later",
                                            "error", location.href);
                                    }
                                    $('#myModal').modal('hide');
                                },
                                error: function (Rval) {
                                    $('#btnbookingquery').removeAttr("disabled");
                                    $('#btnbookingquery').val("Send");
                                }
                            });
                        }
                    }
                    function DetailsBookingValidation() {
                        //debugger
                        var Status = true;
                        if ($("#txtbookingquery").val() == "") {
                            $("#growls-default").empty();
                            alert("Please Enter Email Id");
                            $("#txtbookingquery").focus();
                            Status = false;
                        } else if (!Email_Validate($("#txtbookingquery").val(), 'txtbookingquery')) {
                            $("#growls-default").empty();
                            alert("Please Enter Correct Email Address!");
                            $("#txtbookingquery").focus();
                            Status = false;
                        }
                        return Status;
                    }
                    $(document).on('keyup', '#txtbookingquery', function () {
                        Email_Validate($(this).val(), $(this).attr('id'));
                    });
                    function Email_Validate(emailField, id) {
                        var v = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
                        if (emailField != "") {
                            if (v.test(emailField) == false) {
                                $('#' + id).attr("style", "border-color:red;");
                                return false;
                            } else {
                                $('#' + id).removeAttr('style');
                                return true;
                            }
                        } else {
                            $('#' + id).removeAttr('style');
                        }
                    }
                </script>
            </div>
        </div>
    </div>
</div>

 

  
<!-- <pre> {{flights.length }} </pre>

<pre> {{flights | json }} </pre> -->

<!-- <pre>Key : {{flights[0].key}} </pre>
<pre>Token : {{flights[0].token}} </pre> -->

<section  class="review-book-cards">
    <div class="container-lg">
        <div class="row clearfix  pt-5">
            <div class="col-lg-9 col-md-9 col-sm-12 order-md-1 order-2">
                <div class="card flight-summary">
                    <div class="card-header">
                        <h5><span>1</span> Ticket Details</h5>
                    </div>
                    <div class="card-body">
                        <div class="box-content">
                            <div class="acc-content">

                                <div class="js-accordion" *ngIf="flights.length === 0">
                                    <p>Sorry, Price is not available.</p>
                                   
                                  </div>

  <!-- Start Depart itenary-details-->

                                <div *ngFor="let flight of flights;let i = index">
                                    <div *ngFor="let journey of flight.journey; let j = index;">
                                      
                      
                                       <div  *ngFor="let airSegments of journey.airSegments; let w = index;">
                                        
                                        <!-- <pre> {{journey.airSegments | json }} </pre> -->
                                <div *ngIf="i === 0"  class="itenary-details">
                                    <div class="d-flex flex-wrap justify-content-between align-items-center mb-2">
                                        <div class="col-md-4">
                                            <span  *ngIf="j === 0 && w===0" class="review-title gray">Departure</span>
                                            <span *ngIf="j === 1 && w===0"  class="review-title gray">Return</span>
                                        </div>
                                        <div class="col-md-3">
                                            <!-- <h2>{{ flight.totalPrice | currency }}</h2>
                                            <p>Base Price: {{ flight.basePrice | currency }}</p> -->
                                            <span class="trip-time">Total Time: {{ journey.totalFlightDuration}} </span>
                                        </div>
                                    </div>
                                    <div    class="sector-details">
                                        <article class="row review-article">
                                            <div class="text-sm-center col-sm-2 col-lg-3 text-xs-left sm-gutter-bottom">
                                                <p>
                                                    <img src="{{ airSegments.airlineLogoUrl }}"
                                                        class="img-fluid" alt="...">
                                                </p>
                                                <div class="airline-box">
                                                    <span class="ib fs-12 gray">{{ airSegments.airlineName }}<br>
                                                        <span class="fs-xs gray-light">{{ airSegments.flightNumber }}</span>
                                                    </span>
                                                    <br class="hidden-xs-down">
                                                    <!-- <span class="fs-12 gray-dark block bold hide">Economy</span> -->
                                                    <!-- <span class="fs-xs gray block">Airbus A320-100</span> -->
                                                    <span class="fs-12 red block"></span>
                                                </div>
                                            </div>
                                            <div class="col-sm-10 col-lg-9 ">
                                                <div class="row">
                                                    <div class="col-md-3">
                                                        <p class="fs-base ">{{ airSegments.origin }} </p>
                                                        <h3 class="fs-24 lh28 bold gray-dark ">{{ airSegments.departTime }}</h3>
                                                        <p class="fs-sm gray lh18">
                                                            <span class="bold ">{{ airSegments.departDate }}</span>
                                                            <br>
                                                            <span class="gray-light ">{{ airSegments.originAirportName }} </span>
                                                        </p>
                                                    </div>
                                                    <div class="col-md-6 my-md-0 my-3  text-center">
                                                        <p class=""><i class="bx bx-time"></i> {{ airSegments.travelDuration }} <span
                                                                class="mx-1">|</span> <i class="fas fa-utensils"
                                                                aria-hidden="true"></i> Free Meal <span
                                                                class="mx-1">|</span> {{ airSegments.class }}</p>
                                                        <p class="hidden-sm-down two-dots">
                                                            <i class="fa-solid fa-plane-up" aria-hidden="true"><span
                                                                    class="type-text">Flight</span></i>
                                                        </p>
                                                        1P<span class="gray-lightest mx-2">|</span><a
                                                            href="javascript:void(0);">View Fare Rules</a>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <p class="fs-base ">{{ airSegments.destination }}</p>
                                                        <h3 class="fs-24 lh28 bold gray-dark">{{ airSegments.arrivalTime }}</h3>
                                                        <p class="fs-sm gray lh18">
                                                            <span class="bold ">{{ airSegments.arrivalDate }}</span>
                                                            <br>
                                                            <span class="gray-light">{{ airSegments.destinationAirportName }} 
                                                                <!-- <span class="">, T-1</span> -->
                                                            </span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </article>
                                        <section class="layover-seprator"></section>
                                         
                                    </div>
                                    <hr class="leg-seprator">
                                </div>

                            </div>
                          </div>
                        </div>
                        <!-- Start return itenary-details-->
                                
                                <div  class="itenary-details d-none ">
                                <div class="d-flex flex-wrap justify-content-between align-items-center mb-2">
                                        <div class="col-md-4">
                                            <span class="review-title gray">Return</span>
                                        </div>
                                        <div class="col-md-3">
                                            <span class="trip-time">Total Time: 22hr 0min </span>
                                        </div>
                                    </div>
                                    <div class="sector-details">
                                        <article class="row review-article">
                                            <div class="text-sm-center col-sm-2 col-lg-3 text-xs-left sm-gutter-bottom">
                                                <p>
                                                    <img src="images/SV.png"
                                                        class="img-fluid" alt="...">
                                                </p>
                                                <div class="airline-box">
                                                    <span class="ib fs-12 gray">SV 106
<br>
                                                        <span class="fs-xs gray-light ">6E - 2775</span>
                                                    </span>
                                                    <br class="hidden-xs-down">
                                                    <!-- <span class="fs-xs gray block">Flight Number 611</span> -->
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-lg-9">
                                                <div class="row">
                                                    <div class="col-md-3">
                                                        <p class="fs-base">Dubai</p>
                                                        <h3 class="fs-24 lh28 bold gray-dark">13:05</h3>
                                                        <p class="fs-sm gray lh18">
                                                            <span class="bold">Thu 23 May 2024</span>
                                                            <br>
                                                            <span class="gray-light">Dubai Intl Arpt </span>
                                                        </p>
                                                    </div>
                                                    <div class="col-md-6 my-md-0 my-3 text-center">
                                                        <p class=""><i class="bx bx-time"></i> 28hr 15min <span
                                                                class="mx-1">|</span> <i class="fas fa-utensils"
                                                                aria-hidden="true"></i> Free Meal <span
                                                                class="mx-1">|</span> Economy</p>
                                                        <p class="hidden-sm-down two-dots">
                                                            <i class="fa-solid fa-plane-up" aria-hidden="true"><span
                                                                    class="type-text">Flight</span></i>
                                                        </p>
                                                        1P<span class="gray-lightest mx-2">|</span><a
                                                            href="javascript:void(0);">View Fare Rules</a>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <p class="fs-base">London</p>
                                                        <h3 class="fs-24 lh28 bold gray-dark">14:20</h3>
                                                        <p class="fs-sm gray lh18">
                                                            <span class="bold">Fri 24 May 2024</span>
                                                            <br>
                                                            <span class="gray-light">London Stansted Arpt
                                                            </span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- <div class="center-block text-center col-sm-12 col-lg-12 bdr bdr-left meal-baggage-message fs-sm" style="margin-top: 15px;">
                                                    <div class="col-sm-2 col-lg-3"></div>
                                                    <span style="background-color: #fef1e9;padding: 5px 15px;border-radius: 5px;">
                                                        <img src="/v1/assets/images/nomeal.png" class="img-fluid" alt="..." width="15px">
                                                        <span class="text-danger">This is a special <span class="bold">No Meal </span>
                                                            <span class="bold">fare</span> provided by the airline.</span>&nbsp;
                                                    </span>
                                                </div> -->
                                        </article>
                                    </div>
                                    <!-- <hr class="leg-seprator"> -->
                                </div>
                            </div>
                            <!-- <div class="note-block-new full-lrb">
                                <i class="fas fa-info-circle" aria-hidden="true"></i>
                                <span class="bold fs-sm">Important Note</span>
                                <ul class="">
                                            <li class="">
                                            <span class="fs-sm">Two or more separate PNRs will be created and the airline may depart from a
                                                different
                                                terminal.</span>
                                        </li> 
                                    <li class="">
                                        <span class="fs-sm">Economy Plus</span>
                                    </li>
                                    <<li>
                                            <span class="fs-sm"><b class=""> fare </b>is not available on <b class=""> New Delhi - Mumbai</b> <span style="padding-left:0" class="">flight</span> <span style="padding-left:0" class=""> and </span> <b class=""> Mumbai - New Delhi</b> <span style="padding-left:0"> flight</span>, please review flight details before booking.</span>
                                        </li>
                                        <li class="">
                                            <span class="fs-sm">The Fare Rules for your flight selection have changed. Please review them before
                                                proceeding.</span>
                                        </li>
                                        <li>
                                            <span class="fs-sm">This is a special fare given by the airline. The airline does not allow partial
                                                cancellation.</span>
                                        </li> 
                                </ul>
                            </div> -->
                        </div>
                    </div>
                </div>
                <div class="card my-4">
                    
                        <div class="contents">
                            <div class="card-header">
                                <h5><span>2</span> Enter Traveller Details</h5>
                            </div>
                            <div class="card-body">
                                <div class="p-info-details">
                                    <div class="tier-badge">
                                        <div class="tier-badge-inner">
                                            <span>Passenger Details</span>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12 align-self-center mb-3 ">
                                            <!-- Form Group -->
                                            <div class="form-group m-0">
                                                <label class="form-label m-0 text-center"><i
                                                        class="fa-solid fa-user"></i> Adult 1</label>
                                                <input type="hidden" name="traveller_type[]" value="adult">
                                            </div>
                                        </div>
                                        <div class="col-md-4 mb-3">
                                            <!-- Form Group -->
                                            <div class="form-group">
                                                <label class="form-label">Title</label>
                                                <select class="custom-select-box select-default px-3 form-select"
                                                    name="traveller_sirname[]" tabindex="-1" aria-hidden="false">
                                                    <option value="">Select</option>
                                                    <option value="mrs">Mrs.</option>
                                                    <option value="mr">Mr.</option>
                                                    <option value="ms">Ms.</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-md-4 mb-3">
                                            <!-- Form Group -->
                                            <div class="form-group">
                                                <label class="form-label">First Name</label>
                                                <input type="text" name="traveller_firstname[]" class="form-control"
                                                    placeholder="First Name &amp; Middle Name" fdprocessedid="yu9m3e">
                                            </div>
                                        </div>
                                        <div class="col-md-4 mb-3">
                                            <!-- Form Group -->
                                            <div class="form-group">
                                                <label class="form-label">Last Name</label>
                                                <input type="text" name="traveller_lastname[]" class="form-control"
                                                    placeholder="Last Name" fdprocessedid="rd5d17">
                                            </div>
                                        </div>
                                        <div class="col-md-3 mb-3">
                                            <!-- Form Group -->
                                            <div class="form-group">
                                                <label class="form-label">Gender</label>
                                                <select class="custom-select-box select-default px-3 form-select"
                                                    name="traveller_sirname[]" tabindex="-1" aria-hidden="false">
                                                    <option value="">Select</option>
                                                    <option value="mrs">Male</option>
                                                    <option value="mr">Female</option>
                                                    <option value="ms">Other</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-md-3 mb-3">
                                            <!-- Form Group -->
                                            <div class="form-group">
                                                <label class="form-label">Date of Birth</label>
                                                <input type="date" name="" class="form-control">
                                            </div>
                                        </div>
                                        <div class="col-md-3 mb-3">
                                            <!-- Form Group -->
                                            <div class="form-group">
                                                <label class="form-label">Seating</label>
                                                <select class="custom-select-box select-default px-3 form-select"
                                                    name="traveller_sirname[]" tabindex="-1" aria-hidden="false">
                                                    <option value="">No preference</option>
                                                    <option value="Aisle seat">Aisle seat</option>
                                                    <option value="Bulkhead seat">Bulkhead seat</option>
                                                    <option value="Cradle/Baby Basket seat">Cradle/Baby Basket seat
                                                    </option>
                                                    <option value="Exit seat">Exit seat</option>
                                                    <option value="Non smoking window seat">Non smoking window seat
                                                    </option>
                                                    <option value="Suitable for disable seat">Suitable for disable seat
                                                    </option>
                                                    <option value="Suitable for disable seat">Suitable for disable seat
                                                    </option>
                                                    <option value="Legspace">Legspace</option>
                                                    <option value="Non smoking seat">Non smoking seat</option>
                                                    <option value="Overwing seat">Overwing seat</option>
                                                    <option value="Smoking seat">Smoking seat</option>
                                                    <option value="Window seat">Window seat</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-md-3 mb-3">
                                            <!-- Form Group -->
                                            <div class="form-group">
                                                <label class="form-label">Assistance</label>
                                                <select class="custom-select-box select-default px-3 form-select"
                                                    name="traveller_sirname[]" tabindex="-1" aria-hidden="false">
                                                    <option selected="selected" value="No preference">No preference
                                                    </option>
                                                    <option selected="selected" value="No preference">No preference
                                                    </option>
                                                    <option value="Overwing seat">Deaf</option>
                                                    <option value="Smoking seat">Blind</option>
                                                    <option value="Window seat">Wheelchair</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="p-info-details">
                                    <div class="tier-badge">
                                        <div class="tier-badge-inner">
                                            <span>Passenger Address</span>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6 mb-3">
                                            <!-- Form Group -->
                                            <div class="form-group">
                                                <label class="form-label">Address Line 1</label>
                                                <input type="text" class="form-control" placeholder="Address"
                                                    name="house_number" fdprocessedid="yu9m3e">
                                            </div>
                                        </div>
                                        <div class="col-md-6 mb-3">
                                            <!-- Form Group -->
                                            <div class="form-group">
                                                <label class="form-label">Address Line 2</label>
                                                <input type="text" class="form-control" placeholder="Address"
                                                    name="house_number" fdprocessedid="yu9m3e">
                                            </div>
                                        </div>
                                        <div class="col-md-4 mb-3">
                                            <!-- Form Group -->
                                            <div class="form-group">
                                                <label class="form-label">City</label>
                                                <input type="text" class="form-control" name="city" placeholder="City"
                                                    fdprocessedid="yu9m3e">
                                            </div>
                                        </div>
                                        <div class="col-md-4 mb-3">
                                            <!-- Form Group -->
                                            <div class="form-group">
                                                <label class="form-label">City Code</label>
                                                <input type="text" class="form-control" name="city_code"
                                                    placeholder="City Code" fdprocessedid="yu9m3e">
                                            </div>
                                        </div>
                                        <div class="col-md-4 mb-3">
                                            <!-- Form Group -->
                                            <div class="form-group">
                                                <label class="form-label">Street</label>
                                                <input type="text" class="form-control" name="strret"
                                                    placeholder="Street" fdprocessedid="yu9m3e">
                                            </div>
                                        </div>
                                        <div class="col-md-4 mb-3">
                                            <!-- Form Group -->
                                            <div class="form-group">
                                                <label class="form-label">Postal Code</label>
                                                <input type="text" name="postal_code" class="form-control"
                                                    placeholder="Postal Code" fdprocessedid="yu9m3e">
                                            </div>
                                        </div>
                                        <div class="col-md-4 mb-3">
                                            <!-- Form Group -->
                                            <div class="form-group">
                                                <label class="form-label">Country</label>
                                                <input type="text" class="form-control" name="country"
                                                    placeholder="Country" fdprocessedid="yu9m3e">
                                            </div>
                                        </div>
                                        <div class="col-md-4 mb-3">
                                            <!-- Form Group -->
                                            <div class="form-group">
                                                <label class="form-label">Country code</label>
                                                <input type="text" class="form-control" name="country_code"
                                                    placeholder="Country Code" fdprocessedid="yu9m3e">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="p-info-details">
                                    <div class="tier-badge">
                                        <div class="tier-badge-inner">
                                            <span>Contact Information</span>
                                             
                                            <input type="hidden" id="adult" class="form-control" value="{{f_adult}}">
                                            <input type="hidden" id="children" class="form-control" value="{{f_child}}">
                                            <input type="hidden" id="infant" class="form-control" value="{{f_infant}}">
                                            <input type="hidden" id="source" class="form-control" value="{{airport_name1}}">
                                            <input type="hidden" id="destination" class="form-control" value="{{airport_name2}}">
                                            <input type="hidden" id="travel_date" class="form-control" value="{{f_departureDate | date:'yyyy-MM-dd'}}">
                                            <input type="hidden" id="base_price" class="form-control" value="{{totalPrice}}">
                                            <div  *ngIf="flights.length>=1">
                                            <input type="hidden" id="token" value="{{flights[0].token}}" class="form-control clsName">
                                            <input type="hidden" id="flightprice_key" value="{{flights[0].key}}" class="form-control clsName">
                                            </div>

                                             


                                        </div>
                                    </div>
                                    <small class="mb-3 d-block">* Your booking details will be sent to this email
                                        address and mobile number.</small>
                                        <div class="row">
                                            <div class="col-sm-6 col-xs-12"> 
                                                <div class="row">
                                                    <div class="col-sm-12 col-xs-12">
                                                        <label>Your name*</label>
                                                        <input type="text" id="name" class="form-control clsName" placeholder="Your name">
                                                    </div>
                                                    <div class="col-sm-12 col-xs-12">
                                                        <label>Address *</label>
                                                        <input type="text" id="address" class="form-control" placeholder="Address">
                                                    </div>
                                                     
                                                    <div class="col-sm-12 col-xs-12">
                                                        <label>City*</label>
                                                        <input type="text" id="city" placeholder="City" class="form-control clsName">
                                                    </div>
            
                                                    <div class="col-sm-12 col-xs-12">
                                                        <label>Postcode*</label>
                                                        <input type="text" id="zip_code" placeholder="Postcode" class="form-control">
                                                    </div>
            
                                                </div>
                                            </div>
                                            <div class="col-sm-6 col-xs-12">
                                                <div class="row">
                                                    <div class="col-sm-12 col-xs-12">
                                                        <label>Country*</label>
                                                        <select placeholder="Country" class="form-control" id="state">
                                                            <option value="0">Select Nationality</option>
                                                            <option value="AL~86">Albania </option>
                                                            <option value="DZ~98">Algeria </option>
                                                            <option value="AD~85">Andorra </option>
                                                            <option value="AR~101">Argentina </option>
                                                            <option value="AM~95">Armenia </option>
                                                            <option value="AU~1">Australia </option>
                                                            <option value="AT~2">Austria </option>
                                                            <option value="AZ~3">Azerbaijan </option>
                                                            <option value="BH~4">Bahrain </option>
                                                            <option value="BD~5">Bangladesh </option>
                                                            <option value="BE~6">Belgium </option>
                                                            <option value="BT~112">Bhutan </option>
                                                            <option value="BA~104">Bosnia And Herzegovina </option>
                                                            <option value="BR~7">Brazil </option>
                                                            <option value="BN~8">Brunei </option>
                                                            <option value="BG~9">Bulgaria </option>
                                                            <option value="KH~10">Cambodia </option>
                                                            <option value="CA~11">Canada </option>
                                                            <option value="CL~92">Chile </option>
                                                            <option value="CN~12">China </option>
                                                            <option value="CO~94">Colombia </option>
                                                            <option value="CR~109">Costa Rica </option>
                                                            <option value="HR~13">Croatia </option>
                                                            <option value="CU~84">Cuba </option>
                                                            <option value="CY~14">Cyprus </option>
                                                            <option value="CZ~15">Czech Republic </option>
                                                            <option value="DK~16">Denmark </option>
                                                            <option value="DO~83">Dominican Republic </option>
                                                            <option value="EG~17">Egypt </option>
                                                            <option value="ET~17">Ethopia  </option>
                                                            <option value="FI~99">Finland </option>
                                                            <option value="FR~18">France </option>
                                                            <option value="GE~87">Georgia </option>
                                                            <option value="DE~19">Germany </option>
                                                            <option value="GH~20">Ghana </option>
                                                            <option value="GR~21">Greece </option>
                                                            <option value="HK~22">Hong Kong </option>
                                                            <option value="HU~23">Hungary </option>
                                                            <option value="IS~88">Iceland </option>
                                                            <option value="IN~24">India </option>
                                                            <option value="ID~25">Indonesia </option>
                                                            <option value="IR~26">Iran </option>
                                                            <option value="IQ~96">Iraq </option>
                                                            <option value="IE~27">Ireland </option>
                                                            <option value="IL~28">Israel </option>
                                                            <option value="IT~29">Italy </option>
                                                            <option value="JM~81">Jamaica </option>
                                                            <option value="JP~30">Japan </option>
                                                            <option value="JO~31">Jordan </option>
                                                            <option value="KZ~32">Kazakhstan </option>
                                                            <option value="KE~33">Kenya </option>
                                                            <option value="KW~34">Kuwait </option>
                                                            <option value="LA~35">Laos </option>
                                                            <option value="LV~36">Latvia </option>
                                                            <option value="LB~37">Lebanon </option>
                                                            <option value="LY~97">Libya </option>
                                                            <option value="LU~89">Luxembourg </option>
                                                            <option value="MY~38">Malaysia </option>
                                                            <option value="MV~39">Maldives </option>
                                                            <option value="MT~40">Malta </option>
                                                            <option value="MU~106">Mauritius </option>
                                                            <option value="MX~41">Mexico </option>
                                                            <option value="MD~42">Moldova </option>
                                                            <option value="MC~43">Monaco </option>
                                                            <option value="MN~93">Mongolia </option>
                                                            <option value="ME~107">Montenegro </option>
                                                            <option value="MA~44">Morocco </option>
                                                            <option value="MZ~105">Mozambique </option>
                                                            <option value="MM~45">Myanmar </option>
                                                            <option value="NA~102">Namibia </option>
                                                            <option value="NP~46">Nepal </option>
                                                            <option value="NL~47">Netherlands </option>
                                                            <option value="NZ~48">New Zealand </option>
                                                            <option value="NG~49">Nigeria </option>
                                                            <option value="NO~90">Norway </option>
                                                            <option value="OM~50">Oman </option>
                                                            <option value="PK~51">Pakistan </option>
                                                            <option value="PE~103">Peru </option>
                                                            <option value="PH~52">Philippines </option>
                                                            <option value="PL~53">Poland </option>
                                                            <option value="PT~54">Portugal </option>
                                                            <option value="QA~55">Qatar </option>
                                                            <option value="RO~56">Romania </option>
                                                            <option value="RU~57">Russia </option>
                                                            <option value="SA~58">Saudi Arabia </option>
                                                            <option value="SN~100">Senegal </option>
                                                            <option value="RS~111">Serbia </option>
                                                            <option value="SC~82">Seychelles </option>
                                                            <option value="SG~59">Singapore </option>
                                                            <option value="SK~108">Slovakia </option>
                                                            <option value="SL~60">Slovenia </option>
                                                            <option value="ZA~61">South Africa </option>
                                                            <option value="KR~62">South Korea </option>
                                                            <option value="ES~63">Spain </option>
                                                            <option value="LK~64">Srilanka </option>
                                                            <option value="SD~65">Sudan </option>
                                                            <option value="SE~66">Sweden </option>
                                                            <option value="CH~67">Switzerland </option>
                                                            <option value="CH~655">Syrian </option>
                                                            <option value="TW~91">Taiwan </option>
                                                            <option value="TZ~68">Tanzania </option>
                                                            <option value="TH~69">Thailand </option>
                                                            <option value="TN~70">Tunisia </option>
                                                            <option value="TR~71">Turkey </option>
                                                            <option value="TM~72">Turkmenistan </option>
                                                            <option value="UG~110">Uganda </option>
                                                            <option value="UA~73">Ukraine </option>
                                                            <option value="AE~74">United Arab Emirates </option>
                                                            <option value="UK~75">United Kingdom </option>
                                                            <option value="UY~76">Uruguay </option>
                                                            <option value="US~79">USA </option>
                                                            <option value="UZ~77">Uzbekistan </option>
                                                            <option value="VN~78">Vietnam </option>
                                                            <option value="WS~80">West Indies </option>
                                                            <option value="SO~80">Somalia </option>
                                                        </select>
                                                    </div>
                                                    <div class="col-sm-12 col-xs-12">
                                                        <label>Contact Number*</label>
                                                        <input type="text" placeholder="Contact Number" id="mobile" class="form-control"  >
                                                    </div>
                                                    <div class="col-sm-12 col-xs-12">
                                                        <label>Email Address*</label>
                                                        <input type="text" placeholder="Email" id="email" class="form-control">
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                </div>
                                <div class="chex-bx-with-lnk">
                                    <label class="checkbox checkbox-flight margin-non checked">
                                        <input checked="" name="checkbox-inline" value="true" type="checkbox">
                                        <strong>Yes,</strong> <span class="instext">By continuing, you agree to the <a
                                                href="/term-conditions.php"> <span>Terms and Conditions</span></a> and
                                            the <a href="/privacy-policy.php"><span>Privacy Policy</span></a></span>
                                    </label>
                                </div>
                                <div class="button-box mt-2  text-center">
                                    <button  id="continue_flight" type="submit"
                                        class="button -blue-1 submit_btn py-10 px-35 mx-auto col-md-3 mb-2 rounded-0 bg-dark-3 text-white">
                                        Book Now </button>
                                </div>
                            </div>
                        </div>
                     
                </div>
               
            </div>
            <!--Sidebar Start-->
            <div class="col-lg-3 col-md-3 col-sm-12 order-md-1 ">
                <div class="position-sticky">
                    <!-- Price Summary  -->
                    <div class="card flight-booking-card">
                        <div class="card-header">
                            <h5>Fare Details</h5>
                        </div>
                        <div class="card-body">
                            <ul>
                                <li data-bs-toggle="collapse" data-bs-target="#basefare"
                                    class="d-flex align-items-center justify-content-between mb-2"><span>Base Fare (1
                                        Traveller)<small><i class="fas fa-chevron-down ms-1"
                                                aria-hidden="true"></i></small></span> <span><i
                                            class="bx bx-rupee"></i>£{{totalPrice}}</span></li>
                                <ul id="basefare" class="collapse">
                                    <li class="d-flex align-items-center justify-content-between mb-2"><span>Adult x
                                        {{f_adult}}
                                    <br>
                                    Child x {{f_child}}
                                    Infant {{f_infant}}
                                    </span> <span><i class="bx bx-rupee"></i>£{{totalPrice}}</span></li>
                                </ul>
                                <li data-bs-toggle="collapse" data-bs-target="#surcharges"
                                    class="d-flex align-items-center justify-content-between mb-2"><span>Fee &amp;
                                        Surcharges</span> <span><i class="bx bx-rupee"></i>£0</span></li>
                               
                            </ul>
                        </div>
                        <div class="card-footer">
                            <h5 class="d-flex align-items-center justify-content-between mb-0"><span>Total Fare</span>
                                <span><i class="bx bx-rupee"></i>£{{totalPrice}}</span></h5>
                        </div>
                         
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

 <!---New Design End-->        

 

 
 
 



 