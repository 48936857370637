 	 
	 
<div class="body flightDetails">
    <div class="titleBar footer-bottom">
        <div class="container" *ngFor="let page of pages;let i = index">
            <h2>{{page.name}} 
             </h2>
            
        </div>
    </div>
        <div class="container mt-5">
            <div class="row">
                <div class="col-lg-12 col-sm-12 col-xs-12">
                    <div id="FlightDetails">
    
                    <style type="text/css">
                        .ac_results {
                            padding: 0;
                            border: 1px solid black;
                            background-color: white;
                            overflow: hidden;
                            z-index: 9999;
                            width: 500px !important;
                            position: absolute;
                        }
                    
                            .ac_results ul {
                                width: 100%;
                                list-style-position: outside;
                                list-style: none;
                                padding: 0;
                                margin: 0;
                                box-shadow: 0 0 1px 1px #CCC;
                                background-color: #fff;
                            }
                    
                            .ac_results li {
                                margin: 0;
                                cursor: default;
                                display: block;
                                font: menu;
                                font-size: 14px;
                                overflow: hidden;
                            }
                    
                        .ac_odd {
                            float: left;
                            width: 100%;
                            background: #FFF url("../../Content/images/autocompleteicon/loc1.png") no-repeat scroll 3% 49%;
                            color: #063553;
                            font: 15px/25px DinMedium;
                            padding: 10px 20px 10px 31px;
                            margin-top: 5px;
                        }
                    
                        .ac_even {
                            float: left;
                            width: 100%;
                            padding: 6px 6px 6px 40px;
                            font: 15px DinMedium;
                            color: #0A6473;
                            background: #FFF url("../../Content/images/autocompleteicon/plane1.png") no-repeat scroll 5% 40%;
                        }
                    
                        .ac_over {
                            background-color: #4b9799;
                            color: #ffffff;
                        }
                    </style>
                    
                    <div class="flightDetailsBox wow fadeInUp" style="visibility: visible; animation-name: fadeInUp;">
                        
                        
                                <div class="flight-info">
                                    
                                    <div *ngFor="let page of pages;let i = index">
                                    <!-- <p>{{page.name}} </p> -->
                                    <p></p>
 
                                    <div [innerHTML]="page.details"></div>

                                    </div>    
                                </div>
                                 
                    </div>
                    
                    
                   

                    </div>
                    
                    
                    
                    <div class="paxInfo wow fadeInUp paxtnc animated" style="display: none; visibility: visible; animation-name: fadeInUp;">
                        <h3>Terms &amp; Conditions</h3>
                        <div class="paxDetails">
                            <div>
                                <label class="checklabel">
                                    These terms and conditions of business relate to the travel agency services provided to you by Crown Travel UK Limited trading as Crown Travel  (“us”, “we”) and your use of this website (the “travel site”). The travel site consists of computing and information services and software, information and other content provided by Crown Travel UK Limited trading as Crown Travel. Third parties also provide information, software and other content which may be accessed via Crown Travel.
                                    <input type="checkbox" checked="">
                                    <span class="checkmark"></span>
                                </label>
                            </div>
    
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
</div>
 
<script src='https://cdnjs.cloudflare.com/ajax/libs/jquery/2.2.2/jquery.min.js'></script>
<script src='https://cdnjs.cloudflare.com/ajax/libs/OwlCarousel2/2.1.3/owl.carousel.min.js'></script>

<script src="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/js/bootstrap.bundle.min.js"></script>
<script src="https://kit.fontawesome.com/343d7b598a.js" crossorigin="anonymous"></script>
<script src="js/slick-slider-blog.js"></script>

<script src="js/script.js"></script>



<script>
    (function ($) {
        "use strict";

        jQuery(document).ready(function () {

            $('.flight-offer-slider').slick({
                arrows: true,
                dots: false,
                infinite: true,
                speed: 300,
                slidesToShow: 3,
                slidesToScroll: 1,
                prevArrow: '<a class="slick-prev"><i class="fas fa-arrow-left" alt="Arrow Icon"></i></a>',
                nextArrow: '<a class="slick-next"><i class="fas fa-arrow-right" alt="Arrow Icon"></i></a>',
                responsive: [{
                        breakpoint: 1200,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1,
                            arrows: false,
                            dots: true
                        }
                    },
                    {
                        breakpoint: 992,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1,
                            arrows: false,
                            dots: true
                        }
                    },
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1,
                            arrows: false,
                            dots: true
                        }
                    },
                    {
                        breakpoint: 575,
                        settings: {
                            arrows: false,
                            dots: true,
                            slidesToShow: 1,
                            slidesToScroll: 1
                        }
                    }
                ]
            });

            $('.hotel-offers-slider').slick({

                arrows: true,
                dots: false,
                infinite: true,
                speed: 300,
                slidesToShow: 3,
                slidesToScroll: 1,
                prevArrow: '<a class="slick-prev"><i class="fas fa-arrow-left" alt="Arrow Icon"></i></a>',
                nextArrow: '<a class="slick-next"><i class="fas fa-arrow-right" alt="Arrow Icon"></i></a>',
                responsive: [{
                        breakpoint: 1200,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1,
                            arrows: true,
                            dots: false,
                        }
                    },
                    {
                        breakpoint: 992,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1,
                            arrows: true,
                            dots: false,
                        }
                    },
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            arrows: true,
                            dots: false,
                        }
                    },
                    {
                        breakpoint: 575,
                        settings: {
                            arrows: true,
                            dots: false,
                            slidesToShow: 1,
                            slidesToScroll: 1
                        }
                    }
                ]
            });

            $('.hop-on-hop-off-slider').slick({
                arrows: true,
                dots: false,
                infinite: true,
                speed: 300,
                slidesToShow: 4,
                slidesToScroll: 1,
                prevArrow: '<a class="slick-prev"><i class="fas fa-arrow-left" alt="Arrow Icon"></i></a>',
                nextArrow: '<a class="slick-next"><i class="fas fa-arrow-right" alt="Arrow Icon"></i></a>',
                responsive: [{
                        breakpoint: 1200,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 1,
                            // arrows: false,
                            // dots: true
                        }
                    },
                    {
                        breakpoint: 992,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 1,
                            // arrows: false,
                            // dots: true
                        }
                    },
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1,
                            // arrows: false,
                            // dots: true
                        }
                    },
                    {
                        breakpoint: 575,
                        settings: {
                            // arrows: false,
                            // dots: true,
                            slidesToShow: 1,
                            slidesToScroll: 1
                        }
                    }
                ]
            });

            $('.singleslider').slick({
                arrows: true,
                dots: false,
                infinite: true,
                speed: 300,
                slidesToShow: 1,
                slidesToScroll: 1,
                prevArrow: '<a class="slick-prev"><i class="fas fa-arrow-left" alt="Arrow Icon"></i></a>',
                nextArrow: '<a class="slick-next"><i class="fas fa-arrow-right" alt="Arrow Icon"></i></a>',
                responsive: [{
                        breakpoint: 1200,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 1,
                            // arrows: false,
                            // dots: true
                        }
                    },
                    {
                        breakpoint: 992,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            // arrows: false,
                            // dots: true
                        }
                    },
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            // arrows: false,
                            // dots: true
                        }
                    },
                    {
                        breakpoint: 575,
                        settings: {
                            // arrows: false,
                            // dots: true,
                            slidesToShow: 1,
                            slidesToScroll: 1
                        }
                    }
                ]
            });

            $('.special').slick({
                arrows: true,
                dots: false,
                infinite: true,
                speed: 300,
                slidesToShow: 6,
                slidesToScroll: 1,
                prevArrow: '<a class="slick-prev"><i class="fas fa-arrow-left" alt="Arrow Icon"></i></a>',
                nextArrow: '<a class="slick-next"><i class="fas fa-arrow-right" alt="Arrow Icon"></i></a>',
                responsive: [{
                        breakpoint: 1200,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 1,
                            // arrows: false,
                            // dots: true
                        }
                    },
                    {
                        breakpoint: 992,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 1,
                            // arrows: false,
                            // dots: true
                        }
                    },
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1,
                            // arrows: false,
                            // dots: true
                        }
                    },
                    {
                        breakpoint: 575,
                        settings: {
                            // arrows: false,
                            // dots: true,
                            slidesToShow: 1,
                            slidesToScroll: 1
                        }
                    }
                ]
            });

            $('.flight-listing').slick({
                arrows: true,
                dots: false,
                infinite: true,
                speed: 300,
                slidesToShow: 8,
                slidesToScroll: 1,
                prevArrow: '<a class="slick-prev"><i class="fas fa-arrow-left" alt="Arrow Icon"></i></a>',
                nextArrow: '<a class="slick-next"><i class="fas fa-arrow-right" alt="Arrow Icon"></i></a>',
                responsive: [{
                        breakpoint: 1200,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 1,
                            // arrows: false,
                            // dots: true
                        }
                    },
                    {
                        breakpoint: 992,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 1,
                            // arrows: false,
                            // dots: true
                        }
                    },
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1,
                            // arrows: false,
                            // dots: true
                        }
                    },
                    {
                        breakpoint: 575,
                        settings: {
                            // arrows: false,
                            // dots: true,
                            slidesToShow: 1,
                            slidesToScroll: 1
                        }
                    }
                ]
            });


            $('.deals-slider').slick({
                margin: 10,
                arrows: true,
                dots: false,
                infinite: true,
                speed: 300,
                slidesToShow: 3,
                slidesToScroll: 1,
                prevArrow: '<a class="slick-prev"><i class="fas fa-arrow-left" alt="Arrow Icon"></i></a>',
                nextArrow: '<a class="slick-next"><i class="fas fa-arrow-right" alt="Arrow Icon"></i></a>',
                responsive: [{
                        breakpoint: 1200,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1,
                            arrows: true,
                            dots: false,
                        }
                    },
                    {
                        breakpoint: 992,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1,
                            arrows: true,
                            dots: false,
                        }
                    },
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1,
                            arrows: true,
                            dots: false,
                        }
                    },
                    {
                        breakpoint: 575,
                        settings: {
                            arrows: true,
                            dots: false,
                            slidesToShow: 1,
                            slidesToScroll: 1
                        }
                    }
                ]
            });

            $(".more-link-progressive").click(function () {
                var numClicks = $(this).attr("data-clicks") == null ? 0 : $(this).attr(
                    "data-clicks");
                if (numClicks <= 2) {
                    var curHeight = $(this).parent().height();
                    var PROGRESSIVE_HEIGHT = $(window).height() * 0.20;
                    $(this).parent().css("max-height", (curHeight + PROGRESSIVE_HEIGHT) + "px");
                    $(this).attr("data-clicks", 1 + numClicks);
                } else {
                    $(this).parent().css("max-height", "");
                    $(this).parent().removeClass("compact");
                    $(this).remove();
                }
                return false;
            });

        });

    })(jQuery);
</script>

<script type="text/javascript" src="https://cdn.jsdelivr.net/momentjs/latest/moment.min.js"></script>
<script type="text/javascript" src="https://cdn.jsdelivr.net/npm/daterangepicker/daterangepicker.min.js"></script>
<script src="https://cdnjs.cloudflare.com/ajax/libs/lightgallery/1.6.12/js/lightgallery-all.min.js"></script>
<script type="text/javascript">
    $(function () {

        $('input[name="datefilter"]').daterangepicker({
            autoUpdateInput: false,
            locale: {
                cancelLabel: 'Clear'
            }
        });

        $('input[name="datefilter"]').on('apply.daterangepicker', function (ev, picker) {
            $(this).val(picker.startDate.format('MM/DD/YYYY') + ' - ' + picker.endDate.format(
                'MM/DD/YYYY')).html($(this).html() + "<br> Rs 500");

        });

        $('input[name="datefilter"]').on('cancel.daterangepicker', function (ev, picker) {
            $(this).val('').html($(this).html() + "<br> Rs 500");

        });

    });
</script>

<script>
    $(function () {

        var $filter = $('.filter');
        var $tab = $('.filter a');
        var $offers = $('.boxGroup .box')



        $filter.on('click touch', '.all', function (e) {
            e.preventDefault();
            $tab.removeClass('current');
            $(this).addClass('current');

            $offers.hide();
            $offers.fadeIn(700);

        });


        $filter.on('click touch', '.one', function (e) {
            e.preventDefault();
            $tab.removeClass('current');
            $(this).addClass('current');

            $offers.show();
            $offers.fadeOut(400);
            $offers.filter('.one').fadeIn(400);

        });



        $filter.on('click touch', '.two', function (e) {
            e.preventDefault();
            $tab.removeClass('current');
            $(this).addClass('current');

            $offers.show();
            $offers.fadeOut(400);
            $offers.filter('.two').fadeIn(400);

        });



        $filter.on('click touch', '.three', function (e) {
            e.preventDefault();
            $tab.removeClass('current');
            $(this).addClass('current');

            $offers.show();
            $offers.fadeOut(400);
            $offers.filter('.three').fadeIn(400);

        });

        $filter.on('click touch', '.four', function (e) {
            e.preventDefault();
            $tab.removeClass('current');
            $(this).addClass('current');

            $offers.show();
            $offers.fadeOut(400);
            $offers.filter('.four').fadeIn(400);

        });


    });

    $(function () {

        var $filter = $('.hotel-offers-filter');
        var $tab = $('.hotel-offers-filter a');
        var $offers = $('.hotel-offers-boxGroup .box')



        $filter.on('click touch', '.hotel-offers-all', function (e) {
            e.preventDefault();
            $tab.removeClass('current');
            $(this).addClass('current');

            $offers.hide();
            $offers.fadeIn(700);

        });


        $filter.on('click touch', '.hotel-offers-one', function (e) {
            e.preventDefault();
            $tab.removeClass('current');
            $(this).addClass('current');

            $offers.show();
            $offers.fadeOut(400);
            $offers.filter('.hotel-offers-one').fadeIn(400);

        });



        $filter.on('click touch', '.hotel-offers-two', function (e) {
            e.preventDefault();
            $tab.removeClass('current');
            $(this).addClass('current');

            $offers.show();
            $offers.fadeOut(400);
            $offers.filter('.hotel-offers-two').fadeIn(400);

        });

        $('#lightgallery').lightGallery();


    });
</script>

 