// search.service.ts
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Globals } from '../../globals'; 

@Injectable({
  providedIn: 'root'
})
export class HotelService {

  //private apiUrl = 'https://api.worldota.net/api/b2b/v3/search/serp/region/';

  
  constructor(private http: HttpClient,private globals: Globals) { }
    private apiUrl = this.globals.APIURL+'/api/hotelsearch';

    private apiUrl_hotelinfo = this.globals.APIURL+'/api/hotelinfo';
 // private apiUrl = 'http://localhost:3000/api/hotelsearch';

// private apiUrl = 'http://giatraveldev.sauravsadangi.in:3001/api/hotelsearch';

  searchRegion(checkinDate: string, checkoutDate: string, adults: number, children: number[], regionId: string): Observable<any> {
    const body = {
      checkinDate,
      checkoutDate,
      adults,
      children,
      regionId
    };
   // console.log("Apiurl"+this.apiUrl);
   console.log("checkinDate : "+checkinDate);
   //console.log("Global : "+this.globals.APIURL);
    return this.http.post(this.apiUrl, body);
  }

  hotelInfo(hotel_id: string,checkin_date: string, checkout_date: string, adults: number, children: number, region_id: string): Observable<any> {
    const body = {
      hotel_id,
      checkin_date,
      checkout_date,
      adults,
      children,
      region_id
    };
   // console.log("hotel data service :"+this.http.post(this.apiUrl_hotelinfo, body));
  
    return this.http.post(this.apiUrl_hotelinfo, body);
  }



  searchRegion1(checkinDate: string, checkoutDate: string, adults: number, children: number[], regionId: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Basic Nzc1Mzo5ZTQ5YTM0NC1kZjJjLTQzMWEtODYyZS04NDRjY2I2ZTYxYTg='
    });
    const childrenArray = Array.isArray(children) ? children.map(child => Number(child)) : [];
    const regionIdUint32 = Number(regionId) >>> 0; // Convert to uint32

    const body = {
      checkin: checkinDate,
      checkout: checkoutDate,
      guests: [
        {
            adults: Number(adults), 
          children: childrenArray
        }
      ],
      region_id: regionIdUint32,
      hotels_limit: 5,
      currency: 'GBP'
    };

    const body1 = {
        "checkin": checkinDate,
        "checkout": checkoutDate,
        "guests": [
          {
            "adults": '1',
            "children": children
          }
        ],
        "region_id": regionId,
        "hotels_limit": 2000,
        "currency": 'GBP'
      };
  

    return this.http.post(this.apiUrl, body, { headers: headers });
  }


}
