import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  private isLoggedIn: boolean = false;
  private token: string | null = null;
  private userData: any = null;

  constructor( private http: HttpClient,
    private router: Router) { 
   }

  setSession(token: string, userData: any) {
    this.isLoggedIn = true;
    this.token = token;
    this.userData = userData;
    console.log("setSession");
  }

  clearSession() {
    this.isLoggedIn = false;
    this.token = null;
    this.userData = null;
  }

  isLoggedInUser(): boolean {
    return this.isLoggedIn;
  }
  // Check if user is logged in
  isAuthenticated(): boolean {
    return this.isLoggedIn;
  }
  getToken(): string | null {
    this.token='john2025@gmail.com';
    return this.token;
  }

  getUserData(): any {
    return this.userData;
  }
}
