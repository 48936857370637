import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Globals } from '../../globals'; 
import { AuthService } from '../services/auth.service';
import { NgComponentOutlet } from '@angular/common';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms'; // Import FormsModule
import { SessionService } from '../services/session.service';

@Component({
  selector: 'app-booking',
  standalone: true,
  imports:[NgComponentOutlet,CommonModule,FormsModule], 
  templateUrl: './booking.component.html',
  styleUrl: './booking.component.css'
})
export class BookingComponent {

  booking: any[] = [];
  error: string | null = null;
  isGetDataRoute: boolean = false;
  token: string | null = null;
  sess_email: string | null = null;
  public apiUrl = ''; 
  public email = '';
  
  constructor(
    private sessionService: SessionService,
    private authService: AuthService,
    private http: HttpClient,
    private route: ActivatedRoute, 
    private router: Router,
    private globals: Globals, 
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  ngOnInit() {
    this.route.url.subscribe(url => {
      console.log("Welcome to booking");
      this.getbookingData();
    });
  }

  getbookingData() {
    if(!this.sessionService.isAuthenticated()) {
      console.log("Welcome to booking data");
      this.apiUrl = this.globals.APIURL + '/api/user/booking';
               
        this.http.get(this.apiUrl, {
          headers: { Authorization: `Bearer test@gmail.com` },
          withCredentials: true
        }).subscribe(
          (response: any) => {
            
            this.booking =  Array.from(response);
            console.log('booking Data:', this.booking);
          },
          (error) => {
            this.error = 'Failed to load booking details';
            console.error('Error:', error);
          }
        );
      

      
    } 
  }

}
