import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',  // This makes the service available application-wide.
  })

export class Globals{

   // APIURL = 'http://localhost:3000';
    VAR2 = 'value2';

    //APIURL =  'http://giatraveldev.sauravsadangi.in:3001';
//APIURL =  'http://sauravsadangi.info:8080';
APIURL =  'https://sauravsadangi.info';


}