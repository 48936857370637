
<section class="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4 pt-0" style="background: url(assets/images/banner1.png) no-repeat; background-size:cover;">
    <div class="container mt-5 mb-5">
      <div class="mt-5  row justify-content-center">
        <div class="col-xl-8 col-md-10 d-flex flex-column align-items-center justify-content-center">
            <div class="row">
                <div class="col-md-6 pe-0 d-flex flex-column align-items-center justify-content-center login_s_img rounded-start position-relative" style="background: url(assets/images/login_new.jpg) no-repeat; background-size: cover;">
                    <!-- <img src="images/login_new.jpg" alt="" class="rounded-start"> -->
                </div>
                <div class="col-md-6 d-flex ps-md-0 flex-column align-items-center justify-content-center">
                    <div class="card border-0 rounded-0 rounded-end">

                        <div class="card-body ps-4"> 

                            <div style="color: red;" *ngIf="responseMessage">
                                <p>{{ responseMessage }}</p>
                            </div>


                        <div class="pt-4 pb-2">
                            <h5 class="card-title text-center pb-0">Create an Account</h5>
                            <p class="text-center small">Enter your personal details to create account</p>
                        </div>


                        <form  (ngSubmit)="postData()" class="row g-3 needs-validation signup_form" novalidate="">
                            <div class="col-12">
                            <label for="yourName" class="form-label">Your Name</label>
                            <input type="text" name="name" [(ngModel)]="name"  class="form-control" id="name" required="">
                            <div class="invalid-feedback">Please, enter your name!</div>
                            </div>

                            <div class="col-12">
                            <label for="yourEmail" class="form-label">Your Email</label>
                            <input type="email" name="email" [(ngModel)]="email" class="form-control" id="yourEmail" required="">
                            <div class="invalid-feedback">Please enter a valid Email adddress!</div>
                            </div>

                            <div class="col-12"> 
                            <label for="yourUsername" class="form-label">Phone Number</label>
                            <div class="input-group has-validation">
                                <span class="input-group-text" id="inputGroupPrepend"></span>
                                <input type="text" name="mobile" id="mobile"  [(ngModel)]="mobile" placeholder="Phone Number" class="form-control"  required="">
                                <div class="invalid-feedback">Please choose a Phone Number.</div>
                            </div>
                            </div>

                            <div class="col-12">
                            <label for="yourPassword" class="form-label">Password</label>
                            <input type="password" name="password" [(ngModel)]="password" class="form-control" id="password" required="">
                            <div class="invalid-feedback">Please enter your password!</div>
                            </div>

                            <div class="col-12">
                            <div class="form-check">
                                <input class="form-check-input" name="terms" type="checkbox" value="" id="acceptTerms" required="">
                                <label class="form-check-label" for="acceptTerms">I agree and accept the <a href="#">terms and conditions</a></label>
                                <div class="invalid-feedback">You must agree before submitting.</div>
                            </div>
                            </div>
                            <div class="col-12">
                                <div class="form-group text-center">
                                    <button type="submit" class="btn btn-info w-100 submit_btn">Create Account</button>


                                    <!-- <button class="btn btn-info w-100" type="submit" fdprocessedid="94obgv">
                                        <div class="btn-wrap">
                                            <span class="text-two">Create Account</span>
                                        </div>
                                    </button> -->
                                </div>
                            </div>
                            <div class="col-12">
                            <p class="small mb-0">Already have an account? <a href="/login">Log in</a></p>
                            </div>
                        </form>

                        </div>
                    </div>
                </div>
            </div>

        </div>
      </div>
    </div>
</section>