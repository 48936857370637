<style>
      /*-------Slider CSS-------*/
      .slidecontainer {
        width: 100%;
    }

    .slidecontainer .slider {
         width: 100%;
        height: 7px;
        border-radius: 5px;
        background: #29408d;
        outline: none;
        opacity: 1;
        -webkit-transition: .2s;
        transition: opacity .2s;
    }

    .slidecontainer .slider:hover {
        opacity: 1;
    }

    .slidecontainer .slider::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 24px;
        height: 24px;
        border-radius: 30px;
        cursor: pointer;
        background: #fff;
        box-shadow: 0 0 6px 0 rgba(0, 0, 0, .2) !important;
        border: none;
    }

    .slidecontainer .slider::-moz-range-thumb {
        width: 24px;
        height: 24px;
        border-radius: 30px;
        cursor: pointer;
        background: #fff;
        box-shadow: 0 0 6px 0 rgba(0, 0, 0, .2) !important;
        border: none;
    }
</style>

<style>
  .loader {
    display: none;
    border: 4px solid #f3f3f3;
    border-radius: 50%;
    border-top: 4px solid #3498db;
    width: 20px;
    height: 20px;
    animation: spin 2s linear infinite;
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  .disabled {
    pointer-events: none;
    opacity: 0.6;
  }
</style>
<script>
  $(document).ready(function() {
    $('.booknowbtn').click(function() {
      var button = $(this);
      button.addClass('disabled');
      $('#loader').show();
       
  });
}); 
</script>

<main class="flight_listing w-100">  
   
  <section class="py-40">
    <div class="container">

      <form  action="/flight" method="get">
				<input type="hidden" id="airport_name1" name="airport_name1" value="Dubai Airport">
				<input type="hidden" id="flight_from" name="flight_from" value="DXB"> 
				<input type="hidden" id="airport_name2" name="airport_name2" value="London City Airport">
				<input type="hidden" id="flight_to" name="flight_to" value="LCY"> 
				<input type="hidden" id="f_departureDate" name="f_departureDate">
				<input type="hidden" id="f_returnDate" name="f_returnDate">

      <div class="mainSearch -col-5 border-light rounded-4">
        <div class="button-grid items-center">


          <div class="searchMenu-loc js-form-dd js-liverSearch">

            <div data-x-dd-click="searchMenu-loc">
              <h4 class="text-15 fw-500 ls-2 lh-16">Flying From</h4>
               
              <div class="text-15 text-light-1 ls-2 lh-16">
                <input type="search" id="airport_city1" name="airport_city1" value="{{ airport_city1 }}" class="js-search js-dd-focus" />
                <div id="search-flight-results1" class="search-flight-results search-flight-results1"></div>
                <p class="mb-0 f-14 d-lg-block d-none otherarea-flight1" id="otherarea-flight1"></p>
              </div>
            </div>

          </div>


          <div class="searchMenu-loc js-form-dd js-liverSearch">

            <div data-x-dd-click="searchMenu-loc">
              <h4 class="text-15 fw-500 ls-2 lh-16">Flying To</h4>

              <div class="text-15 text-light-1 ls-2 lh-16">
                <input type="search" id="airport_city2" name="airport_city2" value="{{airport_city2}}" class="js-search js-dd-focus" />
                <div id="search-flight-results2" class="search-flight-results search-flight-results2"></div>
                <p class="mb-0 f-14 d-lg-block d-none otherarea-flight2" id="otherarea-flight2"></p>
             
              </div>
            </div>

          </div>
          

       
        

          <div class="searchMenu-date js-form-dd js-calendar">

            <div data-x-dd-click="searchMenu-date">
              <h4 class="text-15 fw-500 ls-2 lh-16">Depart</h4>

              <div class="text-15 text-light-1 ls-2 lh-16">
                <input type="text" class="date-calendar" name="datefilter" value="{{ f_departureDate }}">
              </div>
            </div>

          </div>


          <div class="searchMenu-date js-form-dd js-calendar">

            <div data-x-dd-click="searchMenu-date">
              <h4 class="text-15 fw-500 ls-2 lh-16">Return</h4>

              <div class="text-15 text-light-1 ls-2 lh-16">
                <input type="text" class="date-calendar" name="datefilter" value="{{ f_returnDate }}">
              </div>
            </div>



          </div>


          <div class="searchMenu-guests js-form-dd js-form-counters traveller-info">

            <div data-x-dd-click="searchMenu-guests">
              <h4 class="text-15 fw-500 ls-2 lh-16">Passengers & Class</h4>

              <div class="text-15 text-light-1 ls-2 lh-16 text-muted collapsed" data-bs-toggle="collapse"
                data-bs-target="#traveller-info" aria-expanded="false" aria-controls="traveller-info">
                1 Adult, Economy
              </div>
              <div id="traveller-info" class="dropdown-menu collapse" style="">
                <span class="chevron"></span>
                <h3 class="">Travelers</h3>
                <div>
                  <div class="d-flex justify-content-between align-items-center mb-4">
                    <div>
                      <label>Adults (16+)</label>
                    </div>
                    <div class="range">
                      <button class="minus"><i class="fa-solid fa-minus"></i></button>
                      <span>1</span>
                      <button class="plus"><i class="fa-solid fa-plus"></i></button>
                    </div>
                  </div>
                  <div class="d-flex justify-content-between align-items-center mb-4">
                    <div>
                      <label>Youth (12-16)</label>
                      <!-- <span class="child-ages">Ages 0 to 17</span> -->
                    </div>
                    <div class="range">
                      <button class="minus"><i class="fa-solid fa-minus"></i></button>
                      <span>1</span>
                      <button class="plus"><i class="fa-solid fa-plus"></i></button>
                    </div>
                  </div>
                  <div class="d-flex justify-content-between align-items-center mb-4">
                    <div>
                      <label>Child (2 - 12)</label>
                      <!-- <span class="child-ages">Ages 0 to 17</span> -->
                    </div>
                    <div class="range">
                      <button class="minus"><i class="fa-solid fa-minus"></i></button>
                      <span>1</span>
                      <button class="plus"><i class="fa-solid fa-plus"></i></button>
                    </div>
                  </div>
                  <div class="d-flex justify-content-between align-items-center mb-4">
                    <div>
                      <label>Infant (0-2)</label>
                      <!-- <span class="child-ages">Ages 0 to 17</span> -->
                    </div>
                    <div class="range">
                      <button class="minus"><i class="fa-solid fa-minus"></i></button>
                      <span>1</span>
                      <button class="plus"><i class="fa-solid fa-plus"></i></button>
                    </div>
                  </div>

                </div>

                <div class="form-control form-control-select p-0">
                  <select>
                    <option>All Classes</option>
                    <option>Economy</option>
                    <option>Business</option>
                    <option>First class</option>
                    <option>Premium economy</option>
                  </select>
                  <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false"
                    class="css-19bqh2r position-absolute end-0">
                    <path
                      d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z">
                    </path>
                  </svg>
                </div>

                <div class="w-100 float-start">
                  <hr class="w-100 float-start">
                </div>

                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="checkbox">
                  <label class="form-check-label">Direct only</label>
                </div>


                <div class="w-100 float-start">
                  <button type="button" class="btn w-100">Done</button>
                </div>
              </div>
            </div>

          </div>


          <div class="button-item">
            <button  type="submit" class="btn" class="mainSearch__submit button -blue-1 py-15 px-35 h-60 col-12 rounded-4 bg-dark-3 text-white">
              <i class="icon-search text-20 mr-10"></i>
              Search
            </button>
          </div>
        </div>
      </div>
      </form>


    </div>
  </section>

  <section class="layout-pt-md layout-pb-md bg-light-2">
    <div class="container">
      <div class="row y-gap-30">
        <div class="col-xl-3 col-lg-4">

           
          <aside class="sidebar py-10 px-10  bg-white d-none d-lg-block" id="relativeTop">
            <div class="fltresult">
              <h4>Filter Result</h4>
              <p>Use our filters to find the flight you are looking for</p>
            </div>
            <div class="shm-filters  ">
              <div id="accordion">
                 
                <div class="card">
                  <div class="card-header">
                    <a class="collapsed btn" data-bs-toggle="collapse" href="#collapseTwo">
                      <span><i class="fa-solid fa-hand-holding-dollar"></i> Price</span> <i
                        class="fa-solid fa-angle-down"></i>
                    </a>
                  </div>
                  <div id="collapseTwo" class="collapse" data-bs-parent="#accordion">
                    <div class="card-body">
                      <div class="filtersOuter mapSlider">
                        <div class="slidecontainer">
                          
  <input type="hidden" id="minPrice"  name="minPrice" [(ngModel)]="minPrice" (change)="applyPriceFilter()">
  <label for="maxPrice">Maximum Price:</label>
  <input type="number" id="maxPrice" name="maxPrice" [(ngModel)]="maxPrice" (change)="applyPriceFilter()">

                          <input type="range" min="5229" max="18000" value="17000" class="slider" id="slider_range">
                          <!-- <p>Value: <span id="slider_value"></span></p> -->
                        </div>
                        <div class="makeFlex spaceBetween darkText">
                          <span><i class="fas fa-pound-sign"></i> <span id="slider_value">
                            </span></span>
                          <span><i class="fas fa-pound-sign"></i> 18,000</span>
                        </div>
                        <script>
                          var slider = document.getElementById("slider_range");
                          var output = document.getElementById("slider_value");
                          output.innerHTML = slider.value;
                          slider.oninput = function () {
                            output.innerHTML = this.value;
                            console.log("inside slide::::");
                          }
                        </script>

<script>
  $(function () {
      $("#slider_range").slider({
          range: "min",
          value : 30,
          min: 1,
          max: 1000,
          slide: function (event, ui) {
              $("slider_value").text(ui.value  + 'GBP');
              $("#maxPrice").val(ui.value);
              

          },
          stop : function (event, ui) {
             // $(".hotel_filter").trigger('change');
          }

      });
      console.log("inside slide:"+ui.value);
      $("#slider_value").text(ui.value  + 'GBP');
      $("#slider_value").text($("#slider_range").slider("values") + 'GBP');
  });
</script>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header">
                    <a class="collapsed btn" data-bs-toggle="collapse" href="#collapseThree">
                      <span><i class="fa-solid fa-clock"></i> Layover Time</span> <i class="fa-solid fa-angle-down"></i>
                    </a>
                  </div>
                  <div id="collapseThree" class="collapse" data-bs-parent="#accordion">
                    <div class="card-body">
                      <div class="filter-style">
                        <div class="searchBar-single-wrap mb-4">
                          <ul class="row align-items-center justify-content-between p-0 gx-3 gy-2">
                            <li class="col-6">
                              <input type="radio" class="btn-check" value="5" name="layovertime" id="layovertime" [(ngModel)]="layovertime" (change)="applyFilter()" >
                              <label class="btn btn-sm btn-secondary rounded-1 fw-medium px-4 full-width"
                                for="00h-05h" for="layovertime"    >00h-05h</label>
                            </li>
                            <li class="col-6">
                              <input type="radio" class="btn-check" value="10" name="layovertime" id="layovertime2" [(ngModel)]="layovertime" (change)="applyFilter()">
                              <label class="btn btn-sm btn-secondary rounded-1 fw-medium px-4 full-width" for="05h-10h" for="layovertime2" >
                                05h-10h
                              </label>
                            </li>
                            <li class="col-6">
                              <input type="radio" class="btn-check" value="15" name="layovertime" id="layovertime3" [(ngModel)]="layovertime" (change)="applyFilter()">
                              <label class="btn btn-sm btn-secondary rounded-1 fw-medium px-4 full-width" for="10h-15h" for="layovertime3">
                                10h-15h
                              </label>
                            </li>
                            <li class="col-6">
                              <input type="radio" class="btn-check" value="20" name="layovertime" id="layovertime4" [(ngModel)]="layovertime" (change)="applyFilter()">
                              <label class="btn btn-sm btn-secondary rounded-1 fw-medium px-4 full-width" for="15h+" for="layovertime4">
                                15h+
                              </label>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header">
                    <a class="btn" data-bs-toggle="collapse" href="#pps4">
                      <span><i class="fa-solid fa-hand"></i> Stops</span> <i
                        class="fa-solid fa-angle-down"></i>
                    </a>
                  </div>
                  <div id="pps4" class="collapse " data-bs-parent="#accordion">
                    <div class="card-body">
                      <div class="sidebar-checkbox">
                        <div class="row y-gap-10 items-center justify-between">
                          <div class="col-auto">
                            <div class="d-flex items-center">
                              <div class="form-checkbox ">
                                <input type="radio" name="stops" value="0" [(ngModel)]="stops" (change)="applyStopFilter()">
                                <div class="form-checkbox__mark">
                                  <div class="form-checkbox__icon icon-check"></div>
                                </div>
                              </div>
                              <div class="text-14 ml-10">Non Stop</div>
                            </div>
                          </div>
                          <div class="col-auto">
                            <div class="text-14 text-light-1"></div>
                          </div>
                        </div>
                        <div class="row y-gap-10 items-center justify-between">
                          <div class="col-auto">
                            <div class="d-flex items-center">
                              <div class="form-checkbox ">
                                <input type="radio" name="stops" value="1" [(ngModel)]="stops" (change)="applyStopFilter()">
                                <div class="form-checkbox__mark">
                                  <div class="form-checkbox__icon icon-check"></div>
                                </div>
                              </div>
                              <div class="text-14 ml-10">1 Stop</div>
                            </div>
                          </div>
                          <div class="col-auto">
                            <div class="text-14 text-light-1"></div>
                          </div>
                        </div>
                        <div class="row y-gap-10 items-center justify-between">
                          <div class="col-auto">
                            <div class="d-flex items-center">
                              <div class="form-checkbox ">
                                <input type="radio" name="stops" value="2" [(ngModel)]="stops" (change)="applyStopFilter()">
                                <div class="form-checkbox__mark">
                                  <div class="form-checkbox__icon icon-check"></div>
                                </div>
                              </div>
                              <div class="text-14 ml-10">1+ Stop</div>
                            </div>
                          </div>
                          <div class="col-auto">
                            <div class="text-14 text-light-1"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header">
                    <a class="btn" data-bs-toggle="collapse" href="#pps5">
                      <span><i class="fa-solid fa-suitcase-rolling"></i> Baggage</span> <i
                        class="fa-solid fa-angle-down"></i>
                    </a>
                  </div>
                  <div id="pps5" class="collapse" data-bs-parent="#accordion">
                    <div class="card-body">
                      <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                        <li class="nav-item" role="presentation">
                          <button class="nav-link active" id="pills-baggageonward-tab" data-bs-toggle="pill"
                            data-bs-target="#pills-baggageonward" type="button" role="tab"
                            aria-controls="pills-baggageonward" aria-selected="true">Onward</button>
                        </li>
                        <li class="nav-item" role="presentation">
                          <button class="nav-link" id="pills-baggagereturn-tab" data-bs-toggle="pill"
                            data-bs-target="#pills-baggagereturn" type="button" role="tab"
                            aria-controls="pills-baggagereturn" aria-selected="false">Return</button>
                        </li>
                      </ul>
                      <div class="tab-content">
                        <div class="tab-pane fade show active" id="pills-baggageonward" role="tabpanel"
                          aria-labelledby="pills-baggageonward-tab" tabindex="0">
                          <div class="sidebar-checkbox">
                            <div class="row y-gap-10 items-center justify-between">
                              <div class="col-auto">
                                <div class="d-flex items-center">
                                  <div class="form-checkbox ">
                                    <input type="radio" name="baggage"  value="15" [(ngModel)]="baggage" (change)="applyBaggageFilter()">
                                    <div class="form-checkbox__mark">
                                      <div class="form-checkbox__icon icon-check">
                                      </div>
                                    </div>
                                  </div>
                                  <div class="text-14 ml-10">15KG</div>
                                </div>
                              </div>
                            </div>
                            <div class="row y-gap-10 items-center justify-between">
                              <div class="col-auto">
                                <div class="d-flex items-center">
                                  <div class="form-checkbox ">
                                    <input type="radio"  name="baggage"  value="20" [(ngModel)]="baggage" (change)="applyBaggageFilter()">
                                    <div class="form-checkbox__mark">
                                      <div class="form-checkbox__icon icon-check">
                                      </div>
                                    </div>
                                  </div>
                                  <div class="text-14 ml-10">20KG</div>
                                </div>
                              </div>
                            </div>
                            <div class="row y-gap-10 items-center justify-between">
                              <div class="col-auto">
                                <div class="d-flex items-center">
                                  <div class="form-checkbox ">
                                    <input type="radio"  name="baggage"  value="25" [(ngModel)]="baggage" (change)="applyBaggageFilter()">
                                    <div class="form-checkbox__mark">
                                      <div class="form-checkbox__icon icon-check">
                                      </div>
                                    </div>
                                  </div>
                                  <div class="text-14 ml-10">25KG</div>
                                </div>
                              </div>
                            </div>
                            <div class="row y-gap-10 items-center justify-between">
                              <div class="col-auto">
                                <div class="d-flex items-center">
                                  <div class="form-checkbox ">
                                    <input type="radio"  name="baggage"  value="30" [(ngModel)]="baggage" (change)="applyBaggageFilter()">
                                    <div class="form-checkbox__mark">
                                      <div class="form-checkbox__icon icon-check">
                                      </div>
                                    </div>
                                  </div>
                                  <div class="text-14 ml-10">30KG</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="tab-pane fade" id="pills-baggagereturn" role="tabpanel"
                          aria-labelledby="pills-baggagereturn-tab" tabindex="0">
                          <div class="sidebar-checkbox">
                            <div class="row y-gap-10 items-center justify-between">
                              <div class="col-auto">
                                <div class="d-flex items-center">
                                  <div class="form-checkbox ">
                                    <input type="checkbox" name="name">
                                    <div class="form-checkbox__mark">
                                      <div class="form-checkbox__icon icon-check">
                                      </div>
                                    </div>
                                  </div>
                                  <div class="text-14 ml-10">15KG</div>
                                </div>
                              </div>
                            </div>
                            <div class="row y-gap-10 items-center justify-between">
                              <div class="col-auto">
                                <div class="d-flex items-center">
                                  <div class="form-checkbox ">
                                    <input type="checkbox" name="name">
                                    <div class="form-checkbox__mark">
                                      <div class="form-checkbox__icon icon-check">
                                      </div>
                                    </div>
                                  </div>
                                  <div class="text-14 ml-10">20KG</div>
                                </div>
                              </div>
                            </div>
                            <div class="row y-gap-10 items-center justify-between">
                              <div class="col-auto">
                                <div class="d-flex items-center">
                                  <div class="form-checkbox ">
                                    <input type="checkbox" name="name">
                                    <div class="form-checkbox__mark">
                                      <div class="form-checkbox__icon icon-check">
                                      </div>
                                    </div>
                                  </div>
                                  <div class="text-14 ml-10">25KG</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header">
                    <a class="btn" data-bs-toggle="collapse" href="#pps6">
                      <span><i class="fa-solid fa-star-of-life"></i> Fare Type</span> <i
                        class="fa-solid fa-angle-down"></i>
                    </a>
                  </div>
                  <div id="pps6" class="collapse " data-bs-parent="#accordion">
                    <div class="card-body">
                      <div class="sidebar-checkbox">
                        <div class="row y-gap-10 items-center justify-between">
                          <div class="col-auto">
                            <div class="d-flex items-center">
                              <div class="form-checkbox ">
                                <input type="checkbox" name="name">
                                <div class="form-checkbox__mark">
                                  <div class="form-checkbox__icon icon-check"></div>
                                </div>
                              </div>
                              <div class="text-14 ml-10">Refundable</div>
                            </div>
                          </div>
                        </div>
                        <div class="row y-gap-10 items-center justify-between">
                          <div class="col-auto">
                            <div class="d-flex items-center">
                              <div class="form-checkbox ">
                                <input type="checkbox" name="name">
                                <div class="form-checkbox__mark">
                                  <div class="form-checkbox__icon icon-check"></div>
                                </div>
                              </div>
                              <div class="text-14 ml-10">Non-Refundable</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header">
                    <a class="btn" data-bs-toggle="collapse" href="#pps7">
                      <span><i class="fa-solid fa-clock-rotate-left"></i> Travel Time</span> <i
                        class="fa-solid fa-angle-down"></i>
                    </a>
                  </div>
                  <div id="pps7" class="collapse" data-bs-parent="#accordion">
                    <div class="card-body">
                      <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                        <li class="nav-item" role="presentation">
                          <button class="nav-link active" id="pills-traveltimeonward-tab" data-bs-toggle="pill"
                            data-bs-target="#pills-traveltimeonward" type="button" role="tab"
                            aria-controls="pills-traveltimeonward" aria-selected="true">Onward</button>
                        </li>
                        <li class="nav-item" role="presentation">
                          <button class="nav-link" id="pills-traveltimereturn-tab" data-bs-toggle="pill"
                            data-bs-target="#pills-traveltimereturn" type="button" role="tab"
                            aria-controls="pills-traveltimereturn" aria-selected="false">Return</button>
                        </li>
                      </ul>
                      <div class="tab-content">
                        <div class="tab-pane fade show active" id="pills-traveltimeonward" role="tabpanel"
                          aria-labelledby="pills-traveltimeonward-tab" tabindex="0">
                          <div class="sidebar__item">
                            <h5 class="text-18 fw-500 mb-10">Departure From New Delhi</h5>
                            <div class="sidebar-checkbox">
                              <div class="row y-gap-10 items-center justify-between">
                                <div class="col-auto">
                                  <div class="timeSlotsOuter">
                                    <div class="appendBottom12 filterTimeSlots ">
                                      <span class="appendBottom2 checkBlockIcon">
                                        <img
                                          src="https://imgak.mmtcdn.com/flights/assets/media/dt/listing/left-filters/morning_inactive.png?v=1"
                                          class="img-fluid" alt="...">
                                      </span>
                                      <div class="boldFont">Before <b>6</b> AM
                                      </div>
                                      <p class="fontSize10 boldFont">GBP 5,235</p>
                                    </div>
                                    <div class="appendBottom12 filterTimeSlots ">
                                      <span class="appendBottom2 checkBlockIcon">
                                        <img
                                          src="https://imgak.mmtcdn.com/flights/assets/media/dt/listing/left-filters/noon_inactive.png?v=1"
                                          class="img-fluid" alt="...">
                                      </span>
                                      <div class="boldFont"><b>6</b> AM -
                                        <b>12</b> PM</div>
                                      <p class="fontSize10 boldFont">GBP 5,234</p>
                                    </div>
                                    <div class="appendBottom12 filterTimeSlots ">
                                      <span class="appendBottom2 checkBlockIcon">
                                        <img
                                          src="https://imgak.mmtcdn.com/flights/assets/media/dt/listing/left-filters/evening_inactive.png?v=1"
                                          class="img-fluid" alt="...">
                                      </span>
                                      <div class="boldFont"><b>12</b> PM -
                                        <b>6</b> PM</div>
                                      <p class="fontSize10 boldFont">GBP 5,235</p>
                                    </div>
                                    <div class="appendBottom12 filterTimeSlots ">
                                      <span class="appendBottom2 checkBlockIcon">
                                        <img
                                          src="https://imgak.mmtcdn.com/flights/assets/media/dt/listing/left-filters/night_inactive.png?v=1"
                                          class="img-fluid" alt="...">
                                      </span>
                                      <div class="boldFont">After <b>6</b> PM
                                      </div>
                                      <p class="fontSize10 boldFont">GBP 5,074</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="sidebar__item">
                            <h5 class="text-18 fw-500 mb-10">Arrival at Bengaluru</h5>
                            <div class="sidebar-checkbox">
                              <div class="row y-gap-10 items-center justify-between">
                                <div class="col-auto">
                                  <div class="timeSlotsOuter">
                                    <div class="appendBottom12 filterTimeSlots ">
                                      <span class="appendBottom2 checkBlockIcon">
                                        <img
                                          src="https://imgak.mmtcdn.com/flights/assets/media/dt/listing/left-filters/morning_inactive.png?v=1"
                                          class="img-fluid" alt="...">
                                      </span>
                                      <div class="boldFont">Before <b>6</b> AM
                                      </div>
                                      <p class="fontSize10 boldFont">GBP 5,235</p>
                                    </div>
                                    <div class="appendBottom12 filterTimeSlots ">
                                      <span class="appendBottom2 checkBlockIcon">
                                        <img
                                          src="https://imgak.mmtcdn.com/flights/assets/media/dt/listing/left-filters/noon_inactive.png?v=1"
                                          class="img-fluid" alt="...">
                                      </span>
                                      <div class="boldFont"><b>6</b> AM -
                                        <b>12</b> PM</div>
                                      <p class="fontSize10 boldFont">GBP 5,234</p>
                                    </div>
                                    <div class="appendBottom12 filterTimeSlots ">
                                      <span class="appendBottom2 checkBlockIcon">
                                        <img
                                          src="https://imgak.mmtcdn.com/flights/assets/media/dt/listing/left-filters/evening_inactive.png?v=1"
                                          class="img-fluid" alt="...">
                                      </span>
                                      <div class="boldFont"><b>12</b> PM -
                                        <b>6</b> PM</div>
                                      <p class="fontSize10 boldFont">GBP 5,235</p>
                                    </div>
                                    <div class="appendBottom12 filterTimeSlots ">
                                      <span class="appendBottom2 checkBlockIcon">
                                        <img
                                          src="https://imgak.mmtcdn.com/flights/assets/media/dt/listing/left-filters/night_inactive.png?v=1"
                                          class="img-fluid" alt="...">
                                      </span>
                                      <div class="boldFont">After <b>6</b> PM
                                      </div>
                                      <p class="fontSize10 boldFont">GBP 5,074</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="tab-pane fade" id="pills-traveltimereturn" role="tabpanel"
                          aria-labelledby="pills-traveltimereturn-tab" tabindex="0">
                          <div class="sidebar__item">
                            <h5 class="text-14 fw-500 mb-10">Departure From New Delhi</h5>
                            <div class="sidebar-checkbox">
                              <div class="row y-gap-10 items-center justify-between">
                                <div class="col-auto">
                                  <div class="timeSlotsOuter">
                                    <div class="appendBottom12 filterTimeSlots ">
                                      <span class="appendBottom2 checkBlockIcon">
                                        <img
                                          src="https://imgak.mmtcdn.com/flights/assets/media/dt/listing/left-filters/morning_inactive.png?v=1"
                                          class="img-fluid" alt="...">
                                      </span>
                                      <div class="boldFont">Before <b>6</b> AM
                                      </div>
                                      <!-- <p class="fontSize10 boldFont">GBP 5,235</p> -->
                                    </div>
                                    <div class="appendBottom12 filterTimeSlots ">
                                      <span class="appendBottom2 checkBlockIcon">
                                        <img
                                          src="https://imgak.mmtcdn.com/flights/assets/media/dt/listing/left-filters/noon_inactive.png?v=1"
                                          class="img-fluid" alt="...">
                                      </span>
                                      <div class="boldFont"><b>6</b> AM -
                                        <b>12</b> PM</div>
                                      <!-- <p class="fontSize10 boldFont">GBP 5,234</p> -->
                                    </div>
                                    <div class="appendBottom12 filterTimeSlots ">
                                      <span class="appendBottom2 checkBlockIcon">
                                        <img
                                          src="https://imgak.mmtcdn.com/flights/assets/media/dt/listing/left-filters/evening_inactive.png?v=1"
                                          class="img-fluid" alt="...">
                                      </span>
                                      <div class="boldFont"><b>12</b> PM -
                                        <b>6</b> PM</div>
                                      <!-- <p class="fontSize10 boldFont">GBP 5,235</p> -->
                                    </div>
                                    <div class="appendBottom12 filterTimeSlots ">
                                      <span class="appendBottom2 checkBlockIcon">
                                        <img
                                          src="https://imgak.mmtcdn.com/flights/assets/media/dt/listing/left-filters/night_inactive.png?v=1"
                                          class="img-fluid" alt="...">
                                      </span>
                                      <div class="boldFont">After <b>6</b> PM
                                      </div>
                                      <!-- <p class="fontSize10 boldFont">GBP 5,074</p> -->
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="sidebar__item">
                            <h5 class="text-14 fw-500 mb-10">Arrival at Bengaluru</h5>
                            <div class="sidebar-checkbox">
                              <div class="row y-gap-10 items-center justify-between">
                                <div class="col-auto">
                                  <div class="timeSlotsOuter">
                                    <div class="appendBottom12 filterTimeSlots ">
                                      <span class="appendBottom2 checkBlockIcon">
                                        <img
                                          src="https://imgak.mmtcdn.com/flights/assets/media/dt/listing/left-filters/morning_inactive.png?v=1"
                                          class="img-fluid" alt="...">
                                      </span>
                                      <div class="boldFont">Before <b>6</b> AM
                                      </div>
                                      <p class="fontSize10 boldFont">GBP 5,235</p>
                                    </div>
                                    <div class="appendBottom12 filterTimeSlots ">
                                      <span class="appendBottom2 checkBlockIcon">
                                        <img
                                          src="https://imgak.mmtcdn.com/flights/assets/media/dt/listing/left-filters/noon_inactive.png?v=1"
                                          class="img-fluid" alt="...">
                                      </span>
                                      <div class="boldFont"><b>6</b> AM -
                                        <b>12</b> PM</div>
                                      <p class="fontSize10 boldFont">GBP 5,234</p>
                                    </div>
                                    <div class="appendBottom12 filterTimeSlots ">
                                      <span class="appendBottom2 checkBlockIcon">
                                        <img
                                          src="https://imgak.mmtcdn.com/flights/assets/media/dt/listing/left-filters/evening_inactive.png?v=1"
                                          class="img-fluid" alt="...">
                                      </span>
                                      <div class="boldFont"><b>12</b> PM -
                                        <b>6</b> PM</div>
                                      <p class="fontSize10 boldFont">GBP 5,235</p>
                                    </div>
                                    <div class="appendBottom12 filterTimeSlots ">
                                      <span class="appendBottom2 checkBlockIcon">
                                        <img
                                          src="https://imgak.mmtcdn.com/flights/assets/media/dt/listing/left-filters/night_inactive.png?v=1"
                                          class="img-fluid" alt="...">
                                      </span>
                                      <div class="boldFont">After <b>6</b> PM
                                      </div>
                                      <p class="fontSize10 boldFont">GBP 5,074</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header">
                    <a class="btn" data-bs-toggle="collapse" href="#pps8">
                      <span><i class="fa-solid fa-plane"></i> Airlines</span> <i class="fa-solid fa-angle-down"></i>
                    </a>
                  </div>
                  <div id="pps8" class="collapse" data-bs-parent="#accordion">
                    <div class="card-body">
                      <div class="sidebar-checkbox">

                        

                        <div class="row y-gap-10 items-center justify-between" *ngFor="let airline of uniqueAirlines;">
                          <div class="col-auto">
                            <div class="d-flex items-center">
                              <div class="form-checkbox ">
                                <input type="radio" name="airlines"  value="{{airline}}" [(ngModel)]="airlines" (change)="applyAirlinesFilter()">
                                <div class="form-checkbox__mark">
                                  <div class="form-checkbox__icon icon-check"></div>
                                </div>
                              </div>
                              <div class="text-14 ml-10">{{airline}}</div>
                            </div>
                          </div>
                          <div class="col-auto">
                            <div class="text-14 text-light-1"></div>
                          </div>
                        </div>


                        <!-- <div class="row y-gap-10 items-center justify-between">
                          <div class="col-auto">
                            <div class="d-flex items-center">
                              <div class="form-checkbox ">
                                <input type="radio" name="airlines"  value="Azerbaijan Air" [(ngModel)]="airlines" (change)="applyAirlinesFilter()">
                                <div class="form-checkbox__mark">
                                  <div class="form-checkbox__icon icon-check"></div>
                                </div>
                              </div>
                              <div class="text-14 ml-10">Azerbaijan Air</div>
                            </div>
                          </div>
                          <div class="col-auto">
                            <div class="text-14 text-light-1"></div>
                          </div>
                        </div>
                        <div class="row y-gap-10 items-center justify-between">
                          <div class="col-auto">
                            <div class="d-flex items-center">
                              <div class="form-checkbox ">
                                <input type="radio" name="airlines"  value="Saudi Arabian" [(ngModel)]="airlines" (change)="applyAirlinesFilter()">
                                <div class="form-checkbox__mark">
                                  <div class="form-checkbox__icon icon-check"></div>
                                </div>
                              </div>
                              <div class="text-14 ml-10">Saudi Arabian</div>
                            </div>
                          </div>
                          <div class="col-auto">
                            <div class="text-14 text-light-1"></div>
                          </div>
                        </div>
                        <div class="row y-gap-10 items-center justify-between">
                          <div class="col-auto">
                            <div class="d-flex items-center">
                              <div class="form-checkbox ">
                                <input type="radio" name="airlines"  value="Royal Air Maroc" [(ngModel)]="airlines" (change)="applyAirlinesFilter()">
                                <div class="form-checkbox__mark">
                                  <div class="form-checkbox__icon icon-check"></div>
                                </div>
                              </div>
                              <div class="text-14 ml-10">Royal Air Maroc</div>
                            </div>
                          </div>
                          <div class="col-auto">
                            <div class="text-14 text-light-1"></div>
                          </div>
                        </div>
                        <div class="row y-gap-10 items-center justify-between">
                          <div class="col-auto">
                            <div class="d-flex items-center">
                              <div class="form-checkbox ">
                                <input type="radio" name="airlines"  value="Aegean Airlines" [(ngModel)]="airlines" (change)="applyAirlinesFilter()">
                                <div class="form-checkbox__mark">
                                  <div class="form-checkbox__icon icon-check"></div>
                                </div>
                              </div>
                              <div class="text-14 ml-10">Aegean Airlines</div>
                            </div>
                          </div>
                          <div class="col-auto">
                            <div class="text-14 text-light-1"></div>
                          </div>
                        </div>
                        <div class="row y-gap-10 items-center justify-between">
                          <div class="col-auto">
                            <div class="d-flex items-center">
                              <div class="form-checkbox ">
                                <input type="radio" name="airlines"  value="Turkish Airlines" [(ngModel)]="airlines" (change)="applyAirlinesFilter()">
                                <div class="form-checkbox__mark">
                                  <div class="form-checkbox__icon icon-check"></div>
                                </div>
                              </div>
                              <div class="text-14 ml-10">Turkish Airlines</div>
                            </div>
                          </div>
                          <div class="col-auto">
                            <div class="text-14 text-light-1"></div>
                          </div>
                        </div>
                        <div class="row y-gap-10 items-center justify-between">
                          <div class="col-auto">
                            <div class="d-flex items-center">
                              <div class="form-checkbox ">
                                <input type="radio" name="airlines"  value="Middle East" [(ngModel)]="airlines" (change)="applyAirlinesFilter()">
                                <div class="form-checkbox__mark">
                                  <div class="form-checkbox__icon icon-check"></div>
                                </div>
                              </div>
                              <div class="text-14 ml-10">Middle East</div>
                            </div>
                          </div>
                          <div class="col-auto">
                            <div class="text-14 text-light-1"></div>
                          </div>
                        </div>
                        <div class="row y-gap-10 items-center justify-between">
                          <div class="col-auto">
                            <div class="d-flex items-center">
                              <div class="form-checkbox ">
                                <input type="radio" name="airlines"  value="Egyptair" [(ngModel)]="airlines" (change)="applyAirlinesFilter()">
                                <div class="form-checkbox__mark">
                                  <div class="form-checkbox__icon icon-check"></div>
                                </div>
                              </div>
                              <div class="text-14 ml-10">Egyptair</div>
                            </div>
                          </div>
                          <div class="col-auto">
                            <div class="text-14 text-light-1"></div>
                          </div>
                        </div>

                        <div class="row y-gap-10 items-center justify-between">
                          <div class="col-auto">
                            <div class="d-flex items-center">
                              <div class="form-checkbox ">
                                <input type="radio" name="airlines"  value="British Airways" [(ngModel)]="airlines" (change)="applyAirlinesFilter()">
                                <div class="form-checkbox__mark">
                                  <div class="form-checkbox__icon icon-check"></div>
                                </div>
                              </div>
                              <div class="text-14 ml-10">British Airways</div>
                            </div>
                          </div>
                          <div class="col-auto">
                            <div class="text-14 text-light-1"></div>
                          </div>
                        </div>
                        <div class="row y-gap-10 items-center justify-between">
                          <div class="col-auto">
                            <div class="d-flex items-center">
                              <div class="form-checkbox ">
                                <input type="radio" name="airlines"  value="Kuwait Airways" [(ngModel)]="airlines" (change)="applyAirlinesFilter()">
                                <div class="form-checkbox__mark">
                                  <div class="form-checkbox__icon icon-check"></div>
                                </div>
                              </div>
                              <div class="text-14 ml-10">Kuwait Airways</div>
                            </div>
                          </div>
                          <div class="col-auto">
                            <div class="text-14 text-light-1"></div>
                          </div>
                        </div>

                        <div class="row y-gap-10 items-center justify-between">
                          <div class="col-auto">
                            <div class="d-flex items-center">
                              <div class="form-checkbox ">
                                <input type="radio" name="airlines"  value="Emirates" [(ngModel)]="airlines" (change)="applyAirlinesFilter()">
                                <div class="form-checkbox__mark">
                                  <div class="form-checkbox__icon icon-check"></div>
                                </div>
                              </div>
                              <div class="text-14 ml-10">Emirates</div>
                            </div>
                          </div>
                          <div class="col-auto">
                            <div class="text-14 text-light-1"></div>
                          </div>
                        </div> -->

                        <!-- <div class="w-100">
                          <a href="#" class="mt-3 text-14 d-block">+ 1 more</a>
                        </div> -->
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header">
                    <a class="btn" data-bs-toggle="collapse" href="#pps9">
                      <span><i class="fa-solid fa-plane-up"></i> Aircraft</span> <i class="fa-solid fa-angle-down"></i>
                    </a>
                  </div>
                  <div id="pps9" class="collapse" data-bs-parent="#accordion">
                    <div class="card-body">
                      <div class="sidebar-checkbox">
                        <div class="row y-gap-10 items-center justify-between">
                          <div class="col-auto">
                            <div class="d-flex items-center">
                              <div class="form-checkbox ">
                                <input type="checkbox" name="name">
                                <div class="form-checkbox__mark">
                                  <div class="form-checkbox__icon icon-check"></div>
                                </div>
                              </div>
                              <div class="text-14 ml-10">32W</div>
                            </div>
                          </div>
                        </div>
                        <div class="row y-gap-10 items-center justify-between">
                          <div class="col-auto">
                            <div class="d-flex items-center">
                              <div class="form-checkbox ">
                                <input type="checkbox" name="name">
                                <div class="form-checkbox__mark">
                                  <div class="form-checkbox__icon icon-check"></div>
                                </div>
                              </div>
                              <div class="text-14 ml-10">7MA</div>
                            </div>
                          </div>
                        </div>
                        <div class="row y-gap-10 items-center justify-between">
                          <div class="col-auto">
                            <div class="d-flex items-center">
                              <div class="form-checkbox ">
                                <input type="checkbox" name="name">
                                <div class="form-checkbox__mark">
                                  <div class="form-checkbox__icon icon-check"></div>
                                </div>
                              </div>
                              <div class="text-14 ml-10">Airbus A319</div>
                            </div>
                          </div>
                        </div>
                        <div class="row y-gap-10 items-center justify-between">
                          <div class="col-auto">
                            <div class="d-flex items-center">
                              <div class="form-checkbox ">
                                <input type="checkbox" name="name">
                                <div class="form-checkbox__mark">
                                  <div class="form-checkbox__icon icon-check"></div>
                                </div>
                              </div>
                              <div class="text-14 ml-10">Airbus A320</div>
                            </div>
                          </div>
                        </div>
                        <div class="row y-gap-10 items-center justify-between">
                          <div class="col-auto">
                            <div class="d-flex items-center">
                              <div class="form-checkbox ">
                                <input type="checkbox" name="name">
                                <div class="form-checkbox__mark">
                                  <div class="form-checkbox__icon icon-check"></div>
                                </div>
                              </div>
                              <div class="text-14 ml-10">Airbus A320-100</div>
                            </div>
                          </div>
                        </div>
                        <div class="row y-gap-10 items-center justify-between">
                          <div class="col-auto">
                            <div class="d-flex items-center">
                              <div class="form-checkbox ">
                                <input type="checkbox" name="name">
                                <div class="form-checkbox__mark">
                                  <div class="form-checkbox__icon icon-check"></div>
                                </div>
                              </div>
                              <div class="text-14 ml-10">Airbus A320-212</div>
                            </div>
                          </div>
                        </div>
                        <div class="row y-gap-10 items-center justify-between">
                          <div class="col-auto">
                            <div class="d-flex items-center">
                              <div class="form-checkbox ">
                                <input type="checkbox" name="name">
                                <div class="form-checkbox__mark">
                                  <div class="form-checkbox__icon icon-check"></div>
                                </div>
                              </div>
                              <div class="text-14 ml-10">Airbus A321</div>
                            </div>
                          </div>
                        </div>
                        <div class="w-100">
                          <a href="#" class="mt-3 text-14 d-block">+ 1 more</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header">
                    <a class="btn" data-bs-toggle="collapse" href="#pps10">
                      <span><i class="fa-solid fa-plane-circle-check"></i> Layover Airports</span>
                      <i class="fa-solid fa-angle-down"></i>
                    </a>
                  </div>
                  <div id="pps10" class="collapse" data-bs-parent="#accordion">
                    <div class="card-body">
                      <div class="sidebar-checkbox">
                        <div class="row y-gap-10 items-center justify-between">
                          <div class="col-auto">
                            <div class="d-flex items-center">
                              <div class="form-checkbox ">
                                <input type="checkbox" name="name">
                                <div class="form-checkbox__mark">
                                  <div class="form-checkbox__icon icon-check"></div>
                                </div>
                              </div>
                              <div class="text-14 ml-10">Ahmedabad Airport (AMD)</div>
                            </div>
                          </div>
                        </div>
                        <div class="row y-gap-10 items-center justify-between">
                          <div class="col-auto">
                            <div class="d-flex items-center">
                              <div class="form-checkbox ">
                                <input type="checkbox" name="name">
                                <div class="form-checkbox__mark">
                                  <div class="form-checkbox__icon icon-check"></div>
                                </div>
                              </div>
                              <div class="text-14 ml-10">Sanganeer Airport (JAI)</div>
                            </div>
                          </div>
                        </div>
                        <div class="row y-gap-10 items-center justify-between">
                          <div class="col-auto">
                            <div class="d-flex items-center">
                              <div class="form-checkbox ">
                                <input type="checkbox" name="name">
                                <div class="form-checkbox__mark">
                                  <div class="form-checkbox__icon icon-check"></div>
                                </div>
                              </div>
                              <div class="text-14 ml-10">Kempegowda International Airport
                                (BLR)</div>
                            </div>
                          </div>
                        </div>
                        <div class="row y-gap-10 items-center justify-between">
                          <div class="col-auto">
                            <div class="d-flex items-center">
                              <div class="form-checkbox ">
                                <input type="checkbox" name="name">
                                <div class="form-checkbox__mark">
                                  <div class="form-checkbox__icon icon-check"></div>
                                </div>
                              </div>
                              <div class="text-14 ml-10">Sonegaon Airport (NAG)</div>
                            </div>
                          </div>
                        </div>
                        <div class="row y-gap-10 items-center justify-between">
                          <div class="col-auto">
                            <div class="d-flex items-center">
                              <div class="form-checkbox ">
                                <input type="checkbox" name="name">
                                <div class="form-checkbox__mark">
                                  <div class="form-checkbox__icon icon-check"></div>
                                </div>
                              </div>
                              <div class="text-14 ml-10">Chennai Airport (MAA)</div>
                            </div>
                          </div>
                        </div>
                        <div class="row y-gap-10 items-center justify-between">
                          <div class="col-auto">
                            <div class="d-flex items-center">
                              <div class="form-checkbox ">
                                <input type="checkbox" name="name">
                                <div class="form-checkbox__mark">
                                  <div class="form-checkbox__icon icon-check"></div>
                                </div>
                              </div>
                              <div class="text-14 ml-10">Trivandrum International Airport
                                (TRV)</div>
                            </div>
                          </div>
                        </div>
                        <div class="row y-gap-10 items-center justify-between">
                          <div class="col-auto">
                            <div class="d-flex items-center">
                              <div class="form-checkbox ">
                                <input type="checkbox" name="name">
                                <div class="form-checkbox__mark">
                                  <div class="form-checkbox__icon icon-check"></div>
                                </div>
                              </div>
                              <div class="text-14 ml-10">Amausi Airport (LKO)</div>
                            </div>
                          </div>
                        </div>
                        <div class="row y-gap-10 items-center justify-between">
                          <div class="col-auto">
                            <div class="d-flex items-center">
                              <div class="form-checkbox ">
                                <input type="checkbox" name="name">
                                <div class="form-checkbox__mark">
                                  <div class="form-checkbox__icon icon-check"></div>
                                </div>
                              </div>
                              <div class="text-14 ml-10">Indore Airport (IDR)</div>
                            </div>
                          </div>
                        </div>
                        <div class="row y-gap-10 items-center justify-between">
                          <div class="col-auto">
                            <div class="d-flex items-center">
                              <div class="form-checkbox ">
                                <input type="checkbox" name="name">
                                <div class="form-checkbox__mark">
                                  <div class="form-checkbox__icon icon-check"></div>
                                </div>
                              </div>
                              <div class="text-14 ml-10">(HSR)</div>
                            </div>
                          </div>
                        </div>
                        <div class="row y-gap-10 items-center justify-between">
                          <div class="col-auto">
                            <div class="d-flex items-center">
                              <div class="form-checkbox ">
                                <input type="checkbox" name="name">
                                <div class="form-checkbox__mark">
                                  <div class="form-checkbox__icon icon-check"></div>
                                </div>
                              </div>
                              <div class="text-14 ml-10">Rajiv Gandhi International
                                Airport (HYD)
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row y-gap-10 items-center justify-between">
                          <div class="col-auto">
                            <div class="d-flex items-center">
                              <div class="form-checkbox ">
                                <input type="checkbox" name="name">
                                <div class="form-checkbox__mark">
                                  <div class="form-checkbox__icon icon-check"></div>
                                </div>
                              </div>
                              <div class="text-14 ml-10">Dabolim Airport (GOI)</div>
                            </div>
                          </div>
                        </div>
                        <div class="row y-gap-10 items-center justify-between">
                          <div class="col-auto">
                            <div class="d-flex items-center">
                              <div class="form-checkbox ">
                                <input type="checkbox" name="name">
                                <div class="form-checkbox__mark">
                                  <div class="form-checkbox__icon icon-check"></div>
                                </div>
                              </div>
                              <div class="text-14 ml-10">Raja Sansi Airport (ATQ)</div>
                            </div>
                          </div>
                        </div>
                        <div class="row y-gap-10 items-center justify-between">
                          <div class="col-auto">
                            <div class="d-flex items-center">
                              <div class="form-checkbox ">
                                <input type="checkbox" name="name">
                                <div class="form-checkbox__mark">
                                  <div class="form-checkbox__icon icon-check"></div>
                                </div>
                              </div>
                              <div class="text-14 ml-10">Netaji Subhas Chandra Airport
                                (CCU)</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header">
                    <a class="btn" data-bs-toggle="collapse" href="#pps11">
                      <span><i class="fa-solid fa-plane-arrival"></i> Nearest Airport</span> <i
                        class="fa-solid fa-angle-down"></i>
                    </a>
                  </div>
                  <div id="pps11" class="collapse" data-bs-parent="#accordion">
                    <div class="card-body">
                      <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                        <li class="nav-item" role="presentation">
                          <button class="nav-link active" id="pills-onward-tab" data-bs-toggle="pill"
                            data-bs-target="#pills-onward" type="button" role="tab" aria-controls="pills-onward"
                            aria-selected="true">Onward</button>
                        </li>
                        <li class="nav-item" role="presentation">
                          <button class="nav-link" id="pills-return-tab" data-bs-toggle="pill"
                            data-bs-target="#pills-return" type="button" role="tab" aria-controls="pills-return"
                            aria-selected="false">Return</button>
                        </li>
                      </ul>
                      <div class="tab-content" id="pills-tabContent">
                        <div class="tab-pane fade show active" id="pills-onward" role="tabpanel"
                          aria-labelledby="pills-onward-tab" tabindex="0">
                          <div class="sidebar__item scroll">
                            <h5 class="text-18 fw-500 mb-10">Departure Airport</h5>
                            <div class="sidebar-checkbox">


                              <div class="row y-gap-10 items-center justify-between"  *ngFor="let departureAirport of uniqueDepartureAirport;">
                                <div class="col-auto">
                                  <div class="d-flex items-center">
                                    <div class="form-checkbox ">
                                      <input type="radio" name="departure"  value="{{departureAirport.airportCode}}" [(ngModel)]="departure" (change)="applyDepartureFilter()">
                                      <div class="form-checkbox__mark">
                                        <div class="form-checkbox__icon icon-check">
                                        </div>
                                      </div>
                                    </div>
                                    <div class="text-14 ml-10">{{departureAirport.airportName}}
                                      ({{departureAirport.airportCode}})</div>
                                  </div>
                                </div>
                              </div>

 
                               
                              
                             
                              
                             
                            </div>
                          </div>
                          <div class="sidebar__item scroll mt-4">
                            <h5 class="text-18 fw-500 mb-10">Arrival Airport</h5>
                            <div class="sidebar-checkbox">
                           
                              <div class="row y-gap-10 items-center justify-between" *ngFor="let arrivalAirport of uniqueArrivalAirport;">
                                <div class="col-auto">
                                  <div class="d-flex items-center">
                                    <div class="form-checkbox ">
                                      <input type="radio" name="arrival"  value="{{arrivalAirport.airportCode}}" [(ngModel)]="arrival" (change)="applyArrivalFilter()">
                                      <div class="form-checkbox__mark">
                                        <div class="form-checkbox__icon icon-check">
                                        </div>
                                      </div>
                                    </div>
                                    <div class="text-14 ml-10">{{arrivalAirport.airportName}}
                                      ({{arrivalAirport.airportCode}})</div>
                                  </div>
                                </div>
                              </div>


                               
                            </div>
                          </div>
                        </div>
                        <div class="tab-pane fade" id="pills-return" role="tabpanel" aria-labelledby="pills-return-tab"
                          tabindex="0">
                          <div class="sidebar__item scroll">
                            <h5 class="text-18 fw-500 mb-10">Departure Airport</h5>
                            <div class="sidebar-checkbox">
                              <div class="row y-gap-10 items-center justify-between">
                                <div class="col-auto">
                                  <div class="d-flex items-center">
                                    <div class="form-checkbox ">
                                      <input type="checkbox" name="name">
                                      <div class="form-checkbox__mark">
                                        <div class="form-checkbox__icon icon-check">
                                        </div>
                                      </div>
                                    </div>
                                    <div class="text-14 ml-10">Ahmedabad Airport
                                      (AMD)</div>
                                  </div>
                                </div>
                              </div>
                              <div class="row y-gap-10 items-center justify-between">
                                <div class="col-auto">
                                  <div class="d-flex items-center">
                                    <div class="form-checkbox ">
                                      <input type="checkbox" name="name">
                                      <div class="form-checkbox__mark">
                                        <div class="form-checkbox__icon icon-check">
                                        </div>
                                      </div>
                                    </div>
                                    <div class="text-14 ml-10">Sanganeer Airport
                                      (JAI)</div>
                                  </div>
                                </div>
                              </div>
                              <div class="row y-gap-10 items-center justify-between">
                                <div class="col-auto">
                                  <div class="d-flex items-center">
                                    <div class="form-checkbox ">
                                      <input type="checkbox" name="name">
                                      <div class="form-checkbox__mark">
                                        <div class="form-checkbox__icon icon-check">
                                        </div>
                                      </div>
                                    </div>
                                    <div class="text-14 ml-10">Kempegowda
                                      International Airport
                                      (BLR)</div>
                                  </div>
                                </div>
                              </div>
                              <div class="row y-gap-10 items-center justify-between">
                                <div class="col-auto">
                                  <div class="d-flex items-center">
                                    <div class="form-checkbox ">
                                      <input type="checkbox" name="name">
                                      <div class="form-checkbox__mark">
                                        <div class="form-checkbox__icon icon-check">
                                        </div>
                                      </div>
                                    </div>
                                    <div class="text-14 ml-10">Sonegaon Airport
                                      (NAG)</div>
                                  </div>
                                </div>
                              </div>
                              <div class="row y-gap-10 items-center justify-between">
                                <div class="col-auto">
                                  <div class="d-flex items-center">
                                    <div class="form-checkbox ">
                                      <input type="checkbox" name="name">
                                      <div class="form-checkbox__mark">
                                        <div class="form-checkbox__icon icon-check">
                                        </div>
                                      </div>
                                    </div>
                                    <div class="text-14 ml-10">Chennai Airport (MAA)
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="row y-gap-10 items-center justify-between">
                                <div class="col-auto">
                                  <div class="d-flex items-center">
                                    <div class="form-checkbox ">
                                      <input type="checkbox" name="name">
                                      <div class="form-checkbox__mark">
                                        <div class="form-checkbox__icon icon-check">
                                        </div>
                                      </div>
                                    </div>
                                    <div class="text-14 ml-10">Trivandrum
                                      International Airport
                                      (TRV)</div>
                                  </div>
                                </div>
                              </div>
                              <div class="row y-gap-10 items-center justify-between">
                                <div class="col-auto">
                                  <div class="d-flex items-center">
                                    <div class="form-checkbox ">
                                      <input type="checkbox" name="name">
                                      <div class="form-checkbox__mark">
                                        <div class="form-checkbox__icon icon-check">
                                        </div>
                                      </div>
                                    </div>
                                    <div class="text-14 ml-10">Amausi Airport (LKO)
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="row y-gap-10 items-center justify-between">
                                <div class="col-auto">
                                  <div class="d-flex items-center">
                                    <div class="form-checkbox ">
                                      <input type="checkbox" name="name">
                                      <div class="form-checkbox__mark">
                                        <div class="form-checkbox__icon icon-check">
                                        </div>
                                      </div>
                                    </div>
                                    <div class="text-14 ml-10">Indore Airport (IDR)
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="row y-gap-10 items-center justify-between">
                                <div class="col-auto">
                                  <div class="d-flex items-center">
                                    <div class="form-checkbox ">
                                      <input type="checkbox" name="name">
                                      <div class="form-checkbox__mark">
                                        <div class="form-checkbox__icon icon-check">
                                        </div>
                                      </div>
                                    </div>
                                    <div class="text-14 ml-10">(HSR)</div>
                                  </div>
                                </div>
                              </div>
                              <div class="row y-gap-10 items-center justify-between">
                                <div class="col-auto">
                                  <div class="d-flex items-center">
                                    <div class="form-checkbox ">
                                      <input type="checkbox" name="name">
                                      <div class="form-checkbox__mark">
                                        <div class="form-checkbox__icon icon-check">
                                        </div>
                                      </div>
                                    </div>
                                    <div class="text-14 ml-10">Rajiv Gandhi
                                      International
                                      Airport (HYD)</div>
                                  </div>
                                </div>
                              </div>
                              <div class="row y-gap-10 items-center justify-between">
                                <div class="col-auto">
                                  <div class="d-flex items-center">
                                    <div class="form-checkbox ">
                                      <input type="checkbox" name="name">
                                      <div class="form-checkbox__mark">
                                        <div class="form-checkbox__icon icon-check">
                                        </div>
                                      </div>
                                    </div>
                                    <div class="text-14 ml-10">Dabolim Airport (GOI)
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="row y-gap-10 items-center justify-between">
                                <div class="col-auto">
                                  <div class="d-flex items-center">
                                    <div class="form-checkbox ">
                                      <input type="checkbox" name="name">
                                      <div class="form-checkbox__mark">
                                        <div class="form-checkbox__icon icon-check">
                                        </div>
                                      </div>
                                    </div>
                                    <div class="text-14 ml-10">Raja Sansi Airport
                                      (ATQ)</div>
                                  </div>
                                </div>
                              </div>
                              <div class="row y-gap-10 items-center justify-between">
                                <div class="col-auto">
                                  <div class="d-flex items-center">
                                    <div class="form-checkbox ">
                                      <input type="checkbox" name="name">
                                      <div class="form-checkbox__mark">
                                        <div class="form-checkbox__icon icon-check">
                                        </div>
                                      </div>
                                    </div>
                                    <div class="text-14 ml-10">Netaji Subhas Chandra
                                      Airport
                                      (CCU)</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="sidebar__item scroll mt-4">
                            <h5 class="text-18 fw-500 mb-10">Arrival Airport</h5>
                            <div class="sidebar-checkbox">
                              <div class="row y-gap-10 items-center justify-between">
                                <div class="col-auto">
                                  <div class="d-flex items-center">
                                    <div class="form-checkbox ">
                                      <input type="checkbox" name="name">
                                      <div class="form-checkbox__mark">
                                        <div class="form-checkbox__icon icon-check">
                                        </div>
                                      </div>
                                    </div>
                                    <div class="text-14 ml-10">Ahmedabad Airport
                                      (AMD)</div>
                                  </div>
                                </div>
                              </div>
                              <div class="row y-gap-10 items-center justify-between">
                                <div class="col-auto">
                                  <div class="d-flex items-center">
                                    <div class="form-checkbox ">
                                      <input type="checkbox" name="name">
                                      <div class="form-checkbox__mark">
                                        <div class="form-checkbox__icon icon-check">
                                        </div>
                                      </div>
                                    </div>
                                    <div class="text-14 ml-10">Sanganeer Airport
                                      (JAI)</div>
                                  </div>
                                </div>
                              </div>
                              <div class="row y-gap-10 items-center justify-between">
                                <div class="col-auto">
                                  <div class="d-flex items-center">
                                    <div class="form-checkbox ">
                                      <input type="checkbox" name="name">
                                      <div class="form-checkbox__mark">
                                        <div class="form-checkbox__icon icon-check">
                                        </div>
                                      </div>
                                    </div>
                                    <div class="text-14 ml-10">Kempegowda
                                      International Airport
                                      (BLR)</div>
                                  </div>
                                </div>
                              </div>
                              <div class="row y-gap-10 items-center justify-between">
                                <div class="col-auto">
                                  <div class="d-flex items-center">
                                    <div class="form-checkbox ">
                                      <input type="checkbox" name="name">
                                      <div class="form-checkbox__mark">
                                        <div class="form-checkbox__icon icon-check">
                                        </div>
                                      </div>
                                    </div>
                                    <div class="text-14 ml-10">Sonegaon Airport
                                      (NAG)</div>
                                  </div>
                                </div>
                              </div>
                              <div class="row y-gap-10 items-center justify-between">
                                <div class="col-auto">
                                  <div class="d-flex items-center">
                                    <div class="form-checkbox ">
                                      <input type="checkbox" name="name">
                                      <div class="form-checkbox__mark">
                                        <div class="form-checkbox__icon icon-check">
                                        </div>
                                      </div>
                                    </div>
                                    <div class="text-14 ml-10">Chennai Airport (MAA)
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="row y-gap-10 items-center justify-between">
                                <div class="col-auto">
                                  <div class="d-flex items-center">
                                    <div class="form-checkbox ">
                                      <input type="checkbox" name="name">
                                      <div class="form-checkbox__mark">
                                        <div class="form-checkbox__icon icon-check">
                                        </div>
                                      </div>
                                    </div>
                                    <div class="text-14 ml-10">Trivandrum
                                      International Airport
                                      (TRV)</div>
                                  </div>
                                </div>
                              </div>
                              <div class="row y-gap-10 items-center justify-between">
                                <div class="col-auto">
                                  <div class="d-flex items-center">
                                    <div class="form-checkbox ">
                                      <input type="checkbox" name="name">
                                      <div class="form-checkbox__mark">
                                        <div class="form-checkbox__icon icon-check">
                                        </div>
                                      </div>
                                    </div>
                                    <div class="text-14 ml-10">Amausi Airport (LKO)
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="row y-gap-10 items-center justify-between">
                                <div class="col-auto">
                                  <div class="d-flex items-center">
                                    <div class="form-checkbox ">
                                      <input type="checkbox" name="name">
                                      <div class="form-checkbox__mark">
                                        <div class="form-checkbox__icon icon-check">
                                        </div>
                                      </div>
                                    </div>
                                    <div class="text-14 ml-10">Indore Airport (IDR)
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="row y-gap-10 items-center justify-between">
                                <div class="col-auto">
                                  <div class="d-flex items-center">
                                    <div class="form-checkbox ">
                                      <input type="checkbox" name="name">
                                      <div class="form-checkbox__mark">
                                        <div class="form-checkbox__icon icon-check">
                                        </div>
                                      </div>
                                    </div>
                                    <div class="text-14 ml-10">(HSR)</div>
                                  </div>
                                </div>
                              </div>
                              <div class="row y-gap-10 items-center justify-between">
                                <div class="col-auto">
                                  <div class="d-flex items-center">
                                    <div class="form-checkbox ">
                                      <input type="checkbox" name="name">
                                      <div class="form-checkbox__mark">
                                        <div class="form-checkbox__icon icon-check">
                                        </div>
                                      </div>
                                    </div>
                                    <div class="text-14 ml-10">Rajiv Gandhi
                                      International
                                      Airport (HYD)</div>
                                  </div>
                                </div>
                              </div>
                              <div class="row y-gap-10 items-center justify-between">
                                <div class="col-auto">
                                  <div class="d-flex items-center">
                                    <div class="form-checkbox ">
                                      <input type="checkbox" name="name">
                                      <div class="form-checkbox__mark">
                                        <div class="form-checkbox__icon icon-check">
                                        </div>
                                      </div>
                                    </div>
                                    <div class="text-14 ml-10">Dabolim Airport (GOI)
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="row y-gap-10 items-center justify-between">
                                <div class="col-auto">
                                  <div class="d-flex items-center">
                                    <div class="form-checkbox ">
                                      <input type="checkbox" name="name">
                                      <div class="form-checkbox__mark">
                                        <div class="form-checkbox__icon icon-check">
                                        </div>
                                      </div>
                                    </div>
                                    <div class="text-14 ml-10">Raja Sansi Airport
                                      (ATQ)</div>
                                  </div>
                                </div>
                              </div>
                              <div class="row y-gap-10 items-center justify-between">
                                <div class="col-auto">
                                  <div class="d-flex items-center">
                                    <div class="form-checkbox">
                                      <input type="checkbox" name="name">
                                      <div class="form-checkbox__mark">
                                        <div class="form-checkbox__icon icon-check">
                                        </div>
                                      </div>
                                    </div>
                                    <div class="text-14 ml-10">Netaji Subhas Chandra
                                      Airport
                                      (CCU)</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </aside>
        </div>

        <div class="col-xl-9 col-lg-8">
         
        


          <div class="row y-gap-10 justify-between items-center mb-4">
            <div class="col-auto">
              <div class="text-25"><span class="fw-500">Flights from {{ airport_city1 }} to {{airport_city2}}</span></div>
            </div>

            <div class="tier-list">
                        <div class="row" *ngIf="flights.length >=1">
                            <div class="col-md-4">
                                <a href="#">
                                <div class="ic-content bst active">
                                    <div class="ic">
                                    <i class="fa-solid fa-star"></i>
                                    </div>
                                    <div class="ic-mai-cnt">
                                        <strong>BEST</strong>
                                        <span>£ {{ flights[0].totalPrice }} |  {{ flights[0].journey[0].optionInfos[0].totalFlightDuration }}</span>

                                    </div>
                                </div>
                                </a>
                            </div>
                            <div class="col-md-4">
                                <a href="">
                                <div class="ic-content chp">
                                    <div class="ic">
                                    <i class="fa-solid fa-percent"></i>
                                    </div>
                                    <div class="ic-mai-cnt">
                                        <strong>CHEAPEST</strong>
                                        <span>£ {{ flights[0].totalPrice }}  |  {{ flights[0].journey[0].optionInfos[0].totalFlightDuration }}</span>

                                    </div>
                                </div>
                                </a>
                            </div>
                            <div class="col-md-4">
                                <a href="">
                                <div class="ic-content fst">
                                    <div class="ic">
                                    <i class="fa-solid fa-gauge-high"></i>
                                    </div>
                                    <div class="ic-mai-cnt">
                                        <strong>FASTEST</strong>
                                        <span>£{{totalprice_lowestDuration}} | {{lowestDuration}}</span>

                                    </div>
                                </div>
                                </a>
                            </div>
                        </div>
                    </div>


          </div>

          <!---div class="flight-listing flight_listing_weekly_frame_items">

            <div class="weeklyFareItems">
              <a>
                <p class="blackFont fontSize12 appendBottom3">Tue, Apr 11</p>
                <p>
                  <font color="">
                    ₹ 6,735</font>
                </p>
              </a>
            </div>

            <div class="weeklyFareItems">
              <a>
                <p class="blackFont fontSize12 appendBottom3">Tue, Apr 11</p>
                <p>
                  <font color="">
                    ₹ 6,735</font>
                </p>
              </a>
            </div>

            <div class="weeklyFareItems">
              <a>
                <p class="blackFont fontSize12 appendBottom3">Tue, Apr 11</p>
                <p>
                  <font color="">
                    ₹ 6,735</font>
                </p>
              </a>
            </div>

            <div class="weeklyFareItems">
              <a>
                <p class="blackFont fontSize12 appendBottom3">Tue, Apr 11</p>
                <p>
                  <font color="">
                    ₹ 6,735</font>
                </p>
              </a>
            </div>

            <div class="weeklyFareItems">
              <a>
                <p class="blackFont fontSize12 appendBottom3">Tue, Apr 11</p>
                <p>
                  <font color="">
                    ₹ 6,735</font>
                </p>
              </a>
            </div>

            <div class="weeklyFareItems">
              <a>
                <p class="blackFont fontSize12 appendBottom3">Tue, Apr 11</p>
                <p>
                  <font color="">
                    ₹ 6,735</font>
                </p>
              </a>
            </div>

            <div class="weeklyFareItems">
              <a>
                <p class="blackFont fontSize12 appendBottom3">Tue, Apr 11</p>
                <p>
                  <font color="">
                    ₹ 6,735</font>
                </p>
              </a>
            </div>

            <div class="weeklyFareItems">
              <a>
                <p class="blackFont fontSize12 appendBottom3">Tue, Apr 11</p>
                <p>
                  <font color="">
                    ₹ 6,735</font>
                </p>
              </a>
            </div>

            <div class="weeklyFareItems">
              <a>
                <p class="blackFont fontSize12 appendBottom3">Tue, Apr 11</p>
                <p>
                  <font color="">
                    ₹ 6,735</font>
                </p>
              </a>
            </div>

            <div class="weeklyFareItems">
              <a>
                <p class="blackFont fontSize12 appendBottom3">Tue, Apr 11</p>
                <p>
                  <font color="">
                    ₹ 6,735</font>
                </p>
              </a>
            </div>

          </div--->

          <!---div class=" paddingTop20 mt-10">
            <div class="sortbySection simpleow bg-white m-0">
              <div class="makeFlex sort-label">
                <span class="blackFont appendRight10">Sorted By:</span>
                <div class="sortby-dom-sctn popularity_sorter " style="display: none;">
                  <span class="pointer">
                    <span>Popularity</span>
                  </span>
                </div>
              </div>
              <div class="makeFlex sort-item">
                <div class="sortby-dom-sctn departure_sorter " style="flex: 1 1 0%; text-align: left;"><span
                    class="pointer"><span>Departure</span></span></div>
                <div class="sortby-dom-sctn duration_sorter " style="flex: 1 1 0%; text-align: center;"><span
                    class="pointer"><span>Duration</span></span></div>
                <div class="sortby-dom-sctn arrival_sorter "
                  style="flex: 1 1 0%; position: relative; text-indent: 55px;"><span
                    class="pointer"><span>Arrival</span>
                  </span>
                </div>
              </div>
              <div class="flexOne"></div>
              <div class="make-flex sort-price">
                <div class="sortby-dom-sctn price_sorter blackFont">
                  <span class="pointer">
                    <span>Price</span>
                    <span class="appendLeft10 up sort-arrow">
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div---->

          <!-- <div *ngFor="let flight of flights;let i = index" >
            <p>Total price: {{ flight.totalPrice }}</p>
            <div *ngFor="let journey of flight.journey;">
             
              <p>From: {{ journey.origin }}</p>
              <p>To :{{ journey.destination }}</p>
              <p>Stop :{{ journey.stop }}</p>
  
              <div *ngFor="let optionInfo of journey.optionInfos;">
             
                <p>Duration: {{ optionInfo.totalFlightDuration }}</p>

                <div *ngFor="let airSegmentInfo of optionInfo.airSegmentInfos;">
             
                  <p>airlineLogoUrl: {{ airSegmentInfo.airlineLogoUrl }}</p>
                </div>  


  
            </div>
        
           </div>
           </div> -->
           <div class="js-accordion" *ngIf="flights.length === 0">
            <p>No flights available.</p>
           
          </div>

          <!-- <pre> {{flights | json }} </pre> -->

          <!-- //Start new iteration -->

          <div *ngFor="let flight of flights;let i = index">
              <div *ngFor="let journey of flight.journey; let j = index;">
                

                 <div  *ngFor="let optionInfo of journey.optionInfos; let w = index;">

              

          <div *ngIf="j === 0" class="js-accordion">
            <div  class="accordion__item py-20 px-20 bg-white rounded-4 base-tr mt-10" data-x="flight-item-1"
              data-x-toggle="shadow-2">
              <div   class="row justify-between">

                

                <div class="col">

                  <div *ngIf="optionInfo.airSegmentInfos.length >= 1"  class="row y-gap-10 items-center" >
                  
                    <span class="text-muted">{{optionInfo.airSegmentInfos[0].departDate}}</span> 
                    <!-- <p>  optionKey1: {{optionInfo.optionKey}} </p>
                    <p>inboundKey1: {{journey.inboundKey}}</p>
                    <p>OutBoundKey1: {{journey.outBoundKey}}</p> -->
                
                  
                    <div class="col-sm-auto">
                      <div class="d-flex align-items-center">
                        
                        <img class="size-40 me-2" src="{{ optionInfo.airSegmentInfos[0].airlineLogoUrl }}" alt="image">
                        <div>
                          <p class="airlineName m-0">{{ optionInfo.airSegmentInfos[0].airlineName }}</p>
                          <small class="text-muted">{{  optionInfo.airSegmentInfos[0].flightNumber }}</small>
                         
                         

                        </div>
                      </div>
                    </div>

                    <div class="col">
                      <div class="row x-gap-20 items-end">
                        <div class="col-auto">
                          <div class="lh-15 fw-500">{{optionInfo.airSegmentInfos[0].departTime }}</div>
                          <div class="text-15 lh-15 text-light-1">
                            <!-- {{ journey.origin }} -->
                              {{ optionInfo.airSegmentInfos[0].origin }}
                          </div>
                        </div>

                        <div class="col text-center">
                          <div class="text-15 lh-15 text-light-1 mb-10">{{ optionInfo.totalFlightDuration }}</div>
                          <div class="flightLine">
                            <div></div>
                            <div></div>
                          </div>
                          <div class="text-15 lh-15 text-light-1 mt-10">Stop : 
                            <!-- {{ journey.stop }} -->
                              {{optionInfo.airSegmentInfos.length-1}}
                          </div>
                        </div>

                        <div class="col-auto">
                          <div class="lh-15 fw-500">{{ optionInfo.airSegmentInfos[optionInfo.airSegmentInfos.length - 1].arrivalTime }}</div>
                          <div class="text-15 lh-15 text-light-1">
                            <!-- {{ journey.destination }} -->
                            {{ optionInfo.airSegmentInfos[optionInfo.airSegmentInfos.length - 1].destination }}
                          
                          </div>
                        </div>
                      </div>
                    </div>


                  </div>

                  <!-- <div *ngFor="let airSegmentInfo of optionInfo.airSegmentInfos;" class="row y-gap-10 items-center">
                  
                    
                  
                    <div class="col-sm-auto">
                      <div class="d-flex align-items-center">
                        <img class="size-40 me-2" src="{{ airSegmentInfo.airlineLogoUrl }}" alt="image">
                        <div>
                          <p class="airlineName m-0">{{ airSegmentInfo.airlineName }}</p>
                          <small class="text-muted">{{ airSegmentInfo.flightNumber }}</small>
                        </div>
                      </div>
                    </div>

                    <div class="col">
                      <div class="row x-gap-20 items-end">
                        <div class="col-auto">
                          <div class="lh-15 fw-500">{{ airSegmentInfo.departTime }}</div>
                          <div class="text-15 lh-15 text-light-1">{{ airSegmentInfo.originAirportCity }}</div>
                        </div>

                        <div class="col text-center">
                          <div class="text-15 lh-15 text-light-1 mb-10">{{ airSegmentInfo.travelDuration }}</div>
                          <div class="flightLine">
                            <div></div>
                            <div></div>
                          </div>
                          <div class="text-15 lh-15 text-light-1 mt-10">Stop : {{ journey.stop }}</div>
                        </div>

                        <div class="col-auto">
                          <div class="lh-15 fw-500">{{ airSegmentInfo.arrivalTime }}</div>
                          <div class="text-15 lh-15 text-light-1">{{ airSegmentInfo.destinationAirportCity }}</div>
                        </div>
                      </div>
                    </div>


                  </div> -->

              <div *ngFor="let journey of flight.journey; let k = index;">

                
                <div  *ngIf="k === 1">
 
                    <div  *ngFor="let optionInfo of journey.optionInfos; let l = index;">
                      
                    <div  *ngIf="l === 0">
                      <div *ngIf="optionInfo.airSegmentInfos.length >= 1"  class="row y-gap-10 items-center" >
                  
                   
                    <span class="text-muted">{{optionInfo.airSegmentInfos[0].departDate}}</span>
                    <!-- <p>  optionKey2: {{optionInfo.optionKey}} </p>
                    <p>inboundKey2: {{journey.inboundKey}}</p>
                  <p>OutBoundKey2: {{journey.outBoundKey}}</p> -->
                        <div class="col-sm-auto">
                          <div class="d-flex align-items-center">
                           
                            <img class="size-40 me-2" src="{{ optionInfo.airSegmentInfos[0].airlineLogoUrl }}" alt="image">
                            <div>
                              <p class="airlineName m-0">{{ optionInfo.airSegmentInfos[0].airlineName }}</p>
                              <small class="text-muted">{{  optionInfo.airSegmentInfos[0].flightNumber }}</small>
                              
                              
                            </div>
                          </div>
                        </div>
    
                        <div class="col">
                          <div class="row x-gap-20 items-end">
                            <div class="col-auto">
                              <div class="lh-15 fw-500">{{optionInfo.airSegmentInfos[0].departTime }}</div>
                              <div class="text-15 lh-15 text-light-1">
                                <!-- {{ journey.origin }} -->
                                {{ optionInfo.airSegmentInfos[0].origin }}
                              
                              </div>
                            </div>
    
                            <div class="col text-center">
                              <div class="text-15 lh-15 text-light-1 mb-10">{{ optionInfo.totalFlightDuration }}</div>
                              <div class="flightLine">
                                <div></div>
                                <div></div>
                              </div>
                              <div class="text-15 lh-15 text-light-1 mt-10">Stop :
                                 <!-- {{ journey.stop }} -->
                                 {{optionInfo.airSegmentInfos.length-1}}
                                
                                </div>
                            </div>
    
                            <div class="col-auto">
                              <div class="lh-15 fw-500">{{ optionInfo.airSegmentInfos[optionInfo.airSegmentInfos.length - 1].arrivalTime }}</div>
                              <div class="text-15 lh-15 text-light-1">
                                
                                <!-- {{ journey.destination }} -->
                                {{ optionInfo.airSegmentInfos[optionInfo.airSegmentInfos.length - 1].destination }}
                              </div>
                            </div>
                          </div>
                        </div>
    
    
                      </div>
                     </div>

                      <!-- <div *ngFor="let airSegmentInfo of optionInfo.airSegmentInfos;" class="row y-gap-10 items-center">
                  
                    
                  
                        <div class="col-sm-auto">
                          <div class="d-flex align-items-center">
                            <img class="size-40 me-2" src="{{ airSegmentInfo.airlineLogoUrl }}" alt="image">
                            <div>
                              <p class="airlineName m-0">{{ airSegmentInfo.airlineName }}</p>
                              <small class="text-muted">{{ airSegmentInfo.flightNumber }}</small>
                            </div>
                          </div>
                        </div>
    
                        <div class="col">
                          <div class="row x-gap-20 items-end">
                            <div class="col-auto">
                              <div class="lh-15 fw-500">{{ airSegmentInfo.departTime }}</div>
                              <div class="text-15 lh-15 text-light-1">{{ airSegmentInfo.originAirportCity }}</div>
                            </div>
    
                            <div class="col text-center">
                              <div class="text-15 lh-15 text-light-1 mb-10">{{ airSegmentInfo.travelDuration }}</div>
                              <div class="flightLine">
                                <div></div>
                                <div></div>
                              </div>
                              <div class="text-15 lh-15 text-light-1 mt-10">Stop : {{ journey.stop }}</div>
                            </div>
    
                            <div class="col-auto">
                              <div class="lh-15 fw-500">{{ airSegmentInfo.arrivalTime }}</div>
                              <div class="text-15 lh-15 text-light-1">{{ airSegmentInfo.destinationAirportCity }}</div>
                            </div>
                          </div>
                        </div>
    
    
                      </div> -->

                     </div>

                </div>

              </div>  


                </div>

                <div class="col-md-auto">
                  <div class="d-flex items-center h-full">
                    <div class="pl-30 border-left-light h-full md:d-none"></div>

                    <div class="text-center">
                      <div class="mb-10">
                        <div class="text-18 lh-16 fw-600">GBP  {{ flight.totalPrice }}</div>
                      </div>

                      <!-- <div class="btn btn-read-more w-100 d-flex align-items-center" style="display: none;">
                        <button class="shadow-none button -dark-1 px-30 h-50 bg-blue-1 text-white collapsed"
                          data-bs-toggle="collapse" data-bs-target="#view-price" aria-expanded="false"
                          aria-controls="view-price" style="display: none;" >
                          View Prices <i class="fa-solid fa-chevron-down ms-2"></i>
                        </button> 
  
                      </div> -->
                      
                      <div class="w-100 d-flex align-items-center">

                      <!-- <p>  BoptionKey1: {{optionInfo.optionKey}} </p>
                        
                      <p>BOutBoundKey1: {{journey.outBoundKey}}</p>

                      <p>  optionKey2: {{flight.journey[1]?.optionInfos[0].optionKey}} </p>
                      
                      <p>Last inboundKey used: {{ flight.journey[1]?.inboundKey }}</p> -->


                        <a class="booknowbtn" href="/flight-details?Key={{flight.key}}&optionKey1={{optionInfo.optionKey}}&optionKey2={{flight.journey[1]?.optionInfos[0].optionKey}}&outBoundKey={{journey.outBoundKey}}&inboundKey={{ flight.journey[1]?.inboundKey }}&OptionKeyList={{optionInfo.optionKey}}&supp={{flight.supp}}&journeyType={{journeyType}}&f_adult={{f_adult}}&f_infant={{f_infant}}&f_child={{f_child}}&f_youth={{f_youth}}&token={{flight.token}}&f_class={{flight.token}}&flight_from={{flightFrom}}&flight_to={{flightTo}}&airport_name1={{airport_city1}}&airport_name2={{airport_city2}}&f_departureDate={{f_departureDate}}&f_returnDate={{f_returnDate}}&f_image={{f_returnDate}}&totalPrice={{flight.totalPrice}} " >
                        <button _ngcontent-ng-c590573831="" id="bookbutton-RKEY:f66a144c-43b4-4900-a80f-db2df8e645f6:51_0" fdprocessedid="o2ush" class="button corp-btn latoBlack buttonPrimary fontSize13">Book Now</button>
                      </a>
                      <div class="loader" id="loader"></div>

                      </div>
                    </div>

                  </div>
                </div>
              </div>

              <!-- <small class="alert alert-warning text-center p-1 d-block mt-3">Get Rs 300 off using MMTSUPER*</small>
              <div class="collapsed text-end" data-bs-toggle="collapse" [attr.data-bs-target]="'#flight-detail_' + i" [attr.aria-controls]="'flight-detail_' + i" data-bs-target1="#flight-detail" aria-expanded="false" aria-controls1="flight-detail">
                <a href="#flight-detail_{{ i }}"  >View Flight Details</a>
              </div> -->


              <!-- <div class="accordion__content collapse mh-100" id="flight-detail_{{ i }}" >

                <div *ngFor="let optionInfo of journey.optionInfos;" class="flightDetailsOuter">
                  <nav class="nav nav-tabs" id="pills-tab" role="tablist">
                    <a href="#" class="nav-link active" id="pills-tab1-tab" data-bs-toggle="pill"
                      d [attr.data-bs-target]="'#pills-tab1_'+i" type="button" role="tab" aria-controls="pills-tab1"
                      aria-selected="true">FLIGHT DETAILS</a>
                    <a href="#" class="nav-link" id="pills-tab2-tab" data-bs-toggle="pill" [attr.data-bs-target]="'#pills-tab2_'+i"
                      type="button" role="tab" aria-controls="pills-tab2" aria-selected="false">FARE SUMMARY</a>
                    <a href="#" class="nav-link" id="pills-tab3-tab" data-bs-toggle="pill"  [attr.data-bs-target]="'#pills-tab3_'+i"
                      type="button" role="tab" aria-controls="pills-tab3" aria-selected="false">CANCELLATION</a>
                    <a href="#" class="nav-link" id="pills-tab4-tab" data-bs-toggle="pill"  [attr.data-bs-target]="'#pills-tab4_'+i"
                      type="button" role="tab" aria-controls="pills-tab4" aria-selected="false">DATE CHANGE</a>
                  </nav>


                  <div  class="tab-content" id="pills-tabContent">
                    <div class="tab-pane fade show active" id="pills-tab1_{{i}}" role="tabpanel"
                      aria-labelledby="pills-tab1-tab" tabindex="0">
                      <div *ngFor="let airSegmentInfo of optionInfo.airSegmentInfos;" class="flightDetails">
                        <p class="flightDetailsHead">{{ airSegmentInfo.originAirportCity }} to {{airSegmentInfo.destinationAirportCity}} , {{airSegmentInfo.departDate}}</p>
                        <div class="flightDetailsInfo">
                          <div class="flightDetailsRow">
                            <p class="makeFlex hrtlCenter appendBottom20 gap-x-10"><span class="icon32 bgProperties"
                                style="background-image: url(&quot;{{airSegmentInfo.airlineLogoUrl}}&quot;);"></span><span>
                                <font color="#000000"><b>{{airSegmentInfo.airlineName}} </b></font>
                                <font color="#6d7278">{{airSegmentInfo.carrier}} | {{airSegmentInfo.flightNumber}}</font>
                              </span></p>
                            <div class="makeFlex flightDtlInfo">
                              <div class="airlineInfo gap-x-10">
                                <div class="airlineDTInfoCol">
                                  <p class="fontSize18 blackText blackFont">{{airSegmentInfo.departTime}}</p>
                                  <p class="fontSize12 blackText boldFont appendBottom8">{{airSegmentInfo.departDate}}</p>
                                  <font color="#4a4a4a">Terminal  {{airSegmentInfo.airport[0].originTerminal }}</font>
                                  <p class="fontSize12">{{airSegmentInfo.originAirportCity}}, {{airSegmentInfo.originAirportCountry}}</p>
                                </div>
                                <div class="airlineDtlDuration fontSize12">{{airSegmentInfo.travelDuration}}
                                  <div class="relative fliStopsSep">
                                    <p class="fliStopsSepLine" style="border-top: 3px solid rgb(81, 226, 194);"></p>
                                  </div>
                                </div>
                                <div class="airlineDTInfoCol">
                                  <p class="fontSize18 blackText blackFont">{{airSegmentInfo.arrivalTime}}</p>
                                  <p class="fontSize12 blackText boldFont appendBottom8">{{airSegmentInfo.arrivalDate}}</p>
                                  <font color="#4a4a4a">Terminal  {{airSegmentInfo.airport[1].destinationTerminal }}</font>
                                  <p class="fontSize12">{{airSegmentInfo.destinationAirportCity}}, {{airSegmentInfo.destinationAirportCountry}}</p>
                                </div>
                              </div>
                              <div class="baggageInfo">
                                <p class="makeFlex spaceBetween appendBottom3"><span
                                    class="baggageInfoText blackFont fontSize14">BAGGAGE : </span><span
                                    class="baggageInfoText blackFont fontSize14">CHECK IN</span><span
                                    class="baggageInfoText blackFont fontSize14">CABIN</span></p>
                                <p class="makeFlex spaceBetween appendBottom3"><span
                                    class="baggageInfoText darkText">ADULT</span><span
                                    class="baggageInfoText darkText">{{ airSegmentInfo.baggageInfo.allowance }}</span><span
                                    class="baggageInfoText darkText"></span></p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="tab-pane fade" id="pills-tab2_{{i}}" role="tabpanel" aria-labelledby="pills-tab2-tab"
                      tabindex="0">
                      <div  class="flightDetails">
                        <p class="flightDetailsHead">Fare breakup</p>
                        <div class="flightDetailsInfo">
                          <p class="appendBottom8 fontSize12"><span class="fareBreakupText">
                              <font style="font-size: 14px; color: rgb(0, 0, 0);">TOTAL</font>
                            </span><span>
                              <font style="font-size: 14px; color: rgb(0, 0, 0);">GBP {{ flight.totalPrice }}</font>
                            </span></p>
                          <p class="appendBottom8 fontSize12"><span class="fareBreakupText">
                              <font style="color: rgb(135, 135, 135);">Base Fare</font>
                            </span><span>
                              <font style="color: rgb(135, 135, 135);">GBP {{ flight.basePrice }}</font>
                            </span></p>
                          <p class="appendBottom8 fontSize12"><span class="fareBreakupText">
                              <font style="color: rgb(135, 135, 135);">Surcharges</font>
                            </span><span>
                              <font style="color: rgb(135, 135, 135);">GBP {{ flight.tax }}</font>
                            </span></p>
                        </div>
                      </div>
                    </div>
                    <div class="tab-pane fade" id="pills-tab3_{{i}}" role="tabpanel" aria-labelledby="pills-tab3-tab"
                      tabindex="0">
                      <div *ngFor="let airSegmentInfo of optionInfo.airSegmentInfos;">
                        <div class="accordion">
                          <div class="card">
                            <div class="card-header">
                              <div class="makeFlex spaceBetween">
                                <span class="makeFlex perfectCenter">
                                  <span class="icon30 bgProperties appendRight10 boldFont"
                                    style="background-image: url(&quot;{{airSegmentInfo.airlineLogoUrl}}&quot;);"></span>
                                    {{ airSegmentInfo.originAirportCity }} - {{airSegmentInfo.destinationAirportCity}}
                                </span>
                              </div>
                            </div>
                            <div class="collapse show">
                              <div class="card-body">
                                <div class="flightDetails darkText fontSize12 appendBottom12">
                                  <div class="DateChangeInfo">
                                    <div class="flightDetailsInfoLeft">
                                      <p class="fontSize14 boldFont blackText">Time frame</p>
                                      <p>(From Scheduled flight departure)</p>
                                    </div>
                                    <div class="flightDetailsInfoLeft">
                                      <p class="fontSize14 boldFont blackText">Airline Fee + MMT Fee</p>
                                      <p>(Per passenger)</p>
                                    </div>
                                  </div>
                                  <div class="DateChangeInfo">
                                    <div class="flightDetailsInfoLeft">
                                      <p class="appendBottom3">0 hours to 2 hours*</p>
                                    </div>
                                    <div class="flightDetailsInfoRight">
                                      <p>ADULT : <b>Non Refundable</b><br></p>
                                    </div>
                                  </div>
                                  <div class="DateChangeInfo">
                                    <div class="flightDetailsInfoLeft">
                                      <p class="appendBottom3">Change Penalty *</p>
                                    </div>
                                    <div class="flightDetailsInfoRight">
                                      <p>ADULT : <b> {{ flight.cancelPenalty }}</b><br></p>
                                    </div>
                                  </div>
                                  <div class="DateChangeInfo">
                                    <div class="flightDetailsInfoLeft">
                                      <p class="appendBottom3">3 days to 365 days*</p>
                                    </div>
                                    <div class="flightDetailsInfoRight">
                                      <p>ADULT : <b>{{ flight.cancelPenalty }}</b><br></p>
                                    </div>
                                  </div>
                                </div>
                                <p class="darkText fontSize12 appendBottom12 appendLeft10 appendTop10">*From the Date of
                                  Departure</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="infoStrip appendTop15 appendBottom15"><b>*Important:</b> The airline fee is
                          indicative. Gia Trave; does not guarantee the accuracy of this information. All fees mentioned
                          are per passenger. </div>
                      </div>
                    </div>
                    <div class="tab-pane fade" id="pills-tab4_{{i}}" role="tabpanel" aria-labelledby="pills-tab4-tab"
                      tabindex="0">
                      <div>
                        <div *ngFor="let airSegmentInfo of optionInfo.airSegmentInfos;" class="accordion">
                          <div class="card">
                            <div class="card-header">
                              <div class="makeFlex spaceBetween">
                                <span class="makeFlex perfectCenter">
                                  <span class="icon30 bgProperties appendRight10 boldFont"
                                    style="background-image: url(&quot;{{ airSegmentInfo.airlineLogoUrl }}&quot;);">
                                  </span>
                                  {{ airSegmentInfo.originAirportCity }} - {{ airSegmentInfo.destinationAirportCity }}
                                </span>
                              </div>
                            </div>
                            <div class="collapse show">
                              <div class="card-body">
                                <div class="flightDetails darkText fontSize12 appendBottom12">
                                  <div class="DateChangeInfo">
                                    <div class="flightDetailsInfoLeft">
                                      <p class="fontSize14 boldFont blackText">Time frame</p>
                                      <p>(From Scheduled flight departure)</p>
                                    </div>
                                    <div class="flightDetailsInfoLeft">
                                      <p class="fontSize14 boldFont blackText">{{ airSegmentInfo.airlineName }} Fee + MMT Fee + Fare difference
                                      </p>
                                      <p>(Per passenger)</p>
                                    </div>
                                  </div>
                                  <div class="DateChangeInfo">
                                    <div class="flightDetailsInfoLeft">
                                      <p class="appendBottom3">0 hours to 2 hours*</p>
                                    </div>
                                    <div class="flightDetailsInfoRight">
                                      <p>ADULT : <b>{{ flight.changePenalty }}</b><br></p>
                                    </div>
                                  </div>
                                  <div class="DateChangeInfo">
                                    <div class="flightDetailsInfoLeft">
                                      <p class="appendBottom3">2 hours to 3 days*</p>
                                    </div>
                                    <div class="flightDetailsInfoRight">
                                      <p>ADULT : <b>{{ flight.changePenalty }}</b><br></p>
                                    </div>
                                  </div>
                                  <div class="DateChangeInfo">
                                    <div class="flightDetailsInfoLeft">
                                      <p class="appendBottom3">3 days to 365 days*</p>
                                    </div>
                                    <div class="flightDetailsInfoRight">
                                      <p>ADULT : <b>{{ flight.changePenalty }}</b><br></p>
                                    </div>
                                  </div>
                                </div>
                                <p class="darkText fontSize12 appendBottom12 appendLeft10 appendTop10">*From the Date of
                                  Departure</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="infoStrip appendTop15 appendBottom15"><b>*Important:</b> The airline fee is
                          indicative. Gia Travel does not guarantee the accuracy of this information. All fees mentioned
                          are per passenger. Date change charges are applicable only on selecting the same airline on a
                          new date. The difference in fares between the old and the new booking will also be payable by
                          the user.<br>Please refer to the Date Change Charges section above for details on the number
                          of allowed free date changes, if applicable<br></div>
                      </div>
                    </div>
                  </div>


                </div>

              </div> -->
              <div class="flightDetailsOuter">
                <div class="d-flex flex-wrap align-items-center">


                <div class="col-md-7">
                <nav class="nav nav-tabs" id="pills-tab" role="tablist">
                  <a href="#" class="nav-link" id="pills-tab1-tab" data-bs-toggle="pill" [attr.data-bs-target]="'#pills-tab1_'+i+j+w"
                    type="button" role="tab" aria-controls="pills-tab1" aria-selected="true"><i
                      class="fa-solid fa-plane-up" aria-hidden="true"></i> ITINERARY</a>
                  <a href="#" class="nav-link" id="pills-tab2-tab" data-bs-toggle="pill" [attr.data-bs-target]="'#pills-tab2_'+i+j+w"
                    type="button" role="tab" aria-controls="pills-tab2" aria-selected="false"><i
                      class="fa-solid fa-star-of-life" aria-hidden="true"></i> FARE BREAKUP</a>
                  <!--a href="#" class="nav-link" id="pills-tab3-tab" data-bs-toggle="pill" data-bs-target="#pills-tab3"
                      type="button" role="tab" aria-controls="pills-tab3" aria-selected="false">CANCELLATION</a>
                    <a href="#" class="nav-link" id="pills-tab4-tab" data-bs-toggle="pill" data-bs-target="#pills-tab4"
                      type="button" role="tab" aria-controls="pills-tab4" aria-selected="false">DATE CHANGE</a-->
                </nav> 
                </div>
                <div class="col-md-5">
                <ul  *ngIf="optionInfo.airSegmentInfos.length >= 1" class="fl-info-area justify-content-end bg-transparent border-0">
                            <li class="py-1"><span><i class="fa-solid fa-suitcase" aria-hidden="true"></i> Baggage Allowance:</span>
                              {{ optionInfo.airSegmentInfos[0].baggageInfo.allowance }}</li>
                            <li  class="py-1 border-0"><svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
                                <path fill="#2295a8"
                                  d="M7 18S4 10 4 6s2-4 2-4h1s1 0 1 1s-1 1-1 3s3 4 3 7s-3 5-3 5m5-1c-1 0-4 2.5-4 2.5c-.3.2-.2.5 0 .8c0 0 1 1.8 3 1.8h6c1.1 0 2-.9 2-2v-1c0-1.1-.9-2-2-2h-5Z">
                                </path>
                              </svg> <span>ClassI</span> {{ optionInfo.airSegmentInfos[0].cabinClass}}</li>
                          </ul>
                </div>
                        </div>

                <div class="tab-content " id="pills-tabContent">
                  <div class="tab-pane fade mt-4" id="pills-tab1_{{i}}{{j}}{{w}}" role="tabpanel" aria-labelledby="pills-tab1-tab"
                    tabindex="0">
                    
                    <div *ngIf="optionInfo.airSegmentInfos.length >= 1" class="flightDetails">
                      <div class="d-flex justify-content-between">
                        <div class="col-md-6">
                          <p class="flightDetailsHead">Departure: <span>{{ optionInfo.airSegmentInfos[0].originAirportCity }} to {{optionInfo.airSegmentInfos[optionInfo.airSegmentInfos.length -1].destinationAirportCity}}</span></p>
                         </div>
                        <div class="col-md-6 text-right">
                          <p class="flightDetailsHead">Total Flight Duration: <span>{{optionInfo.airSegmentInfos[0].travelDuration}}</span></p>
                        </div>
                      </div>
                      <div *ngFor="let airSegmentInfo of optionInfo.airSegmentInfos; let p = index;" class="flightDetailsInfo">
                        <div class="flightDetailsRow d-flex flex-wrap align-items-center">
                          <div class="col-md-3">
                            <p class="makeFlex hrtlCenter appendBottom20 gap-x-10"><span class="icon32 bgProperties"
                                style="background-image: url(&quot;{{airSegmentInfo.airlineLogoUrl}}&quot;);"></span><span>
                                <font color="#000000"><b>{{airSegmentInfo.airlineName}} </b></font>
                                <font color="#6d7278">{{airSegmentInfo.carrier}} | {{airSegmentInfo.flightNumber}}</font>

                              </span></p>
                          </div>
                          <div class="col-md-9">
                            <div class="d-flex flex-wrap align-items-center">
                              <div class="col-md-4 text-center">
                                <div class="airlineDTInfoCol">
                                  <p class="fontSize18 blackText blackFont">{{airSegmentInfo.departTime}}</p>
                                  <p class="fontSize12 blackText boldFont appendBottom8">{{airSegmentInfo.departDate}}</p>
                                  <font color="#4a4a4a">Terminal  {{airSegmentInfo.airport[0].originTerminal }}</font>
                                  <p class="fontSize12">{{airSegmentInfo.originAirportCity}}, {{airSegmentInfo.originAirportCountry}}</p>

                                </div>
                              </div>
                              <div class="col-md-4 text-center">
                                <div class="airlineDtlDuration fontSize14">{{airSegmentInfo.travelDuration}}
                                  <!-- <font color="#757575"> h </font>50<font color="#757575"> m </font> -->
                                  <!-- <div class="relative fliStopsSep">
                                      <p class="fliStopsSepLine" style="border-top: 3px solid rgb(81, 226, 194);"></p>
                                    </div> -->
                                  <div class="flightLine">
                                    <div></div>
                                    <div></div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-md-4 text-center">
                                <div class="airlineDTInfoCol">
                                   
                                  <p class="fontSize18 blackText blackFont">{{airSegmentInfo.arrivalTime}}</p>
                                  <p class="fontSize12 blackText boldFont appendBottom8">{{airSegmentInfo.arrivalDate}}</p>
                                  <font color="#4a4a4a">Terminal  {{airSegmentInfo.airport[1].destinationTerminal }}</font>
                                  <p class="fontSize12">{{airSegmentInfo.destinationAirportCity}}, {{airSegmentInfo.destinationAirportCountry}}</p>

                                </div>
                              </div>
                            </div>
                            
                          </div>
                          <ul class="fl-info-area mt-2">
                            <li><span><i class="fa-solid fa-suitcase" aria-hidden="true"></i> Baggage Allowance:</span>
                               {{ airSegmentInfo.baggageInfo.allowance }} {{airSegmentInfo.class}} </li>
                            <li><svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
                                <path fill="#2295a8"
                                  d="M7 18S4 10 4 6s2-4 2-4h1s1 0 1 1s-1 1-1 3s3 4 3 7s-3 5-3 5m5-1c-1 0-4 2.5-4 2.5c-.3.2-.2.5 0 .8c0 0 1 1.8 3 1.8h6c1.1 0 2-.9 2-2v-1c0-1.1-.9-2-2-2h-5Z">
                                </path>
                              </svg> <span>ClassI</span> {{airSegmentInfo.cabinClass}} </li>
                          </ul>
                        </div>
                        <div *ngIf="optionInfo.airSegmentInfos.length > p+1 " class="con-time-bar">
                          <span>Connection time:    {{ optionInfo.airSegmentInfos[p+1].connectionTime }} </span>
                        </div>
                      </div>
                      <!-- <div class="flightDetailsInfo fdi-l-2">
                        <div class="con-time-bar">
                          <span>Connection time: 11 hr 45 min</span>
                        </div>
                        
                      </div> -->
                    </div>

                    <!-- Start Return Flight Itenary -->

                    <div *ngFor="let journey of flight.journey; let k = index;" class="flightDetails">
     
                      <div  *ngIf="k === 1">
                    
                          <div  *ngFor="let optionInfo of journey.optionInfos; let l = index;">
                            
                          <div  *ngIf="l === 0">
                    
                    
                                        <div *ngIf="optionInfo.airSegmentInfos.length >= 1">
                                          <div class="d-flex justify-content-between">
                                            <div class="col-md-6">
                                              <p class="flightDetailsHead">Departure: <span>{{ optionInfo.airSegmentInfos[0].originAirportCity }} to {{optionInfo.airSegmentInfos[optionInfo.airSegmentInfos.length -1].destinationAirportCity}}</span></p>
                                             </div>
                                            <div class="col-md-6 text-right">
                                              <p class="flightDetailsHead">Total Flight Duration: <span>{{optionInfo.airSegmentInfos[0].travelDuration}}</span></p>
                                            </div>
                                          </div>
                                          <div *ngFor="let airSegmentInfo of optionInfo.airSegmentInfos; let p = index;" class="flightDetailsInfo">
                                            <div class="flightDetailsRow d-flex flex-wrap align-items-center">
                                              <div class="col-md-3">
                                                <p class="makeFlex hrtlCenter appendBottom20 gap-x-10"><span class="icon32 bgProperties"
                                                    style="background-image: url(&quot;{{airSegmentInfo.airlineLogoUrl}}&quot;);"></span><span>
                                                    <font color="#000000"><b>{{airSegmentInfo.airlineName}} </b></font>
                                                    <font color="#6d7278">{{airSegmentInfo.carrier}} | {{airSegmentInfo.flightNumber}}</font>
                    
                                                  </span></p>
                                              </div>
                                              <div class="col-md-9">
                                                <div class="d-flex flex-wrap align-items-center">
                                                  <div class="col-md-4 text-center">
                                                    <div class="airlineDTInfoCol">
                                                      <p class="fontSize18 blackText blackFont">{{airSegmentInfo.departTime}}</p>
                                                      <p class="fontSize12 blackText boldFont appendBottom8">{{airSegmentInfo.departDate}}</p>
                                                      <font color="#4a4a4a">Terminal  {{airSegmentInfo.airport[0].originTerminal }}</font>
                                                      <p class="fontSize12">{{airSegmentInfo.originAirportCity}}, {{airSegmentInfo.originAirportCountry}}</p>
                    
                                                    </div>
                                                  </div>
                                                  <div class="col-md-4 text-center">
                                                    <div class="airlineDtlDuration fontSize14">{{airSegmentInfo.travelDuration}}
                                                      <!-- <font color="#757575"> h </font>50<font color="#757575"> m </font> -->
                                                      <!-- <div class="relative fliStopsSep">
                                                          <p class="fliStopsSepLine" style="border-top: 3px solid rgb(81, 226, 194);"></p>
                                                        </div> -->
                                                      <div class="flightLine">
                                                        <div></div>
                                                        <div></div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div class="col-md-4 text-center">
                                                    <div class="airlineDTInfoCol">
                                                       
                                                      <p class="fontSize18 blackText blackFont">{{airSegmentInfo.arrivalTime}}</p>
                                                      <p class="fontSize12 blackText boldFont appendBottom8">{{airSegmentInfo.arrivalDate}}</p>
                                                      <font color="#4a4a4a">Terminal  {{airSegmentInfo.airport[1].destinationTerminal }}</font>
                                                      <p class="fontSize12">{{airSegmentInfo.destinationAirportCity}}, {{airSegmentInfo.destinationAirportCountry}}</p>
                    
                                                    </div>
                                                  </div>
                                                </div>
                                                
                                              </div>
                                              <ul class="fl-info-area mt-2">
                                                <li><span><i class="fa-solid fa-suitcase" aria-hidden="true"></i> Baggage Allowance:</span>
                                                   {{ airSegmentInfo.baggageInfo.allowance }} {{airSegmentInfo.class}} </li>
                                                <li><svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
                                                    <path fill="#2295a8"
                                                      d="M7 18S4 10 4 6s2-4 2-4h1s1 0 1 1s-1 1-1 3s3 4 3 7s-3 5-3 5m5-1c-1 0-4 2.5-4 2.5c-.3.2-.2.5 0 .8c0 0 1 1.8 3 1.8h6c1.1 0 2-.9 2-2v-1c0-1.1-.9-2-2-2h-5Z">
                                                    </path>
                                                  </svg> <span>ClassI</span> {{airSegmentInfo.cabinClass}} </li>
                                              </ul>
                                            </div>
                                            <div *ngIf="optionInfo.airSegmentInfos.length > p+1 " class="con-time-bar">
                                              <span>Connection time:    {{ optionInfo.airSegmentInfos[p+1].connectionTime }} </span>
                                            </div>
                                          </div>
                                          
                                        </div>
                          </div>
                         </div> 
                      </div>
                    </div>                          
                    
                    
                                    
                    
                                        

                    <!-- End Return Flight Itenary -->
                  </div>
                  <div class="tab-pane fade mt-4" id="pills-tab2_{{i}}{{j}}{{w}}" role="tabpanel" aria-labelledby="pills-tab2-tab"
                    tabindex="0">
                    <div class="flightDetails">
                      <p class="flightDetailsHead">Fare breakup</p>

                      <div class="fare-break-table table-responsive-sm">
                        <table class="table table-striped">
                          <thead>
                            <tr>
                              <th class="center">Passengers</th>
                              <th>Base Fare</th>
                              <th>Taxes</th>
                              <th class="right">Total</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td class="center">1 ( 1x Adult ) </td>
                              <td class="left strong">£ {{ flight.basePrice }}</td>
                              <td class="left">£ {{ flight.tax }}</td>
                              <td class="right">£  {{ flight.totalPrice }}</td>
                            </tr>
                            <tr>
                              <td class="center"></td>
                              <td class="left strong"></td>
                              <td class="left">
                                <strong>Subtotal</strong>
                              </td>
                              <td class="right">£  {{ flight.totalPrice }}</td>
                            </tr>

                          </tbody>
                        </table>
                      </div>
                     


                      <!-- <div class="flightDetailsInfo">
                        <p class="appendBottom8 fontSize12"><span class="fareBreakupText">
                            <font style="font-size: 14px; color: rgb(0, 0, 0);">TOTAL</font>
                          </span><span>
                            <font style="font-size: 14px; color: rgb(0, 0, 0);">GBP 5,234</font>
                          </span></p>
                        <p class="appendBottom8 fontSize12"><span class="fareBreakupText">
                            <font style="color: rgb(135, 135, 135);">Base Fare</font>
                          </span><span>
                            <font style="color: rgb(135, 135, 135);">GBP 4,541</font>
                          </span></p>
                        <p class="appendBottom8 fontSize12"><span class="fareBreakupText">
                            <font style="color: rgb(135, 135, 135);">Surcharges</font>
                          </span><span>
                            <font style="color: rgb(135, 135, 135);">GBP 693</font>
                          </span></p>
                      </div> -->
                    </div>
                  </div>
                  <div class="tab-pane fade" id="pills-tab3_{{i}}{{j}}{{w}}" role="tabpanel" aria-labelledby="pills-tab3-tab"
                      tabindex="0">
                      <div *ngFor="let airSegmentInfo of optionInfo.airSegmentInfos;">
                        <div class="accordion">
                          <div class="card">
                            <div class="card-header">
                              <div class="makeFlex spaceBetween">
                                <span class="makeFlex perfectCenter">
                                  <span class="icon30 bgProperties appendRight10 boldFont"
                                    style="background-image: url(&quot;{{airSegmentInfo.airlineLogoUrl}}&quot;);"></span>
                                    {{ airSegmentInfo.originAirportCity }} - {{airSegmentInfo.destinationAirportCity}}
                                </span>
                              </div>
                            </div>
                            <div class="collapse show">
                              <div class="card-body">
                                <div class="flightDetails darkText fontSize12 appendBottom12">
                                  <div class="DateChangeInfo">
                                    <div class="flightDetailsInfoLeft">
                                      <p class="fontSize14 boldFont blackText">Time frame</p>
                                      <p>(From Scheduled flight departure)</p>
                                    </div>
                                    <div class="flightDetailsInfoLeft">
                                      <p class="fontSize14 boldFont blackText">Airline Fee + MMT Fee</p>
                                      <p>(Per passenger)</p>
                                    </div>
                                  </div>
                                  <div class="DateChangeInfo">
                                    <div class="flightDetailsInfoLeft">
                                      <p class="appendBottom3">0 hours to 2 hours*</p>
                                    </div>
                                    <div class="flightDetailsInfoRight">
                                      <p>ADULT : <b>Non Refundable</b><br></p>
                                    </div>
                                  </div>
                                  <div class="DateChangeInfo">
                                    <div class="flightDetailsInfoLeft">
                                      <p class="appendBottom3">Change Penalty *</p>
                                    </div>
                                    <div class="flightDetailsInfoRight">
                                      <p>ADULT : <b> {{ flight.cancelPenalty }}</b><br></p>
                                    </div>
                                  </div>
                                  <div class="DateChangeInfo">
                                    <div class="flightDetailsInfoLeft">
                                      <p class="appendBottom3">3 days to 365 days*</p>
                                    </div>
                                    <div class="flightDetailsInfoRight">
                                      <p>ADULT : <b>{{ flight.cancelPenalty }}</b><br></p>
                                    </div>
                                  </div>
                                </div>
                                <p class="darkText fontSize12 appendBottom12 appendLeft10 appendTop10">*From the Date of
                                  Departure</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="infoStrip appendTop15 appendBottom15"><b>*Important:</b> The airline fee is
                          indicative. Gia Trave; does not guarantee the accuracy of this information. All fees mentioned
                          are per passenger. </div>
                      </div>
                    </div>
                    <div class="tab-pane fade" id="pills-tab4_{{i}}{{j}}{{w}}" role="tabpanel" aria-labelledby="pills-tab4-tab"
                      tabindex="0">
                      <div>
                        <div *ngFor="let airSegmentInfo of optionInfo.airSegmentInfos;" class="accordion">
                          <div class="card">
                            <div class="card-header">
                              <div class="makeFlex spaceBetween">
                                <span class="makeFlex perfectCenter">
                                  <span class="icon30 bgProperties appendRight10 boldFont"
                                    style="background-image: url({{ airSegmentInfo.airlineLogoUrl }})">
                                  </span>
                                  {{ airSegmentInfo.originAirportCity }} - {{ airSegmentInfo.destinationAirportCity }}
                                </span>
                              </div>
                            </div>
                            <div class="collapse show">
                              <div class="card-body">
                                <div class="flightDetails darkText fontSize12 appendBottom12">
                                  <div class="DateChangeInfo">
                                    <div class="flightDetailsInfoLeft">
                                      <p class="fontSize14 boldFont blackText">Time frame</p>
                                      <p>(From Scheduled flight departure)</p>
                                    </div>
                                    <div class="flightDetailsInfoLeft">
                                      <p class="fontSize14 boldFont blackText">{{ airSegmentInfo.airlineName }} Fee + MMT Fee + Fare difference
                                      </p>
                                      <p>(Per passenger)</p>
                                    </div>
                                  </div>
                                  <div class="DateChangeInfo">
                                    <div class="flightDetailsInfoLeft">
                                      <p class="appendBottom3">0 hours to 2 hours*</p>
                                    </div>
                                    <div class="flightDetailsInfoRight">
                                      <p>ADULT : <b>{{ flight.changePenalty }}</b><br></p>
                                    </div>
                                  </div>
                                  <div class="DateChangeInfo">
                                    <div class="flightDetailsInfoLeft">
                                      <p class="appendBottom3">2 hours to 3 days*</p>
                                    </div>
                                    <div class="flightDetailsInfoRight">
                                      <p>ADULT : <b>{{ flight.changePenalty }}</b><br></p>
                                    </div>
                                  </div>
                                  <div class="DateChangeInfo">
                                    <div class="flightDetailsInfoLeft">
                                      <p class="appendBottom3">3 days to 365 days*</p>
                                    </div>
                                    <div class="flightDetailsInfoRight">
                                      <p>ADULT : <b>{{ flight.changePenalty }}</b><br></p>
                                    </div>
                                  </div>
                                </div>
                                <p class="darkText fontSize12 appendBottom12 appendLeft10 appendTop10">*From the Date of
                                  Departure</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="infoStrip appendTop15 appendBottom15"><b>*Important:</b> The airline fee is
                          indicative. Gia Travel does not guarantee the accuracy of this information. All fees mentioned
                          are per passenger. Date change charges are applicable only on selecting the same airline on a
                          new date. The difference in fares between the old and the new booking will also be payable by
                          the user.<br>Please refer to the Date Change Charges section above for details on the number
                          of allowed free date changes, if applicable<br></div>
                      </div>
                    </div>
                </div>
              </div>

              <!-- End Flight Details-->
              <div class="accordion__content collapse mh-100" id="view-price">

                <div class="viewFaresOuter ">
                  <div class="fontSize10 viewFareRowHead"><span class="fareName">FARES</span>
                    <div class="viewFareRowContent"><span class="otherClass flexOne appendRight10">Cabin bag</span><span
                        class="otherClass flexOne appendRight10">Check-in</span><span
                        class=" flexOne appendRight10">Cancellation</span><span class=" flexOne appendRight10">Date
                        Change</span><span class=" flexOne appendRight10">Seat</span><span
                        class=" flexOne appendRight10">Meal</span></div><span class="viewFareBtnCol"></span>
                  </div>
                  <div class="viewFareRowWrap">
                    <div class="make_flex ">
                      <div class="fareFamilyListv4">
                        <div class="viewFareRow ">
                          <div class="fareName"><span class="leftBrd"></span>
                            <p class="fareNameHead blackFont blackText appendBottom3">Saver</p>
                            <p class="fontSize12">Fare offered by airline.</p>
                          </div>
                          <div class="viewFareRowContent" >
                            <div class="otherClass whatsAppMetaDataContainer appendRight10">7 Kgs</div>
                            <div class="otherClass whatsAppMetaDataContainer appendRight10">15 Kgs</div>
                            <div class=" whatsAppMetaDataContainer appendRight10">Cancellation Fee Starting {{flight.cancelPenalty}}
                            </div>
                            <div class=" whatsAppMetaDataContainer appendRight10">Date Change fee starting {{flight.cancelPenalty}}</div>
                            <div class=" whatsAppMetaDataContainer appendRight10">Chargeable</div>
                            <div class=" whatsAppMetaDataContainer appendRight10">Chargeable</div>
                          </div>
                        </div>
                      </div>
                      <div class="viewFareBtnCol ">
                        <p class="blackText fontSize16 blackFont appendBottom5  ">GBP  {{ flight.totalPrice }}</p>
                        <div><button class="button buttonSecondry appendBottom8 fontSize13" fdprocessedid="agyja">LOCK
                            PRICE</button></div><button id="bookbutton-RKEY:f66a144c-43b4-4900-a80f-db2df8e645f6:51_0"
                          class="button corp-btn latoBlack buttonPrimary fontSize13  " fdprocessedid="o2ush">Book
                          Now</button>
                      </div>
                    </div>
                  </div>
                  <div class="viewFareRowWrap">
                    <div class="appendBottom10 appendTop10 appendLR20">
                      <div class="allInOneBenefits" style="background-color: rgb(255, 237, 209);"><span
                          class="showBenefitsOffer">Get benefits worth ₹ 249</span>
                        <div class="benefitListWapper">
                          <ul class="makeFlex hrtlCenter benifitList">
                            <li><span class="bgProperties tvlrInsuranceIcon"
                                style="background-image: url(&quot;https://imgak.mmtcdn.com/flights/assets/media/dt/listing/sp-travel-insurance.png&quot;);"></span>Travel
                              Insurance</li>
                          </ul>
                          <div class="makeFlex hrtlCenter"><span class="slashedPrice">₹ 249</span><span
                              class="blackText blackFont">₹ 174</span></div>
                        </div>
                      </div>
                    </div>
                    <div class="make_flex ">
                      <div class="fareFamilyListv4">
                        <div class="viewFareRow ">
                          <div class="fareName"><span class="leftBrd"
                              style="background-color: rgb(192, 120, 8);"></span>
                            <p class="fareNameHead blackFont blackText appendBottom3">
                              <font color="#C07808">MMT</font>
                              <font color="#000000">SPECIAL</font>
                            </p>
                            <p class="fontSize12">
                              <font color="#000000">Fare by MakeMyTrip</font>
                            </p>
                          </div>
                          <div class="viewFareRowContent">
                            <div class="otherClass whatsAppMetaDataContainer appendRight10">7 Kgs</div>
                            <div class="otherClass whatsAppMetaDataContainer appendRight10">15 Kgs</div>
                            <div class=" whatsAppMetaDataContainer appendRight10">Cancellation Fee Starting ₹ 3,000
                            </div>
                            <div class=" whatsAppMetaDataContainer appendRight10">Date Change fee starting ₹ 2,500</div>
                            <div class=" whatsAppMetaDataContainer appendRight10">Chargeable</div>
                            <div class=" whatsAppMetaDataContainer appendRight10">Chargeable</div>
                          </div>
                        </div>
                      </div>
                      <div class="viewFareBtnCol ">
                        <p class="blackText fontSize16 blackFont appendBottom5  ">₹ 5,403</p>
                        <div><button class="button buttonSecondry appendBottom8 fontSize13" fdprocessedid="ma80v8">LOCK
                            PRICE</button></div><button id="bookbutton-RKEY:f66a144c-43b4-4900-a80f-db2df8e645f6:51_2"
                          class="button corp-btn latoBlack buttonPrimary fontSize13  " fdprocessedid="pzujpv">Book
                          Now</button>
                      </div>
                    </div>
                  </div>
                  <div class="viewFareRowWrap">
                    <div class="make_flex ">
                      <div class="fareFamilyListv4">
                        <div class="viewFareRow ">
                          <div class="fareName"><span class="leftBrd"></span>
                            <p class="fareNameHead blackFont blackText appendBottom3">Flexi</p>
                            <p class="fontSize12">Fare offered by airline.</p>
                          </div>
                          <div class="viewFareRowContent">
                            <div class="otherClass whatsAppMetaDataContainer appendRight10">7 Kgs</div>
                            <div class="otherClass whatsAppMetaDataContainer appendRight10">15 Kgs</div>
                            <div class=" whatsAppMetaDataContainer appendRight10">Cancellation Fee Starting ₹ 500</div>
                            <div class=" whatsAppMetaDataContainer appendRight10">Free Date change allowed upto 3 days
                              before departure</div>
                            <div class=" whatsAppMetaDataContainer appendRight10">Free seats available</div>
                            <div class=" whatsAppMetaDataContainer appendRight10">Get complimentary meals</div>
                          </div>
                        </div>
                      </div>
                      <div class="viewFareBtnCol ">
                        <p class="blackText fontSize16 blackFont appendBottom5  ">₹ 5,756</p>
                        <div><button class="button buttonSecondry appendBottom8 fontSize13" fdprocessedid="gz0xbj">LOCK
                            PRICE</button></div><button id="bookbutton-RKEY:f66a144c-43b4-4900-a80f-db2df8e645f6:51_1"
                          class="button corp-btn latoBlack buttonPrimary fontSize13  " fdprocessedid="9h44vv">Book
                          Now</button>
                      </div>
                    </div>
                  </div>
                </div>

              </div>

            </div>
          </div>

        </div>
      
      </div>

    </div>   
          <!-- //end new iteration -->

          <!-- //Start old iteration -->
           
          

           
       <!-- //end old iteration -->

         <!-- //Start Travel Port iteration -->

         <!-- <div class="js-accordion">
          <p>Travel Port flights available.</p>
        </div>

        <h2>Flight Data</h2> -->
        <!-- <pre>Bright:{{ flights }}</pre>

        <pre>Travel port : {{ flightsTp }}</pre> -->

           
         
          <!-- <div  *ngFor="let flight of flightsTp; let j = index;">
         
             
 
             <div  *ngFor="let ProductBrandOptions of flight.ProductBrandOptions; let w = index;">

              <div  *ngFor="let ProductBrandOffering of ProductBrandOptions.ProductBrandOffering; let w = index;">
<p>
              FlightRefs : {{ProductBrandOptions.flightRefs}}
                Departure:: {{flight.Departure}}
                Arrival : {{flight.Arrival}}
           Total Pirce:: {{ProductBrandOffering.Price.TotalPrice}}
</p>

       
          </div>
    </div>
  
   

 

</div>  -->

           
       <!-- //end Travel Port  iteration -->


        </div>
      </div>
    </div>
  </section>
</main>