 // home.component.ts
import { Component, OnInit, NgModule } from '@angular/core';
import { FormControl } from '@angular/forms';
import { HomeService } from './homeService';
import { Observable,of } from 'rxjs';
import { map, startWith,switchMap } from 'rxjs/operators';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatOptionModule } from '@angular/material/core'; // or '@angular/material/option'
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
 
import { CommonModule } from '@angular/common';
import { Globals } from '../../globals';


@Component({ 
  selector: 'app-home',
  providers: [ Globals ],
  standalone: true,
  imports: [ReactiveFormsModule,MatFormFieldModule, MatOptionModule,MatAutocompleteModule , MatInputModule,CommonModule],
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {  



  airportControl = new FormControl();
 // filteredAirports: Observable<any[]>;
// filteredAirports: Observable<any[]> = new Observable<any[]>();
filteredAirports$: Observable<any[]> = new Observable<any[]>();
 
  //airports: any[] = [];
 // airports$!: Observable<any>; // Provide an initializer


  constructor(private airportService: HomeService,public globals: Globals) { }

//   ngOnInit(): void {  
    
//   this.filteredAirports$ = this.airportControl.valueChanges.pipe(
//         startWith(''),
//         map(value => typeof value === 'string' ? value : value.name),
//         map(name => name ? this._filter(name) : this.airportService.getAirports())
//       ); 
// } 

onInputChange(event: Event) {
  const value = (event.target as HTMLInputElement).value; // Explicitly cast to HTMLInputElement
  // Now you can use the value
  this._filter(value);
  console.log('Input value:', value);
}


ngOnInit(): void {
  this.filteredAirports$ = this.airportControl.valueChanges.pipe(
    
    startWith(''),
    map(value => typeof value === 'string' ? value : value.name),
    switchMap(name => this._filter(name))
  );
}
 
  displayFn(airport: any): string {
    console.log("Diplay Fn ");
    if (airport && airport.name) {
      return `${airport.name} (${airport.iata_code})`; 
    } else {
      return 'ahmedabad 111'; // Or any default value you prefer
    }
  } 

   

  private _filter(name: string): Observable<any[]> {
    const filterValue = name.toLowerCase();
    console.log("filterValue"+ name.toLowerCase());
    return this.airportService.getAirports().pipe( 
      map(airports => {
        if (!airports) {
          return [];
        }
        return airports.filter((airport: any) => airport.name.toLowerCase().includes(filterValue));
      })
    );
  }


  // private _filter(name: string): any[] {
  //   const filterValue = name.toLowerCase();
  //   return this.airportService.getAirports().filter(airport => airport.name.toLowerCase().includes(filterValue));
  // }

  // getAirports(): void { 
  //   this.homeService.getAirports()
  //     .subscribe(
  //       (response: any) => {
  //         this.airports = response.data;
  //         console.log("data:"+response.data); 
  //       },
  //       (error: any) => {
  //         console.error('Error fetching airports:', error);
  //       }
  //     );
  // }


  
  
  
  // getAirportName(code: string): string {
  //   const airport = this.airports.find(a => a.code === code);
  //   return airport ? airport.name : '';
  // }
}
