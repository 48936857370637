import { Component } from '@angular/core';

import { PageService } from './page.service';
import { NgComponentOutlet } from '@angular/common';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms'; // Import FormsModule


@Component({
  selector: 'app-page',
  standalone: true,
  imports: [NgComponentOutlet,CommonModule,FormsModule],
  templateUrl: './page.component.html',
  styleUrl: './page.component.css'
})
export class PageComponent {

  pages: any[] = [];
  public query ='';

  constructor(private pageService: PageService,private route: ActivatedRoute) { }


  ngOnInit(): void {
    //this.fetchFlights();
    this.route.queryParams.subscribe(params => {
      this.query = params['query'];
       
  

     this.pageService.getPages(this.query).subscribe({
      next: (pages) => {
     

        this.pages =  Array.from(pages);
 

 // Extract unique airline names
const uniqueAirlinesSet = new Set<string>(); // Using a Set to ensure uniqueness

//const uniqueDepartureAirportSet = new Set<string>(); // Using a Set to ensure uniqueness
const uniqueDepartureAirports: { airportName: string; airportCode: string }[] = [];

const uniqueArrivalAirports: { airportName: string; airportCode: string }[] = [];
 

      },
   
      error: (error) => {
        console.error('Error fetching pages:', error);
      }
    });
  });
  }

}
