// auth.service.ts
import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private tokenKey = 'user-token';

  //constructor(private http: HttpClient, private router: Router) {}
  constructor(
    private http: HttpClient,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  saveToken(token: string): void {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem(this.tokenKey, token);
      console.log("Saved token");
    } else {
      console.error("saveToken Not running in the browser environment");
  }
  }

  getToken(): string | null {
    if (isPlatformBrowser(this.platformId)) {
        console.log("Gettoken:"+ localStorage.getItem(this.tokenKey));

      return localStorage.getItem(this.tokenKey);
    }else{
      console.log("Gettoken:"+ localStorage.getItem(this.tokenKey));
      console.error("getToken Not running in the browser environment");
      return localStorage.getItem(this.tokenKey);
      
    }
    return null;
  }

  isLoggedIn(): boolean {
    const token = this.getToken();
    console.log("token:"+token);
    if (token) {
      const payload = JSON.parse(atob(token.split('.')[1]));
      return payload.exp > Date.now() / 1000;
    }
    return false;
  }

  currentUser(): string | null {
    if (this.isLoggedIn()) {
      const token = this.getToken();
      const payload = JSON.parse(atob(token!.split('.')[1]));
      return payload.username;
    }
    return null;
  }

//   register(user: any): Promise<void> {
//     return this.http.post<{ token: string }>('/register', user).toPromise().then(response => {
//       this.saveToken(response.token);
//     });
//   }

//   logIn(user: any): Promise<void> {
//     return this.http.post<{ token: string }>('/login', user).toPromise().then(response => {
//       this.saveToken(response.token);
//     });
//   }

  logOut(): void {
    localStorage.removeItem(this.tokenKey);
    this.router.navigate(['/login']);
  }
}
