import { Component,Input } from '@angular/core';


import { RouterOutlet } from '@angular/router';
 import { NgComponentOutlet } from '@angular/common';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
 import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms'; // Import FormsModule
import { HttpClient, HttpParams,HttpHeaders } from '@angular/common/http';
import { Globals } from '../../globals'; 
import { HotelService } from './hotel.service';


@Component({
  selector: 'app-hotel-details',
  standalone: true,
  imports:[NgComponentOutlet,CommonModule,FormsModule] ,
  templateUrl: './hotel-details.component.html',
  styleUrl: './hotel-details.component.css'
})
export class HotelDetailsComponent {
  //hotels: any[] = [];
  imageChunks: any[][] = [];

  //hotels:any= {};
  @Input() hotels: any;
  @Input() roomsData: any;

  
  hotel_search: string | null = null;
  
  public region_id ='';
  public hotel_id ='';
  public region_country =  '';
  public region_city =  '';
  public checkin_date = '';
  public checkout_date =  '';
  public room =  '';
  public adults = 0;
  public children = 0;
 

  constructor(private route: ActivatedRoute,private hotelService: HotelService) {}

  ngOnInit(): void {
    // Extract query parameters
    this.route.queryParams.subscribe(params => {
      this.region_id = params['region_id'];
      this.hotel_id = params['id'];
      this.region_country = params['region_country'];
      this.region_city = params['region_city'];
      this.checkin_date = params['checkin_date'];
      this.checkout_date = params['checkout_date'];
      this.hotel_search = params['hotel_search'];
       this.room = params['room'] ;
      this.adults = params['adults'];
      this.children = params['children'];

      this.hotelService.hotelInfo(this.hotel_id,this.checkin_date,this.checkout_date, this.adults, this.children, this.region_id).subscribe(response => {
       // this.hotels = Array.isArray(response['data']) ? response['data'] : Array.from(response['data']);
         this.hotels =  response['hotel_data']['data'];
         this.roomsData =  response['room_data']['data'];
         //this.hotels =  response.hotel_data;
       // this.hotels =Array.from(response['data']);
      //  console.log("Full response:", response);

        this.chunkImages(this.hotels.images);


        console.log("hotel data component :"+JSON.stringify(this.hotels));
       }, error => {
         console.error('Error:', error);
       });

    });
  }

  chunkImages(images: string[]): void {
    this.imageChunks = [];

    // First chunk with 1 image
    if (images.length > 0) {
      this.imageChunks.push([images[0]]);
    }

    // Remaining images in chunks of 2
    //for (let i = 1; i < images.length; i += 2) {
    for (let i = 1; i < 10; i += 2) {
      this.imageChunks.push(images.slice(i, i + 2));
    }
  }

  getKeys(obj: any): string[] {
    return Object.keys(obj);
  }
  


}
