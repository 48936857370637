import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Globals } from '../../globals'; 
@Injectable({
  providedIn: 'root'
})
export class PageService {

  constructor(private http: HttpClient,private globals: Globals) { }
  private apiUrl = this.globals.APIURL+ '/api/pages';
 // private apiUrl = 'http://localhost:3000/api/pages';

  //constructor(private http: HttpClient) { }

  

  getPages(query : string): Observable<any[]> {
    const url = `${this.apiUrl}?query=${query}`;

    return this.http.get<any[]>(url);
  }
}
