import { Component, OnInit  } from '@angular/core';
 
import { NgComponentOutlet } from '@angular/common';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms'; // Import FormsModule
import { HttpClient, HttpParams } from '@angular/common/http';
import { Globals } from '../../globals'; 
import { AuthService } from '../services/auth.service';
import { SessionService } from '../services/session.service';




@Component({
  selector: 'app-login',
  standalone: true,
  imports: [NgComponentOutlet,CommonModule,FormsModule],
  templateUrl: './login.component.html',
  styleUrl: './login.component.css'
})
export class LoginComponent  implements OnInit {

  isPostDataRoute: boolean = false;
  
  public sess_email='';
  public email =  '';
  public password = '';
  public apiUrl=''; 
  public responseMessage='';
  private tokenKey = 'user-token';
  isLoggedIn: boolean = false;

  constructor(private sessionService: SessionService,private authService: AuthService,private http: HttpClient,private route: ActivatedRoute, private router: Router ,private globals: Globals) {}

  ngOnInit() {
    this.route.url.subscribe(url => {
        this.isPostDataRoute = this.router.url.includes('postdata');
    });
}

  postData() {
    // Implement post data logic here
    console.log('PostData function called : '+this.email);

    const postData = {
        
        email: this.email,
        password: this.password
    };
  //  this.apiUrl = 'http://localhost:3000/api/user/login';
  this.apiUrl = this.globals.APIURL+'/api/user/login';
    this.http.post<any>(this.apiUrl, postData, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
    })
    .subscribe(
        response => {
            // Store the response data in the component state
            console.log("Signup response : ", response);
           
            if( response.alert_type=="success"){

              console.log("login response : ");
              // this.authService.saveToken(this.email);
              // localStorage.setItem(this.sess_email, this.email);
             // this.router.navigate(['/profile']);
             this.sessionService.setSession(this.email, { username: 'user' });
             this.isLoggedIn = true;
             //window.location.href='/profile';
            this.router.navigate(['/profile']);

            }else{
              this.responseMessage = response.message; 
            }
          
            
          //  res.status(200).json({ status: "alert",alert_type:"error",alert_head:"Registration Failed!", message:errormsg });

        },
        error => {
            console.error('An error occurred:', error);
            this.responseMessage = 'An error occurred during signup';
        }
    );
}

logout() {
  this.sessionService.clearSession();
  this.isLoggedIn = false;
}

}
