 import { RouterOutlet } from '@angular/router';
import { Component, OnInit } from '@angular/core';
 import { NgComponentOutlet } from '@angular/common';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
 import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms'; // Import FormsModule
import { HttpClient, HttpParams,HttpHeaders } from '@angular/common/http';
import { Globals } from '../../globals'; 
import { HotelService } from './hotel.service';



@Component({
  selector: 'app-hotel',
  templateUrl: './hotel.component.html',
  styleUrl: './hotel.component.css',
  standalone: true,
  imports:[NgComponentOutlet,CommonModule,FormsModule] 
})
export class HotelComponent {  
  
  hotels: any[] = [];

  // flights: any[] = [];
   public region_id ='';
   public region_country =  '';
   public region_city =  '';
   public checkin_date = '';
   public checkout_date =  '';
   public room =  '';
   public adults = 0;
   public children = '';
   public apiUrl='';
   
  constructor(private http: HttpClient,private route: ActivatedRoute,private globals: Globals,private hotelService: HotelService) { }

  ngOnInit(): void {

    this.route.queryParams.subscribe(params_in => {
      this.region_id =  params_in['region_id'];
      this.region_country =  params_in['region_country'];
      this.region_city =  params_in['region_city'];
      this.checkin_date =  params_in['checkin_date'];
      this.checkout_date = params_in['checkout_date'];
      this.room = params_in['room'];
      this.adults= params_in['adults'];
      const children =  params_in['children'];
      
      this.hotelService.searchRegion(this.checkin_date, this.checkout_date, this.adults, children, this.region_id).subscribe(response => {
       // console.log("Hotel Data:"+JSON.stringify(response['data']['hotels']));
        this.hotels =  Array.from(response['data']['hotels']);

      }, error => {
        console.error('Error:', error);
      });

    });

  }

}
