<main class="flight_listing w-100">  
<div class="banner-one w-100 float-start" style="margin-top:0px !important" >

    <div class="banner-one_image-layer" style="background-image: url(./assets/images/bg.png)">
    </div>

    <div class="auto-container">
        <!-- Content Column -->
        <div class="banner-one_content">
            <div class="banner-one_content-inner">

                <h1 class="banner-one_heading">Modify Your Search</h1>
                <div class="home-tabs sec search-bar inner-search-bar">
                    <div class="container">
                        <div class="inner">

                            <form novalidate="" action="/hotel" method="get" class="">

                                <div id="nav-tabContent" class="tab-content">

                                    <div id="nav-hotel" role="tabpanel" aria-labelledby="nav-hotel-tab" class="tab-pane fade active show mt-0 p-3">
                                        <div class="rounded-div">
                                            <div class="row g-3">
                                                <div class="col-lg-6 col-md-6"><label>Enter City or Hotel name</label>
                                                    <div class="form-control d-flex form-control2"><svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" fill="#000000" class="icon">
                                                            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                                            <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                                                            <g id="SVGRepo_iconCarrier">
                                                                <path d="M762.9 116.2c-7.2-14-25.9-25.5-41.7-25.5h-417c-15.8 0-34.5 11.5-41.7 25.5L176 285.3c-7.2 14-0.1 25.5 15.6 25.5h642.1c15.8 0 22.8-11.5 15.6-25.5l-86.4-169.1z" fill="#333E48"></path>
                                                                <path d="M786.1 161.6H600.4v28.6h200.4l-14.7-28.6z m-561.4 28.7h200.4v-28.6H239.3l-14.6 28.6z" fill=""></path>
                                                                <path d="M158.4 289.4h708.7v622.1H158.4z" fill="#00B3E3"></path>
                                                                <path d="M766.3 377.5h100.8V426H766.3zM628.4 597.6h100.8v48.5H628.4zM504.1 376.7h100.8v48.5H504.1zM158.4 495h100.8v48.5H158.4zM237.5 332.9h100.8v48.5H237.5zM386.4 667.5h100.8V716H386.4z" fill=""></path>
                                                                <path d="M208.7 799.2h100.8v48.5H208.7z" fill="#5D5D5D"></path>
                                                                <path d="M766.3 796.5h100.8V845H766.3z" fill=""></path>
                                                                <path d="M410.6 691.8h204.3v219.7H410.6z" fill="#FFFFFF"></path>
                                                                <path d="M498.4 691.8H527V890h-28.6z" fill=""></path>
                                                                <path d="M220.8 425.1h123.8v140H220.8z" fill="#FFFFFF"></path>
                                                                <path d="M220.8 425.1h123.8V456H220.8z" fill=""></path>
                                                                <path d="M220.8 480.7h123.8v28.6H220.8z" fill="#333E48"></path>
                                                                <path d="M235.1 550.7h95.2V439.4h-95.2v111.3z m109.5 28.6H220.8c-7.9 0-14.3-6.4-14.3-14.3V425c0-7.9 6.4-14.3 14.3-14.3h123.8c7.9 0 14.3 6.4 14.3 14.3v140c0.1 7.9-6.4 14.3-14.3 14.3z" fill="#0071CE"></path>
                                                                <path d="M450.8 425.1h123.8v140H450.8z" fill="#FFFFFF"></path>
                                                                <path d="M450.8 425.1h123.8V456H450.8z" fill=""></path>
                                                                <path d="M450.8 480.7h123.8v28.6H450.8z" fill="#333E48"></path>
                                                                <path d="M465.1 550.7h95.2V439.4h-95.2v111.3z m109.5 28.6H450.8c-7.9 0-14.3-6.4-14.3-14.3V425c0-7.9 6.4-14.3 14.3-14.3h123.8c7.9 0 14.3 6.4 14.3 14.3v140c0 7.9-6.4 14.3-14.3 14.3z" fill="#0071CE"></path>
                                                                <path d="M680.8 425.1h123.8v140H680.8z" fill="#FFFFFF"></path>
                                                                <path d="M680.8 425.1h123.8V456H680.8z" fill=""></path>
                                                                <path d="M680.8 480.7h123.8v28.6H680.8z" fill="#333E48"></path>
                                                                <path d="M695.1 550.7h95.2V439.4h-95.2v111.3z m109.5 28.6H680.8c-7.9 0-14.3-6.4-14.3-14.3V425c0-7.9 6.4-14.3 14.3-14.3h123.8c7.9 0 14.3 6.4 14.3 14.3v140c0 7.9-6.4 14.3-14.3 14.3z" fill="#0071CE"></path>
                                                                <path d="M220.8 621.8h123.8v140H220.8z" fill="#FFFFFF"></path>
                                                                <path d="M220.8 621.8h123.8v30.9H220.8z" fill=""></path>
                                                                <path d="M220.8 677.5h123.8v28.6H220.8z" fill="#333E48"></path>
                                                                <path d="M235.1 747.4h95.2V636.1h-95.2v111.3z m109.5 28.7H220.8c-7.9 0-14.3-6.4-14.3-14.3v-140c0-7.9 6.4-14.3 14.3-14.3h123.8c7.9 0 14.3 6.4 14.3 14.3v140c0.1 7.9-6.4 14.3-14.3 14.3z" fill="#0071CE"></path>
                                                                <path d="M680.8 621.8h123.8v140H680.8z" fill="#FFFFFF"></path>
                                                                <path d="M680.8 621.8h123.8v30.9H680.8z" fill=""></path>
                                                                <path d="M680.8 677.5h123.8v28.6H680.8z" fill="#333E48"></path>
                                                                <path d="M695.1 747.4h95.2V636.1h-95.2v111.3z m109.5 28.7H680.8c-7.9 0-14.3-6.4-14.3-14.3v-140c0-7.9 6.4-14.3 14.3-14.3h123.8c7.9 0 14.3 6.4 14.3 14.3v140c0 7.9-6.4 14.3-14.3 14.3zM894.1 290.5c0-10.5-8.6-19.1-19.1-19.1H150.4c-10.5 0-19.1 8.6-19.1 19.1v23.2c0 10.5 8.6 19.1 19.1 19.1H875c10.5 0 19.1-8.6 19.1-19.1v-23.2z" fill="#0071CE"></path>
                                                                <path d="M960.2 925.3c0 4.2-3.5 7.7-7.7 7.7H72.9c-4.2 0-7.7-3.5-7.7-7.7v-27.6c0-4.2 3.5-7.7 7.7-7.7h879.5c4.2 0 7.7 3.5 7.7 7.7v27.6z" fill="#333E48"></path>
                                                                <path d="M349.9 889.9h325.7v43H349.9z" fill="#A4A9AD"></path>
                                                                <path d="M545.2 121.4c-7.9 0-14.3 6.4-14.3 14.3v31h-36.3v-31c0-7.9-6.4-14.3-14.3-14.3s-14.3 6.4-14.3 14.3v90.7c0 7.9 6.4 14.3 14.3 14.3s14.3-6.4 14.3-14.3v-31h36.3v31c0 7.9 6.4 14.3 14.3 14.3s14.3-6.4 14.3-14.3v-90.7c0-7.9-6.4-14.3-14.3-14.3z" fill="#FFFFFF"></path>
                                                            </g>
                                                        </svg>
                                                        <div>
                                                            <input type="text" placeholder="Enter city or hotel">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-3 col-md-3"><label>Check in Date</label>
                                                    <div class="form-control d-flex date date1"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                            <path d="M9 11H7v2h2v-2zm4 0h-2v2h2v-2zm4 0h-2v2h2v-2zm2-7h-1V2h-2v2H8V2H6v2H5c-1.11 0-1.99.9-1.99 2L3 20c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 16H5V9h14v11z"></path>
                                                            <path fill="none" d="M0 0h24v24H0z"></path>
                                                        </svg>
                                                        <div><input type="text" name="datefilter" class="date-calendar">
                                                            <p class="blackText f_depdate"><span class="dateStart">11</span><span></span><span class="monthStart"> Jul 2024</span></p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-3 col-md-3"><label>Check out Date</label>
                                                    <div class="form-control d-flex date"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                            <path d="M9 11H7v2h2v-2zm4 0h-2v2h2v-2zm4 0h-2v2h2v-2zm2-7h-1V2h-2v2H8V2H6v2H5c-1.11 0-1.99.9-1.99 2L3 20c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 16H5V9h14v11z"></path>
                                                            <path fill="none" d="M0 0h24v24H0z"></path>
                                                        </svg>
                                                        <div><input type="text" name="datefilter" class="date-calendar">
                                                            <p class="blackText f_depdate"><span class="dateEnd">09</span><span></span><span class="monthEnd"> Aug 2024</span></p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-3 col-md-3"><label>Rooms</label>
                                                    <div class="form-control form-control-select">
                                                        <select>
                                                            <option>1</option>
                                                            <option>2</option>
                                                            <option>3</option>
                                                            <option>4</option>
                                                            <option>5</option>
                                                            <option>6</option>
                                                            <option>7</option>
                                                            <option>8</option>
                                                            <option>9</option>
                                                        </select>
                                                        <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" class="css-19bqh2r position-absolute end-0">
                                                            <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
                                                        </svg>
                                                    </div>
                                                </div>
                                                <div class="col-lg-3 col-md-3"><label>Adults (16+)</label>
                                                    <div class="form-control form-control-select">
                                                        <select>
                                                            <option>1</option>
                                                            <option>2</option>
                                                            <option>3</option>
                                                            <option>4</option>
                                                            <option>5</option>
                                                            <option>6</option>
                                                            <option>7</option>
                                                            <option>8</option>
                                                            <option>9</option>
                                                        </select>
                                                        <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" class="css-19bqh2r position-absolute end-0">
                                                            <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
                                                        </svg>
                                                    </div>
                                                </div>
                                                <div class="col-lg-3 col-md-3"><label>Child (2 - 12)</label>
                                                    <div class="form-control form-control-select">
                                                        <select>
                                                            <option>1</option>
                                                            <option>2</option>
                                                            <option>3</option>
                                                            <option>4</option>
                                                            <option>5</option>
                                                            <option>6</option>
                                                            <option>7</option>
                                                            <option>8</option>
                                                            <option>9</option>
                                                        </select>
                                                        <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" class="css-19bqh2r position-absolute end-0">
                                                            <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
                                                        </svg>
                                                    </div>
                                                </div>
                                                <div class="searchbtn align-self-end col-lg-3 col-md-3">
                                                    <div class="text-center"><button type="submit" class="btn"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                                <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" fill="white"></path>
                                                                <path d="M0 0h24v24H0z" fill="none"></path>
                                                            </svg> Search</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </form>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>

</div>

<section class="w-100 float-start section">
    <div class="container">
        <div class="row g-2">

            <div class="col-lg-3">

                <div class="projects-one_filters d-lg-none mb-0 mt-0">
                    <ul class="filter-tabs">
                        <!-- <span class="">Show Filters</span> -->
                        <li class="collapse-btn" data-bs-toggle="collapse" data-bs-target="#collapseFilter">
                            <span class="">Show Filters</span>
                            <!-- <i class='bx bx-filter d-md-none fs-2'></i> -->
                        </li>
                    </ul>
                </div>

                <div id="collapseFilter" class="collapse d-lg-block">
                    <div class="sidebar-filter position-sticky shadow p-3 rounded-4">
                        <div class="widget border-bottom-0 bg-transparent">
                            <h6 class="widget-title mb-0">Filters</h6>
                        </div>

                        <div class="widget">
                            <h6 class="widget-title">Sort By</h6>
                            <div class="filter-style">
                                <select class="form-control">
                                    <option>Select</option>
                                    <option>Popularity</option>
                                    <option>Price (low to high)</option>
                                    <option>Price (low to high)</option>
                                    <option>Price (high to low)</option>
                                    <option>Guests' rating (high to low)</option>
                                </select>
                            </div>
                        </div>

                        <div class="widget">
                            <h6 class="widget-title">Hotel Name</h6>
                            <div class="filter-style">
                                <input type="text" class="form-control" placeholder="For example, Hilton">
                            </div>
                        </div>

                        <div class="widget">
                            <h6 class="widget-title">Meals</h6>
                            <div class="filter-style">
                                <form action="" method="post">
                                    <div class="form-check">
                                        <label for="checkbox1">No meals included</label>
                                        <input type="checkbox" class="form-check-input" name="stops[]" value="1">
                                    </div>
                                    <div class="form-check">
                                        <label for="checkbox1">Breakfast included</label>
                                        <input type="checkbox" class="form-check-input" name="stops[]" value="0">
                                    </div>
                                    <div class="form-check">
                                        <label for="checkbox1">Breakfast + dinner or lunch included</label>
                                        <input type="checkbox" class="form-check-input" name="stops[]" value="0">
                                    </div>
                                    <div class="form-check">
                                        <label for="checkbox1">Breakfast, lunch and dinner included</label>
                                        <input type="checkbox" class="form-check-input" name="stops[]" value="0">
                                    </div>
                                    <div class="form-check">
                                        <label for="checkbox1">All included</label>
                                        <input type="checkbox" class="form-check-input" name="stops[]" value="0">
                                    </div>
                                </form>
                            </div>
                        </div>

                        <div class="widget">
                            <h6 class="widget-title">Price for a night in GBP</h6>
                            <div class="filter-price-range">
                                <div class="range-slider w-100 float-left pt-0">
                                    <span class="irs irs--flat js-irs-0"><span class="irs"><span class="irs-line" tabindex="0"></span><span class="irs-min" style="visibility: hidden;">5k</span><span class="irs-max" style="visibility: visible;">1 000k</span><span class="irs-from" style="visibility: visible; left: -2.1164%;">5k</span><span class="irs-to" style="visibility: visible; left: 47.9155%;">600k</span><span class="irs-single" style="visibility: hidden; left: 14.6985%;">5k — 600k</span></span><span class="irs-grid"></span><span class="irs-bar" style="cursor: ew-resize; left: 5.29101%; width: 53.4711%;"></span><span class="irs-shadow shadow-from" style="display: none;"></span><span class="irs-shadow shadow-to" style="display: none;"></span><span class="irs-handle from" style="left: 0%;"><i></i><i></i><i></i></span><span class="irs-handle to type_last" style="left: 53.4711%;"><i></i><i></i><i></i></span></span><input type="text" class="js-range-slider irs-hidden-input" name="my_range" id="my_range" value="" tabindex="-1" readonly="">
                                    <div class="clearfix"></div>
                                    <p class="m-0 float-left ml-4 mt-2 w-100 d-flex justify-content-between">
                                        <span class="text_min" style="word-break:keep-all;">£5</span>
                                        <span class="text_max" style="word-break:keep-all;">£600</span>
                                    </p>
                                </div>
                            </div>
                        </div>

                        <style>
                            .range-slider .irs--flat .irs-handle {
                                top: 20px;
                                width: 20px;
                                height: 20px;
                                background-color: #f1817f;
                                border-radius: 100%;
                                cursor: pointer;
                                border: 2px solid rgb(255, 255, 255);
                            }

                            .range-slider .irs--flat .irs-from,
                            .range-slider .irs--flat .irs-to,
                            .range-slider .irs--flat .irs-single {
                                color: white;
                                font-size: 12px;
                                line-height: 1;
                                padding: 3px 5px 3px 10px;
                                background-color: #f1817f;
                                top: -7px;
                                display: none;
                            }

                            .range-slider .irs--flat .irs-handle>i:first-child {
                                background-color: #f1817f;
                            }

                            .range-slider .irs--flat .irs-from::before,
                            .range-slider .irs--flat .irs-to::before,
                            .range-slider .irs--flat .irs-single::before {
                                border-top-color: #f1817f;
                            }

                            .range-slider .irs--flat .irs-bar {
                                background: #f1817f;
                            }

                            .range-slider .irs--flat .irs-min,
                            .range-slider .irs--flat .irs-max {
                                display: none;
                            }

                            .range-slider .irs--flat {
                                float: left;
                                width: 100%;
                            }

                            .range-slider p {
                                font-size: 14px !important;
                            }

                            @media screen and (max-width: 575.98px) {
                                .range-slider .irs--flat {
                                    width: 60%;
                                }

                                .range-slider p {
                                    width: 30%;
                                    font-size: 14px !important;
                                }
                            }
                        </style>


                        <div class="widget">
                            <h6 class="widget-title">Star Rating</h6>
                            <div class="filter-style">
                                <form action="" method="post">
                                    <div class="form-check">
                                        <label>
                                            <i class="fa fa-star text-warning" aria-hidden="true"></i>
                                            <i class="fa fa-star text-warning" aria-hidden="true"></i>
                                            <i class="fa fa-star text-warning" aria-hidden="true"></i>
                                            <i class="fa fa-star text-warning" aria-hidden="true"></i>
                                            <i class="fa fa-star text-warning" aria-hidden="true"></i>
                                        </label>
                                        <input type="checkbox" class="form-check-input">
                                    </div>
                                    <div class="form-check">
                                        <label>
                                            <i class="fa fa-star text-warning" aria-hidden="true"></i>
                                            <i class="fa fa-star text-warning" aria-hidden="true"></i>
                                            <i class="fa fa-star text-warning" aria-hidden="true"></i>
                                            <i class="fa fa-star text-warning" aria-hidden="true"></i>
                                        </label>
                                        <input type="checkbox" class="form-check-input">
                                    </div>
                                    <div class="form-check">
                                        <label>
                                            <i class="fa fa-star text-warning" aria-hidden="true"></i>
                                            <i class="fa fa-star text-warning" aria-hidden="true"></i>
                                            <i class="fa fa-star text-warning" aria-hidden="true"></i>
                                        </label>
                                        <input type="checkbox" class="form-check-input">
                                    </div>
                                    <div class="form-check">
                                        <label>
                                            <i class="fa fa-star text-warning" aria-hidden="true"></i>
                                            <i class="fa fa-star text-warning" aria-hidden="true"></i>
                                        </label>
                                        <input type="checkbox" class="form-check-input">
                                    </div>
                                    <div class="form-check">
                                        <label>
                                            <i class="fa fa-star text-warning" aria-hidden="true"></i>
                                        </label>
                                        <input type="checkbox" class="form-check-input">
                                    </div>
                                </form>
                            </div>
                        </div>

                        <div class="widget">
                            <h6 class="widget-title">Payment and booking</h6>
                            <div class="filter-style">
                                <form action="" method="post">
                                    <div class="form-check">
                                        <label>Free cancellation available</label>
                                        <input type="checkbox" class="form-check-input">
                                    </div>
                                    <div class="form-check">
                                        <label>Pay Now</label>
                                        <input type="checkbox" class="form-check-input">
                                    </div>
                                    <div class="form-check">
                                        <label>Pay on the spot</label>
                                        <input type="checkbox" class="form-check-input">
                                    </div>
                                </form>
                            </div>
                        </div>

                        <div class="widget">
                            <h6 class="widget-title">Facilities and Services</h6>

                            <div class="filter-amenities scroll">
                                <p class="f-12 mb-5">In the hotel</p>
                                <form action="" method="post">
                                    <div class="form-check">
                                        <label>Free Internet</label>
                                        <input type="checkbox" class="form-check-input">
                                    </div>
                                    <div class="form-check">
                                        <label>Transfer</label>
                                        <input type="checkbox" class="form-check-input">
                                    </div>
                                    <div class="form-check">
                                        <label>Parking</label>
                                        <input type="checkbox" class="form-check-input">
                                    </div>
                                    <div class="form-check">
                                        <label>Swimming Pool</label>
                                        <input type="checkbox" class="form-check-input">
                                    </div>
                                    <div class="form-check">
                                        <label>Fitness centre</label>
                                        <input type="checkbox" class="form-check-input">
                                    </div>
                                    <div class="form-check">
                                        <label>Bar or restaurant</label>
                                        <input type="checkbox" class="form-check-input">
                                    </div>
                                    <div class="form-check">
                                        <label>Conference hall</label>
                                        <input type="checkbox" class="form-check-input">
                                    </div>
                                    <div class="form-check">
                                        <label>Spa Services</label>
                                        <input type="checkbox" class="form-check-input">
                                    </div>
                                    <div class="form-check">
                                        <label>Kids</label>
                                        <input type="checkbox" class="form-check-input">
                                    </div>
                                    <div class="form-check">
                                        <label>Beach nearby</label>
                                        <input type="checkbox" class="form-check-input">
                                    </div>
                                    <div class="form-check">
                                        <label>Kitchen</label>
                                        <input type="checkbox" class="form-check-input">
                                    </div>
                                    <div class="form-check">
                                        <label>Pets</label>
                                        <input type="checkbox" class="form-check-input">
                                    </div>
                                </form>
                            </div>

                            <div class="filter-amenities mt-3">
                                <p class="f-12 mb-5">In the room</p>
                                <form action="" method="post">
                                    <div class="form-check">
                                        <label>Private Bathroom</label>
                                        <input type="checkbox" class="form-check-input">
                                    </div>
                                    <div class="form-check">
                                        <label>Kitchen</label>
                                        <input type="checkbox" class="form-check-input">
                                    </div>
                                    <div class="form-check">
                                        <label>Balcony</label>
                                        <input type="checkbox" class="form-check-input">
                                    </div>
                                </form>
                            </div>

                        </div>

                        <div class="widget">
                            <h6 class="widget-title">Accommodation Features</h6>
                            <div class="filter-amenities scroll">
                                <form action="" method="post">
                                    <div class="form-check">
                                        <label>Suitable for children</label>
                                        <input type="checkbox" class="form-check-input" value="">
                                    </div>
                                    <div class="form-check">
                                        <label>For guests with disabilities</label>
                                        <input type="checkbox" class="form-check-input" value="">
                                    </div>
                                    <div class="form-check">
                                        <label>Pets allowed</label>
                                        <input type="checkbox" class="form-check-input" value="">
                                    </div>
                                    <div class="form-check">
                                        <label>Smoking allowed</label>
                                        <input type="checkbox" class="form-check-input" value="">
                                    </div>
                                </form>
                            </div>
                        </div>

                        <div class="widget">
                            <h6 class="widget-title">Property Type</h6>
                            <div class="filter-amenities scroll">
                                <form action="" method="post">
                                    <div class="form-check">
                                        <label>Hotels</label>
                                        <input type="checkbox" class="form-check-input" value="">
                                    </div>
                                    <div class="form-check">
                                        <label>Dorm</label>
                                        <input type="checkbox" class="form-check-input" value="">
                                    </div>
                                    <div class="form-check">
                                        <label>Apartments</label>
                                        <input type="checkbox" class="form-check-input" value="">
                                    </div>
                                    <div class="form-check">
                                        <label>Capsule</label>
                                        <input type="checkbox" class="form-check-input" value="">
                                    </div>
                                    <div class="form-check">
                                        <label>Guesthouses</label>
                                        <input type="checkbox" class="form-check-input" value="">
                                    </div>
                                    <div class="form-check">
                                        <label>Cottages</label>
                                        <input type="checkbox" class="form-check-input" value="">
                                    </div>
                                    <div class="form-check">
                                        <label>Bungalows</label>
                                        <input type="checkbox" class="form-check-input" value="">
                                    </div>
                                    <div class="form-check">
                                        <label>Villas</label>
                                        <input type="checkbox" class="form-check-input" value="">
                                    </div>
                                    <div class="form-check">
                                        <label>Tent</label>
                                        <input type="checkbox" class="form-check-input" value="">
                                    </div>
                                    <div class="form-check">
                                        <label>Camping</label>
                                        <input type="checkbox" class="form-check-input" value="">
                                    </div>
                                </form>
                            </div>
                        </div>

                        <div class="widget">
                            <h6 class="widget-title">Districts</h6>
                            <div class="filter-amenities scroll">
                                <form action="" method="post">
                                    <div class="form-check">
                                        <label>City Centre</label>
                                        <input type="checkbox" class="form-check-input" value="">
                                    </div>
                                    <div class="form-check">
                                        <label>Victoria - Westminster</label>
                                        <input type="checkbox" class="form-check-input" value="">
                                    </div>
                                    <div class="form-check">
                                        <label>Kensington and Chelsea</label>
                                        <input type="checkbox" class="form-check-input" value="">
                                    </div>
                                    <div class="form-check">
                                        <label>West End</label>
                                        <input type="checkbox" class="form-check-input" value="">
                                    </div>
                                    <div class="form-check">
                                        <label>Hammersmith and Fulhama</label>
                                        <input type="checkbox" class="form-check-input" value="">
                                    </div>
                                    <div class="d-flex align-items-center justify-content-between">
                                        <a class="text_blue">
                                            Show 30 more
                                        </a>
                                    </div>
                                </form>
                            </div>
                        </div>

                        <div class="widget">
                            <h6 class="widget-title">Landmarks</h6>
                            <div class="filter-amenities scroll">
                                <form action="" method="post">
                                    <div class="form-check">
                                        <label>The British Museum</label>
                                        <input type="checkbox" class="form-check-input" value="">
                                    </div>
                                    <div class="form-check">
                                        <label>Tower of New York</label>
                                        <input type="checkbox" class="form-check-input" value="">
                                    </div>
                                    <div class="form-check">
                                        <label>Hyde Park</label>
                                        <input type="checkbox" class="form-check-input" value="">
                                    </div>
                                    <div class="form-check">
                                        <label>Buckingham Palace</label>
                                        <input type="checkbox" class="form-check-input" value="">
                                    </div>
                                    <div class="form-check">
                                        <label>New York Eye</label>
                                        <input type="checkbox" class="form-check-input" value="">
                                    </div>
                                </form>
                            </div>
                        </div>

                        <div class="widget">
                            <h6 class="widget-title">Number of rooms</h6>
                            <div class="filter-amenities scroll">
                                <form action="" method="post">
                                    <div class="form-check">
                                        <label>1 room</label>
                                        <input type="checkbox" class="form-check-input" value="">
                                    </div>
                                    <div class="form-check">
                                        <label>2 room</label>
                                        <input type="checkbox" class="form-check-input" value="">
                                    </div>
                                    <div class="form-check">
                                        <label>3 room</label>
                                        <input type="checkbox" class="form-check-input" value="">
                                    </div>
                                    <div class="form-check">
                                        <label>4 room</label>
                                        <input type="checkbox" class="form-check-input" value="">
                                    </div>
                                    <div class="form-check">
                                        <label>5 room</label>
                                        <input type="checkbox" class="form-check-input" value="">
                                    </div>
                                    <div class="form-check">
                                        <label>6 room</label>
                                        <input type="checkbox" class="form-check-input" value="">
                                    </div>
                                </form>
                            </div>
                        </div>

                        <div class="widget">
                            <h6 class="widget-title">Type of bed</h6>
                            <div class="filter-amenities scroll">
                                <form action="" method="post">
                                    <div class="form-check">
                                        <label>Double bed</label>
                                        <input type="checkbox" class="form-check-input" value="">
                                    </div>
                                    <div class="form-check">
                                        <label>Twin beds</label>
                                        <input type="checkbox" class="form-check-input" value="">
                                    </div>
                                </form>
                            </div>
                        </div>

                        <div class="widget">
                            <h6 class="widget-title">Brands</h6>
                            <div class="filter-amenities scroll">
                                <form action="" method="post">
                                    <div class="form-check">
                                        <label>Accor Hotels</label>
                                        <input type="checkbox" class="form-check-input" value="">
                                    </div>
                                    <div class="form-check">
                                        <label>lbis</label>
                                        <input type="checkbox" class="form-check-input" value="">
                                    </div>
                                    <div class="form-check">
                                        <label>Mercure</label>
                                        <input type="checkbox" class="form-check-input" value="">
                                    </div>
                                    <div class="form-check">
                                        <label>Ibis Styles</label>
                                        <input type="checkbox" class="form-check-input" value="">
                                    </div>
                                    <div class="form-check">
                                        <label>Fairmont Hotels &amp; Resorts</label>
                                        <input type="checkbox" class="form-check-input" value="">
                                    </div>
                                </form>
                            </div>
                        </div>

                        <div class="widget">
                            <h6 class="widget-title">Nearest metro station</h6>
                            <div class="filter-style">
                                <input type="text" class="form-control" value="Metro Station">
                            </div>
                        </div>

                    </div>
                </div>

            </div>
            <div class="col-lg-9">
                <div class="page-header d-flex align-items-center justify-content-between flex-wrap flex-md-nowrap gap-md-5">
                    <div class="gallery-five_title-box text-start w-100">
                        <h4 class="text-dark h3 mb-20 text-capitalize">{{region_city}}: {{ hotels.length}} accommodation options available</h4>
                    </div>
                </div>
                <div class="mixitup-gallery">

                    <div *ngFor="let hotel of hotels;let i = index" class="filter-list">

                        <div class="tour-block_one mix cloud seasonal shadow w-100">
                            <div class="tour-block_one-inner align-items-center row g-3">
                                <div class="col-lg-4">
                                    <div class="tour-block_one-image">
                                        <a href="hotel-detail.php" class="position-relative d-block">
                                            <img src="./assets/images/hotel/1.jpg" alt="" class="image">
                                            <img src="./assets/images/icons/5star.png" class="img-fluid star-rating" alt="...">
                                        </a>
                                    </div>
                                </div> 
                                <div class="col-lg-5 border-end"> 
                                    <div class="tour-block_one-content">
                                        <div class="tour-block_one-location"><i class="fa-solid fa-location-dot me-2" aria-hidden="true"></i>{{region_city}}</div>
                                        <h4 class="tour-block_one-heading">
                                            <a href="hotel-detail.php">{{ hotel.id}}</a>
                                        </h4>
                                        <p class="text-capitalize fw-bold">{{ hotel.rates[0].room_name }}</p>
                                        <ul class="hotel-amenities gap-2">
                                            <li>
                                                <img src="https://img.icons8.com/?size=100&amp;id=172&amp;format=png&amp;color=000000" class="img-fluid" alt="..." width="18px">
                                                <span class="">Wifi</span>
                                            </li>
                                            <li>
                                                <img src="https://img.icons8.com/?size=100&amp;id=10726&amp;format=png&amp;color=000000" class="img-fluid" alt="..." width="18px">
                                                <span class="">Parking</span>
                                            </li>
                                            <li>
                                                <img src="https://img.icons8.com/?size=100&amp;id=6899&amp;format=png&amp;color=000000" class="img-fluid" alt="..." width="18px">
                                                <span class="">Meal</span>
                                            </li>
                                            <li>
                                                <img src="https://img.icons8.com/?size=100&amp;id=11227&amp;format=png&amp;color=000000" class="img-fluid" alt="..." width="18px">
                                                <span class="">Support</span>
                                            </li>
                                        </ul>
                                        
                                        <ul class="hotel-amenities gap-2">
                                            <li>
                                                <i class="fa fa-times-circle-o" aria-hidden="true"></i>
                                                <span class="">Free Cancellation</span>
                                            </li>
                                            <li>
                                                <i class="fa fa-cutlery" aria-hidden="true"></i>
                                                <span class="">Nomeal</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="col-lg-3 text-center border-lg-start">
                                    <div class="tour-block_one-info">
                                        <ul class="tour-block_one-info_list">
                                            <li class="text-muted">
                                                For 29 nights, for 1 guests
                                            </li>
                                            <li  class="text-black fw-bold">GBP {{ hotel.rates[0].payment_options.payment_types[0].amount}}</li>
                                        </ul>
                                        <div class="tour-block_one-buttons">
                                            <a class="btn btn-info" href="/hotel-details?id={{ hotel.id}}&adults={{adults}}&children={{children}}&checkin_date={{checkin_date}}&checkout_date={{checkout_date}}&region_id={{ region_id}}&region_country={{region_country}}&region_city={{region_city}}&room={{room}}">
                                                Show All Rooms
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>



                </div>
            </div>
        </div>
    </div>
</section>
</main>