import { Component, OnInit } from '@angular/core';
import { FlightService } from './flight.service';
import { NgComponentOutlet } from '@angular/common';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { ApiResponse } from './api-response.interface';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms'; // Import FormsModule

interface OptionInfo {
  optionKey: string;
  totalFlightDuration: string;
}

interface Journey {
  group: number;
  outBoundKey?: string;
  inboundKey?: string;
  optionInfos: OptionInfo[];
}

interface Flight {
  key: string;
  totalPrice: number;
  basePrice: number;
  tax: number;
  journey: Journey[];
}


 
@Component({
  selector: 'app-flight',
  templateUrl: './flight.component.html',
  styleUrls: ['./flight.component.css'],
  standalone: true,
  imports:[NgComponentOutlet,CommonModule,FormsModule] 
})

export class FlightComponent implements OnInit {
  flights: any[] = [];
  flightsTp: any[] = [];
  flights_latest: any[] = [];
  result :any[]=[];
  filteredFlights: any[] = [];
  uniqueAirlines: any[] = [];
  uniqueDepartureAirport : any[] = [];
  uniqueArrivalAirport: any[] = [];
  

  flights_arr : any[] = [
    { "id": 0, "name": "Available" },
    { "id": 1, "name": "Ready" },
    { "id": 2, "name": "Started" }
];
//  flights!: any[];

subscription: Subscription | undefined;
apiResponse: ApiResponse | undefined;

public flightFrom="";
public flightTo=""; 
public airport_city1="";
public airport_city2="";
public journeyType="";
public flightName22: string = '';
minPrice: number=0;
maxPrice: number=100000;
layovertime: string='';
stops :number=-1;
baggage:number=-1;
airlines: string='';
departure:string='';
arrival:string='';

public f_departureDate ='';
public f_returnDate = '';
public f_adult = '';
public f_infant = '';
public f_child = '';
public f_youth = '';
public f_class =  '';




constructor(private flightService: FlightService,private route: ActivatedRoute) { }

lowestDurationFlight: Flight | undefined;

lowestDuration: string='';
totalprice_lowestDuration: string='';


  ngOnInit(): void {
    //this.fetchFlights();
    this.route.queryParams.subscribe(params => {
      this.flightFrom = params['flight_from'];
      this.flightTo = params['flight_to'];
      this.airport_city1 = params['airport_city1'];
      this.airport_city2 = params['airport_city2'];
      this.journeyType= params['journeyType'];
 

      this.f_departureDate= params['f_departureDate'];
      this.f_returnDate= params['f_returnDate'];
      this.f_adult= params['f_adult'];
      this.f_infant= params['f_infant'];
      this.f_child= params['f_child'];
      this.f_youth= params['f_youth'];
      this.f_class= params['f_class'];
      console.log('flight_from:', this.flightFrom);
      console.log('flight_to:', this.flightTo);
      // Now you have the value of flight_from, you can use it as needed
    

     this.flightService.getFlights(this.flightFrom,this.flightTo,this.journeyType, this.f_departureDate,this.f_returnDate,this.f_adult,this.f_infant,this.f_child,this.f_youth,this.f_class).subscribe({
      next: (flights) => {
     //   this.flights = flights; 

        this.flights =  Array.from(flights);

      //  console.log("Bright sun com Response : "+JSON.stringify(this.flights));

        this.flights_latest =  Array.from(flights);
      //  console.log("flight Details:"+flights);

 // Extract unique airline names
const uniqueAirlinesSet = new Set<string>(); // Using a Set to ensure uniqueness

//const uniqueDepartureAirportSet = new Set<string>(); // Using a Set to ensure uniqueness
const uniqueDepartureAirports: { airportName: string; airportCode: string }[] = [];

const uniqueArrivalAirports: { airportName: string; airportCode: string }[] = [];


this.flights.forEach((flight) => {
  for (const optionInfo of flight.journey[0].optionInfos) {

    uniqueAirlinesSet.add(optionInfo.airSegmentInfos[0].arlineName);
    //uniqueDepartureAirportSet.add(optionInfo.airSegmentInfos[0].originAirportName);

    const originAirportName = optionInfo.airSegmentInfos[0].originAirportName;
    const originAirportCode = optionInfo.airSegmentInfos[0].origin;
    uniqueDepartureAirports.push({ airportName: originAirportName, airportCode: originAirportCode });

    const destinationAirportName = optionInfo.airSegmentInfos[0].destinationAirportName;
    const destination = optionInfo.airSegmentInfos[0].destination;
    uniqueArrivalAirports.push({ airportName: destinationAirportName, airportCode: destination });
 
    
  }
});

// Convert Set to array
this.uniqueAirlines = Array.from(uniqueAirlinesSet);

const uniqueDepartureAirportsSet = new Set(uniqueDepartureAirports.map(airport => JSON.stringify(airport)));

this.uniqueDepartureAirport = Array.from(uniqueDepartureAirportsSet).map(airport => JSON.parse(airport));


const uniqueArrivalAirportsSet = new Set(uniqueArrivalAirports.map(airport => JSON.stringify(airport)));

this.uniqueArrivalAirport = Array.from(uniqueArrivalAirportsSet).map(airport => JSON.parse(airport));


       },
   
      error: (error) => {
        console.error('Error fetching flights:', error);
      },
      complete: () => {

        // this.flightService.getTravelPortFlights(this.flightFrom,this.flightTo,this.journeyType, this.f_departureDate,this.f_returnDate,this.f_adult,this.f_infant,this.f_child,this.f_youth,this.f_class).subscribe({
        //   next: (flightsTp) => {
             
        //   this.flightsTp =  flightsTp;
     
        //   }
        // });

      }
    });

    

  });
  this.findLowestDurationFlight();

  }
  varDump(flights: any): void {
    console.log(flights);
  }

  parseDuration(duration: string): number {
    const parts = duration.split(' ');
    let hours = 0;
    let minutes = 0;
    parts.forEach(part => {
      if (part.includes('hr')) {
        hours = parseInt(part.replace('hr', ''), 10);
      } else if (part.includes('min')) {
        minutes = parseInt(part.replace('min', ''), 10);
      }
    });
    return (hours * 60) + minutes;
  }

  findLowestDurationFlight() {
    let minDuration = Infinity;
    this.flights.forEach(flight => {
      let totalDuration = 0;
      let totalFlightDuration = '';
      let  totalprice= '';
      flight.journey.forEach((journey: Journey) => {
        journey.optionInfos.forEach((optionInfo: OptionInfo) => {
          totalDuration += this.parseDuration(optionInfo.totalFlightDuration);
          totalFlightDuration = optionInfo.totalFlightDuration;
          totalprice=flight.totalPrice;
        });
      });
      if (totalDuration < minDuration) {
        minDuration = totalDuration;
        this.lowestDurationFlight = flight;
        this.lowestDuration =totalFlightDuration;
        this.totalprice_lowestDuration =totalprice;
      }
    });
  }
   
  
  // Method to filter flights based on price range
  applyPriceFilter(): void {
    
    this.filteredFlights = this.flights_latest.filter(flight => {
      if (this.minPrice && this.maxPrice) {
        return flight.totalPrice >= this.minPrice && flight.totalPrice <= this.maxPrice;
      } else if (this.minPrice) {
        return flight.totalPrice >= this.minPrice;
      } else if (this.maxPrice) {
        return flight.totalPrice <= this.maxPrice;
      } else { 
        return true; // If both minPrice and maxPrice are not specified, return all flights
      }
    });
    this.flights=this.filteredFlights;
    console.log('minPrice:', this.minPrice);
    console.log('maxPrice:', this.maxPrice); 
    console.log('Filtered Flights:', this.filteredFlights);
  }

  async applyFilter(): Promise<void> {
    
    this.filteredFlights = await this.flights_latest.filter(flight => {

      const [hours, minutes] = flight.journey[0].optionInfos[0].totalFlightDuration.split(' ');
      const hoursValue = parseInt(hours.replace('hr', ''), 10);
      const minutesValue = parseInt(minutes.replace('min', ''), 10);
      const totalHours = hoursValue + (minutesValue / 60);
      console.log("totalFlightDuration : ",totalHours);
     // console.log("stops : ",this.stops);

      if (this.layovertime=='5') {
        return totalHours <= 5;
      } else if (this.layovertime=='10') {
        return totalHours > 5 && totalHours <= 10 ;
      } else if (this.layovertime=='15') {
        return  totalHours > 10 && totalHours <= 15 ;
      }else { 
        return totalHours > 15;
      }
      
     // this.varDump(flight.journey[0].optionInfos[0].totalFlightDuration);

     
      
      
    });
    this.flights=this.filteredFlights;
    console.log('minPrice:', this.minPrice);
    console.log('maxPrice:', this.maxPrice); 
    console.log("layovertime : ",this.layovertime);
    console.log('Filtered Flights:', this.filteredFlights);
    console.log("Stops : ",this.stops);

    
    
  }


  async applyStopFilter(): Promise<void> {
    
    this.filteredFlights = await this.flights_latest.filter(flight => {

      

      if(this.stops==0) {

        return flight.journey[0].stop==0;
      }else if(this.stops==1) {
      
        return flight.journey[0].stop==1;
      
      }else{

        return flight.journey[0].stop>=2;
      }
     
      
      
    });
    this.flights=this.filteredFlights;
    console.log('minPrice:', this.minPrice);
    console.log('maxPrice:', this.maxPrice); 
    console.log("layovertime : ",this.layovertime);
    console.log('Filtered Flights:', this.filteredFlights);
    console.log("Stops : ",this.stops);

    
    
  }

  async applyBaggageFilter(): Promise<void> {
    
    this.filteredFlights = await this.flights_latest.filter(flight => {

      for (const optionInfo of flight.journey[0].optionInfos) {
        console.log("allowance : ",optionInfo.airSegmentInfos[0].baggageInfo.allowance);

        // Check if baggage allowance matches
        if (this.baggage == 15 && optionInfo.airSegmentInfos[0].baggageInfo.allowance == "15 Kg") {
          return true;
        } else if (this.baggage == 20 && optionInfo.airSegmentInfos[0].baggageInfo.allowance == "20 Kg") {
          return true;
        } else if (this.baggage == 25 && optionInfo.airSegmentInfos[0].baggageInfo.allowance == "25 Kg") {
          return true;
        } else if (this.baggage == 30 && optionInfo.airSegmentInfos[0].baggageInfo.allowance == "30 Kg") {
          return true;
        } 
      }
      // If no match found, return false
      return false;
      
      
    });
    this.flights=this.filteredFlights;
    console.log('minPrice:', this.minPrice);
    console.log('maxPrice:', this.maxPrice); 
    console.log("layovertime : ",this.layovertime);
    console.log('Filtered Flights:', this.filteredFlights);
    console.log("Stops : ",this.stops);
    console.log("baggage : ",this.baggage);

    
    
  }

  async applyAirlinesFilter(): Promise<void> {
    
    this.filteredFlights = await this.flights_latest.filter(flight => {

      for (const optionInfo of flight.journey[0].optionInfos) {
        console.log("arlineName : ",optionInfo.airSegmentInfos[0].arlineName);

        // Check if baggage allowance matches
        if (this.airlines == optionInfo.airSegmentInfos[0].arlineName) {
          return true;
        }  
      }
      // If no match found, return false
      return false;
      
      
    });
    this.flights=this.filteredFlights;
    console.log('minPrice:', this.minPrice);
    console.log('maxPrice:', this.maxPrice); 
    console.log("layovertime : ",this.layovertime);
    console.log('Filtered Flights:', this.filteredFlights);
    console.log("Stops : ",this.stops);
    console.log("baggage : ",this.baggage);
    console.log("airlines : ",this.airlines);

    
    
  }

  async applyDepartureFilter(): Promise<void> {
    
    this.filteredFlights = await this.flights_latest.filter(flight => {

      for (const optionInfo of flight.journey[0].optionInfos) {
        console.log("arlineName : ",optionInfo.airSegmentInfos[0].origin);

        // Check if baggage allowance matches
        if (this.departure == optionInfo.airSegmentInfos[0].origin) {
          return true;
        }  
      }
      // If no match found, return false
      return false;
      
      
    });
    this.flights=this.filteredFlights;
    console.log('minPrice:', this.minPrice);
    console.log('maxPrice:', this.maxPrice); 
    console.log("layovertime : ",this.layovertime);
    console.log('Filtered Flights:', this.filteredFlights);
    console.log("Stops : ",this.stops);
    console.log("baggage : ",this.baggage);
    console.log("airlines : ",this.airlines);

    
    
  }

  async applyArrivalFilter(): Promise<void> {
    
    this.filteredFlights = await this.flights_latest.filter(flight => {

      for (const optionInfo of flight.journey[0].optionInfos) {
        console.log("arlineName : ",optionInfo.airSegmentInfos[0].origin);

        // Check if baggage allowance matches
        if (this.arrival == optionInfo.airSegmentInfos[0].destination) {
          return true;
        }  
      }
      // If no match found, return false
      return false;
      
      
    });
    this.flights=this.filteredFlights;
    console.log('minPrice:', this.minPrice);
    console.log('maxPrice:', this.maxPrice); 
    console.log("layovertime : ",this.layovertime);
    console.log('Filtered Flights:', this.filteredFlights);
    console.log("Stops : ",this.stops);
    console.log("baggage : ",this.baggage);
    console.log("airlines : ",this.airlines);

    
    
  }
     
}