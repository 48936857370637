import { Component, OnInit } from '@angular/core';
import { FlightService } from './flight.service';
import { NgComponentOutlet } from '@angular/common';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { ApiResponse } from './api-response.interface';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms'; // Import FormsModule
import { HttpClient, HttpParams,HttpHeaders } from '@angular/common/http';
import { Globals } from '../../globals'; 
import { Location } from '@angular/common';







@Component({
  selector: 'app-flight',
  templateUrl: './flight-details.component.html',
  styleUrls: ['./flight.component.css'],
  standalone: true,
  imports:[NgComponentOutlet,CommonModule,FormsModule] 
})

export class FlightDetailsComponent implements OnInit {
   
   
  //flightPrice: any[] = [];
  flightPrice:any= {};
  flights: any[] = [];
  public flight_from ='';
  public flight_to =  '';
  public airport_name1 =  '';
  public airport_name2 = '';
  public f_departureDate =  '';
  public f_returnDate =  '';
  public f_image = '';
  public totalPrice='';
  public f_adult='';
  public f_infant='';
  public f_child='';
  public jsonString ='';
  public journeyType='';




//  private apiUrl = 'http://localhost:3000/api/flightprice';

//private apiUrl = 'http://giatraveldev.sauravsadangi.in:3001/api/flightprice';

private apiUrl ='';

  constructor(private location: Location,private http: HttpClient,private route: ActivatedRoute,private globals: Globals,private flightService: FlightService) { 

    console.log("location :: "+this.location);
  }
  

  ngOnInit(): void {

    this.route.queryParams.subscribe(params_in => {

    const Key = params_in['Key'];
    this.journeyType =  params_in['journeyType'];
    this.f_adult =  params_in['f_adult'];
    this.f_infant =  params_in['f_infant'];
    this.f_child =  params_in['f_child'];
    const f_youth =  params_in['f_youth'];
    const token = params_in['token'];
    const supp = params_in['supp'];
    const optionKey1= params_in['optionKey1'];
    const optionKey2= params_in['optionKey2'];
    const outBoundKey= params_in['outBoundKey'];
    const inboundKey= params_in['inboundKey'];
    const OptionKeyList= params_in['OptionKeyList'];
    const f_class =  params_in['f_class'];
    this.flight_from =  params_in['flight_from'];
    this.flight_to =  params_in['flight_to'];
    this.airport_name1 =  params_in['airport_name1'];
    this.airport_name2 =  params_in['airport_name2'];
    this.f_departureDate =  params_in['f_departureDate'];
    this.f_returnDate =  params_in['f_returnDate'];
    this.f_image =  params_in['f_image'];
    this.totalPrice = params_in['totalPrice'];
    this.apiUrl = this.globals.APIURL+'/api/flightprice';
 
    
    

  let params = new HttpParams().set('Key', Key).set('optionKey1', optionKey1).set('optionKey2', optionKey2).set('outBoundKey', outBoundKey).set('inboundKey', inboundKey).set('OptionKeyList', OptionKeyList).set('supp', supp).set('journeyType', this.journeyType).set('f_adult', this.f_adult).set('f_infant', this.f_infant).set('f_child', this.f_child).set('f_youth', f_youth).set('token', token);

    this.http.get<any[]>(this.apiUrl, { params: params })
    .subscribe(
      response => {

     //   this.flightPrice =JSON.stringify(response, null, 2);
        // Store the response data in the component state
          this.flightPrice = response;

          this.flights =  Array.from(response);

       // this.flightPrice = Object.entries(response);
       //  this.flightPrice = Array.from(response);
      //  console.log("Price response : "+response);
     // this.flightPrice =JSON.parse(JSON.stringify(response, null, 2));
       //console.log("Price Details : "+JSON.stringify(response, null, 2));
       this.jsonString = JSON.stringify(this.flightPrice, null, 2);
      //  console.log("API Price Details : "+JSON.stringify(this.flightPrice)); 
     //this.flightPrice = JSON.stringify(response); 

      },
      error => {
        console.error('An error occurred:', error);
      }
    );

    
  });

 

  }
  goBack(): void {
    console.log("Clicked :: ");
    this.location.back();
  }
  

  ngOnInit_old2(): void {

    this.route.queryParams.subscribe(params_in => {

      const Key = params_in['Key'];
      const journeyType =  params_in['journeyType'];
      this.f_adult =  params_in['f_adult'];
      this.f_infant =  params_in['f_infant'];
      this.f_child =  params_in['f_child'];
      const f_youth =  params_in['f_youth'];
      const token = params_in['token'];
      const supp = params_in['supp'];
      const OptionKeyList= params_in['OptionKeyList'];
      const f_class =  params_in['f_class'];
      this.flight_from =  params_in['flight_from'];
      this.flight_to =  params_in['flight_to'];
      this.airport_name1 =  params_in['airport_name1'];
      this.airport_name2 =  params_in['airport_name2'];
      this.f_departureDate =  params_in['f_departureDate'];
      this.f_returnDate =  params_in['f_returnDate'];
      this.f_image =  params_in['f_image'];
      this.totalPrice = params_in['totalPrice'];
  
    const DATA = {
      // Add your request payload here
  "TripType":`${journeyType}`,
  "Key" :`${Key}`,
  "OutBoundKey":"outgal",
  "InboundKey":"ingal",
  "CompanyCode":"BS7494",
  "WebsiteName":"giatravel.co.uk",
  "ApplicationAccessMode":"TEST",
  "Token":`${token}`,
  "Supp":`${supp}`,
  "IsFlexibleDate": false,
  "OptionKeyList" :[`${OptionKeyList}`],
  "NoOfAdultPax":`${this.f_adult}`,
  "NoOfInfantPax":`${this.f_infant}`,
  "NoOfChildPax":`${this.f_child}`,
  "NoOfYouthPax":`${f_youth}`,
  "AccountCode":"BS7494"
  
   };

    //const URL = 'Your_API_URL_here';
const UserName = 'BS7494';
const Password = '#b7s494@giatravelTest';
//const DATA = 'Your_JSON_Data';

const authString = Buffer.from(`${UserName}:${Password}`).toString('base64');
//const authString = btoa(`${UserName}:${Password}`);
const headers = new HttpHeaders({
  'Authorization': `Basic ${authString}`,
        'Content-Type': 'application/json',
        'Content-Length': Buffer.byteLength(JSON.stringify(DATA))
});
    this.apiUrl = 'https://api.brightsun.co.uk/api/BSFlight/flightprice';
    this.http.post<any[]>(this.apiUrl, DATA,{ headers: headers }).subscribe(
      response => {
        this.flightPrice = response;
       // console.log("Flight Prices :", this.flightPrice); // Log the response
      },
      error => {
        console.error('Error fetching flight prices:', error);
      }
    );
    });    
  }    
  
  ngOnInit_service_old(): void {


    this.route.queryParams.subscribe(params_in => {

      const Key = params_in['Key'];
      const journeyType =  params_in['journeyType'];
      this.f_adult =  params_in['f_adult'];
      this.f_infant =  params_in['f_infant'];
      this.f_child =  params_in['f_child'];
      const f_youth =  params_in['f_youth'];
      const token = params_in['token'];
      const supp = params_in['supp'];
      const OptionKeyList= params_in['OptionKeyList'];
      const f_class =  params_in['f_class'];
      this.flight_from =  params_in['flight_from'];
      this.flight_to =  params_in['flight_to'];
      this.airport_name1 =  params_in['airport_name1'];
      this.airport_name2 =  params_in['airport_name2'];
      this.f_departureDate =  params_in['f_departureDate'];
      this.f_returnDate =  params_in['f_returnDate'];
      this.f_image =  params_in['f_image'];
      this.totalPrice = params_in['totalPrice'];

      this.flightService.getFlightPrice(journeyType,Key, token,supp,OptionKeyList,this.f_adult,this.f_infant,this.f_child,f_youth).subscribe({
        next: (response) => {
          //this.flightPrice =  Array.from(response);
          this.flightPrice = response;

          //console.log("Flight Prices Response:", this.flightPrice);
        }
      });

    });
  
  
  }
   
}