import { NgModule } from '@angular/core';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable ,of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { throwError } from 'rxjs';


@Injectable({
  providedIn: 'root' // Provide the service at the application level
})
export class HomeService {
  constructor(private http: HttpClient) { }
  // getAirports(): Observable<any[]> {

  //   console.log("Entered getAirports service");

  //   return this.http.get<any[]>('http://localhost:3000/api/airports');
  //  }


  getAirports(): Observable<any[]> {
    console.log("Entered getAirports service");
    return this.http.get<any[]>('http://localhost:88/sauravsadangi.com/api/airports.php').pipe(
      map(response => response.filter(name => name != null)), // Filter out null objects
      catchError(error => {
        console.error('Error fetching airports:', error);  
        return []; 
      })
    );
  }


  // getAirports(): Observable<any[]> {
  // //  console.log("Entered getAirports service"+this.http.get<any[]>('http://localhost:3000/api/airports'));
  //   return this.http.get<any[]>('http://localhost:3000/api/airports').pipe(
  //     map(response => response.filter(airport => airport != null)), // Filter out null objects
  //     catchError(error => {
  //       console.error('Error fetching airports:', error);
  //       return throwError('Something went wrong while fetching airports data.');
  //     })
  //   );
  // }


  // getAirports(): Observable<any[]> {
  //   return this.http.get<any[]>('http://localhost:3000/api/airports').pipe(
  //     map(response => response.map(airport => ({
  //       id: airport.id,
  //       iata_code: airport.iata_code,
  //       name: 'ahmedabad',
  //       city: airport.city,
  //       country: airport.country,
  //       in_operation: airport.in_operation
  //     }))),
  //     catchError(error => {
  //             console.error('Error fetching airports:', error);
  //             return [];
  //           })
  //   );
  // }
}
