<section class="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4 pt-0" style="background-image: url(assets/images/banner1.png);">
        <div class="container mt-5 mb-5">
          <div class="mt-5 row justify-content-center">
            <div class="col-xl-8 col-md-10 d-flex flex-column align-items-center justify-content-center">
              <div class="row">
                <div class="col-md-6 pe-0 d-flex flex-column align-items-center justify-content-center login_s_img rounded-start position-relative" style="background: url(assets/images/login_new.jpg) no-repeat; background-size: cover;">
                    <!-- <img src="images/login_new.jpg" alt="" class="rounded-start"> -->
                </div>
                <div class="col-md-6 d-flex ps-md-0 flex-column align-items-center justify-content-center">
                  <div class="card border-0 rounded-0 rounded-end">
                    <div class="card-body ps-4">
                        <div style="color: red;" *ngIf="responseMessage">
                            <p>{{ responseMessage }}</p>
                        </div>

                      <div class="pt-4 pb-2">
                        <h5 class="card-title text-center pb-0 ">Login to Your Account</h5>
                        <p class="text-center small">Enter your username &amp; password to login</p>
                      </div>

                      <form (ngSubmit)="postData()" class="row g-3 needs-validation login_form" novalidate="">

                        <div class="col-12">
                          <label for="yourUsername" class="form-label">Username</label>
                          <div class="input-group has-validation">
                            <span class="input-group-text" id="inputGroupPrepend"></span>
                            <input type="text" name="email" [(ngModel)]="email"  class="form-control" id="email" required="">
                            <div class="invalid-feedback">Please enter your username.</div>
                          </div>
                        </div>

                        <div class="col-12">
                          <label for="yourPassword" class="form-label">Password</label>
                          <input type="password" name="password" [(ngModel)]="password"  class="form-control" id="password" required="">
                          <div class="invalid-feedback">Please enter your password!</div>
                        </div>

                        <div class="col-12 d-flex justify-content-between align-items-center">
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox" name="remember" value="true" id="rememberMe">
                            <label class="form-check-label" for="rememberMe">Remember me</label>
                          </div>
                          <a href="#" class="forget_link pb-1 mb-1">Forget Password?</a>
                        </div>
                        <div class="col-12">
                          <div class="form-group text-center">
                              <!-- <button class="btn btn-info w-100" type="submit" fdprocessedid="94obgv">
                                  <div class="btn-wrap">
                                      <span class="text-two">Login</span>
                                  </div>
                              </button> -->
                              <button type="submit" class="btn btn-info w-100 submit_btn">Login</button>

                          </div>
                        </div>
                        <div class="col-12">
                          <p class="small mb-0">Don't have account? <a href="/signup">Create an account</a></p>
                        </div>
                      </form>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
</section>

 


<script>
    (function ($) {
        "use strict";

        jQuery(document).ready(function () {

            $('.flight-offer-slider').slick({
                arrows: true,
                dots: false,
                infinite: true,
                speed: 300,
                slidesToShow: 3,
                slidesToScroll: 1,
                prevArrow: '<a class="slick-prev"><i class="fas fa-arrow-left" alt="Arrow Icon"></i></a>',
                nextArrow: '<a class="slick-next"><i class="fas fa-arrow-right" alt="Arrow Icon"></i></a>',
                responsive: [{
                        breakpoint: 1200,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1,
                            arrows: false,
                            dots: true
                        }
                    },
                    {
                        breakpoint: 992,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1,
                            arrows: false,
                            dots: true
                        }
                    },
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1,
                            arrows: false,
                            dots: true
                        }
                    },
                    {
                        breakpoint: 575,
                        settings: {
                            arrows: false,
                            dots: true,
                            slidesToShow: 1,
                            slidesToScroll: 1
                        }
                    }
                ]
            });

            $('.hotel-offers-slider').slick({

                arrows: true,
                dots: false,
                infinite: true,
                speed: 300,
                slidesToShow: 3,
                slidesToScroll: 1,
                prevArrow: '<a class="slick-prev"><i class="fas fa-arrow-left" alt="Arrow Icon"></i></a>',
                nextArrow: '<a class="slick-next"><i class="fas fa-arrow-right" alt="Arrow Icon"></i></a>',
                responsive: [{
                        breakpoint: 1200,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1,
                            arrows: true,
                            dots: false,
                        }
                    },
                    {
                        breakpoint: 992,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1,
                            arrows: true,
                            dots: false,
                        }
                    },
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            arrows: true,
                            dots: false,
                        }
                    },
                    {
                        breakpoint: 575,
                        settings: {
                            arrows: true,
                            dots: false,
                            slidesToShow: 1,
                            slidesToScroll: 1
                        }
                    }
                ]
            });

            $('.hop-on-hop-off-slider').slick({
                arrows: true,
                dots: false,
                infinite: true,
                speed: 300,
                slidesToShow: 4,
                slidesToScroll: 1,
                prevArrow: '<a class="slick-prev"><i class="fas fa-arrow-left" alt="Arrow Icon"></i></a>',
                nextArrow: '<a class="slick-next"><i class="fas fa-arrow-right" alt="Arrow Icon"></i></a>',
                responsive: [{
                        breakpoint: 1200,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 1,
                            // arrows: false,
                            // dots: true
                        }
                    },
                    {
                        breakpoint: 992,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 1,
                            // arrows: false,
                            // dots: true
                        }
                    },
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1,
                            // arrows: false,
                            // dots: true
                        }
                    },
                    {
                        breakpoint: 575,
                        settings: {
                            // arrows: false,
                            // dots: true,
                            slidesToShow: 1,
                            slidesToScroll: 1
                        }
                    }
                ]
            });

            $('.singleslider').slick({
                arrows: true,
                dots: false,
                infinite: true,
                speed: 300,
                slidesToShow: 1,
                slidesToScroll: 1,
                prevArrow: '<a class="slick-prev"><i class="fas fa-arrow-left" alt="Arrow Icon"></i></a>',
                nextArrow: '<a class="slick-next"><i class="fas fa-arrow-right" alt="Arrow Icon"></i></a>',
                responsive: [{
                        breakpoint: 1200,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 1,
                            // arrows: false,
                            // dots: true
                        }
                    },
                    {
                        breakpoint: 992,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            // arrows: false,
                            // dots: true
                        }
                    },
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            // arrows: false,
                            // dots: true
                        }
                    },
                    {
                        breakpoint: 575,
                        settings: {
                            // arrows: false,
                            // dots: true,
                            slidesToShow: 1,
                            slidesToScroll: 1
                        }
                    }
                ]
            });

            $('.special').slick({
                arrows: true,
                dots: false,
                infinite: true,
                speed: 300,
                slidesToShow: 6,
                slidesToScroll: 1,
                prevArrow: '<a class="slick-prev"><i class="fas fa-arrow-left" alt="Arrow Icon"></i></a>',
                nextArrow: '<a class="slick-next"><i class="fas fa-arrow-right" alt="Arrow Icon"></i></a>',
                responsive: [{
                        breakpoint: 1200,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 1,
                            // arrows: false,
                            // dots: true
                        }
                    },
                    {
                        breakpoint: 992,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 1,
                            // arrows: false,
                            // dots: true
                        }
                    },
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1,
                            // arrows: false,
                            // dots: true
                        }
                    },
                    {
                        breakpoint: 575,
                        settings: {
                            // arrows: false,
                            // dots: true,
                            slidesToShow: 1,
                            slidesToScroll: 1
                        }
                    }
                ]
            });

            $('.flight-listing').slick({
                arrows: true,
                dots: false,
                infinite: true,
                speed: 300,
                slidesToShow: 8,
                slidesToScroll: 1,
                prevArrow: '<a class="slick-prev"><i class="fas fa-arrow-left" alt="Arrow Icon"></i></a>',
                nextArrow: '<a class="slick-next"><i class="fas fa-arrow-right" alt="Arrow Icon"></i></a>',
                responsive: [{
                        breakpoint: 1200,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 1,
                            // arrows: false,
                            // dots: true
                        }
                    },
                    {
                        breakpoint: 992,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 1,
                            // arrows: false,
                            // dots: true
                        }
                    },
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1,
                            // arrows: false,
                            // dots: true
                        }
                    },
                    {
                        breakpoint: 575,
                        settings: {
                            // arrows: false,
                            // dots: true,
                            slidesToShow: 1,
                            slidesToScroll: 1
                        }
                    }
                ]
            });


            $('.deals-slider').slick({
                margin: 10,
                arrows: true,
                dots: false,
                infinite: true,
                speed: 300,
                slidesToShow: 3,
                slidesToScroll: 1,
                prevArrow: '<a class="slick-prev"><i class="fas fa-arrow-left" alt="Arrow Icon"></i></a>',
                nextArrow: '<a class="slick-next"><i class="fas fa-arrow-right" alt="Arrow Icon"></i></a>',
                responsive: [{
                        breakpoint: 1200,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1,
                            arrows: true,
                            dots: false,
                        }
                    },
                    {
                        breakpoint: 992,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1,
                            arrows: true,
                            dots: false,
                        }
                    },
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1,
                            arrows: true,
                            dots: false,
                        }
                    },
                    {
                        breakpoint: 575,
                        settings: {
                            arrows: true,
                            dots: false,
                            slidesToShow: 1,
                            slidesToScroll: 1
                        }
                    }
                ]
            });

            $(".more-link-progressive").click(function () {
                var numClicks = $(this).attr("data-clicks") == null ? 0 : $(this).attr(
                    "data-clicks");
                if (numClicks <= 2) {
                    var curHeight = $(this).parent().height();
                    var PROGRESSIVE_HEIGHT = $(window).height() * 0.20;
                    $(this).parent().css("max-height", (curHeight + PROGRESSIVE_HEIGHT) + "px");
                    $(this).attr("data-clicks", 1 + numClicks);
                } else {
                    $(this).parent().css("max-height", "");
                    $(this).parent().removeClass("compact");
                    $(this).remove();
                }
                return false;
            });

        });

    })(jQuery);
</script>

<script type="text/javascript" src="https://cdn.jsdelivr.net/momentjs/latest/moment.min.js"></script>
<script type="text/javascript" src="https://cdn.jsdelivr.net/npm/daterangepicker/daterangepicker.min.js"></script>
<script src="https://cdnjs.cloudflare.com/ajax/libs/lightgallery/1.6.12/js/lightgallery-all.min.js"></script>
<script type="text/javascript">
    $(function () {

        $('input[name="datefilter"]').daterangepicker({
            autoUpdateInput: false,
            locale: {
                cancelLabel: 'Clear'
            }
        });

        $('input[name="datefilter"]').on('apply.daterangepicker', function (ev, picker) {
            $(this).val(picker.startDate.format('MM/DD/YYYY') + ' - ' + picker.endDate.format(
                'MM/DD/YYYY')).html($(this).html() + "<br> Rs 500");

        });

        $('input[name="datefilter"]').on('cancel.daterangepicker', function (ev, picker) {
            $(this).val('').html($(this).html() + "<br> Rs 500");

        });

    });
</script>

<script>
    $(function () {

        var $filter = $('.filter');
        var $tab = $('.filter a');
        var $offers = $('.boxGroup .box')



        $filter.on('click touch', '.all', function (e) {
            e.preventDefault();
            $tab.removeClass('current');
            $(this).addClass('current');

            $offers.hide();
            $offers.fadeIn(700);

        });


        $filter.on('click touch', '.one', function (e) {
            e.preventDefault();
            $tab.removeClass('current');
            $(this).addClass('current');

            $offers.show();
            $offers.fadeOut(400);
            $offers.filter('.one').fadeIn(400);

        });



        $filter.on('click touch', '.two', function (e) {
            e.preventDefault();
            $tab.removeClass('current');
            $(this).addClass('current');

            $offers.show();
            $offers.fadeOut(400);
            $offers.filter('.two').fadeIn(400);

        });



        $filter.on('click touch', '.three', function (e) {
            e.preventDefault();
            $tab.removeClass('current');
            $(this).addClass('current');

            $offers.show();
            $offers.fadeOut(400);
            $offers.filter('.three').fadeIn(400);

        });

        $filter.on('click touch', '.four', function (e) {
            e.preventDefault();
            $tab.removeClass('current');
            $(this).addClass('current');

            $offers.show();
            $offers.fadeOut(400);
            $offers.filter('.four').fadeIn(400);

        });


    });

    $(function () {

        var $filter = $('.hotel-offers-filter');
        var $tab = $('.hotel-offers-filter a');
        var $offers = $('.hotel-offers-boxGroup .box')



        $filter.on('click touch', '.hotel-offers-all', function (e) {
            e.preventDefault();
            $tab.removeClass('current');
            $(this).addClass('current');

            $offers.hide();
            $offers.fadeIn(700);

        });


        $filter.on('click touch', '.hotel-offers-one', function (e) {
            e.preventDefault();
            $tab.removeClass('current');
            $(this).addClass('current');

            $offers.show();
            $offers.fadeOut(400);
            $offers.filter('.hotel-offers-one').fadeIn(400);

        });



        $filter.on('click touch', '.hotel-offers-two', function (e) {
            e.preventDefault();
            $tab.removeClass('current');
            $(this).addClass('current');

            $offers.show();
            $offers.fadeOut(400);
            $offers.filter('.hotel-offers-two').fadeIn(400);

        });

        $('#lightgallery').lightGallery();


    });
</script>



 