<!-- <pre>{{ hotels | json }}</pre> -->
<div class="main-search-page position-relative hotel_detail_a" id="main_expand">
    <div class="container-fluid" style="max-width:1340px">
        <div class="main-sec p-0">
            <div class="content-sec section px-0 py-md-5 py-3">
                <div class="top_barM">
                    <div class="top_abarM d-flex">
                        <!-- <div class="mb-0 mr-2 breadCrums bg-white tab_hide rounded-3 p-3 py-2 filter_dtop chnage_date position-relative" data-toggle="modal" data-target="#chnage_dates" > 
                                        <p class="mb-0 f-14 text_blue">
                                            <svg class="zenregioninfo-lens" style="fill: #00bbe8;" height="14" width="14" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg"><path d="M11.65 14.08v6.07a1 1 0 1 1-2 0v-6.07a7 7 0 0 1 1-13.93 7 7 0 0 1 1 13.93zm-1-1.93a5 5 0 1 0 0-10 5 5 0 0 0 0 10z" transform="rotate(-45 10.65 10.65)"></path></svg>
                                            New search
                                        </p>
                                    </div> -->
                                    
                                    
                                    <!-- {{hotels|json}} -->
                            <!-- <p *ngIf="hotels.length >= 0"> hotel data:    {{hotels[0].address}} </p> 
                                 -->

                                 <div *ngIf="hotels && hotels.length > 0">
                                    Hotel Details::
                                    <div *ngFor="let hotel of hotels">
                                      <h1>{{ hotel.name }}</h1>
                                      <p>{{ hotel.address }}</p>
                                      <!-- Other properties -->
                                    </div>
                                  </div>

                                 <div *ngIf="hotels">

                                  


                                    
                                     
                                   
                                  </div>
                        <div class="bg-white rounded-3 p-2 px-3 ml-2 breadCrums">
                            <div class="filter-header rounded-3">
                                <div class="text-center">
                                    <span class="session-expiration w-100">
                                        <span class="last_page text_blue">
                                            Main page
                                        </span> &gt; <span class="this_pae">
                                            
                                            Hotels in {{ hotels.address }} </span>
                                        <span class="text-muted det_date position-relative ml-2">
                                            {{checkin_date  }}, {{checkout_date}}</span> &gt; <span class="this_pae">
                                            {{hotels.name}} </span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <script>
                    document.addEventListener('DOMContentLoaded', function () {
                        // var modalTrigger = document.getElementById('modalTrigger');
                        // var modal = new bootstrap.Modal(modalTrigger);
                        // modal.show();
                        $('.main_loader_a').show();
                        setTimeout(function () {
                            $('.main_loader_a').hide();
                        }, 4000);
                    });
                </script>
                <div class="modal main_loader_a" id="flightloader" tabindex="-1" aria-labelledby="exampleModalLabel"
                    aria-hidden="true" style="display: none;">
                    <div class="modal-dialog">
                        <div class="modal-content bg-transparent border-0">
                            <div class="loader-body w-100 float-start loader_body_main">
                                <div class="container">
                                    <div class="sliding-loader">
                                        <div>
                                            <div class="loader1"> <img src="/assets/images/loadingRsltPage.gif">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="content_result">
                    <div class="row gy-4 mt-xl-0">
                        <div class="col-xxl-12 col-lg-12 col-xl-12">
                            <div id="content-right" class="mw-100">
                                <div class="p-3 bg-white rounded-3 mb-2 d-flex justify-content-between">
                                    <div class="d-flex justify-content-between flex-wrap align-items-center w-100">
                                        <div class="add_favBox mr-3 d-none">
                                            <div class="addFav pa_p rounded-circle bg-light">
                                                <a href="#">
                                                    <img src="http://195.96.138.30/FE/public/images/add-heart.svg"
                                                        alt="" class="unsaved">
                                                    <i class="fa fa-heart" aria-hidden="true"></i>
                                                </a>
                                            </div>
                                        </div>
                                        <div class="hotel_det h-detail-a-otr pl-3 border-left1 flex-fill">
                                            <h4 class="">
                                                <b>{{ hotels.name }}</b>
                                            </h4>
                                            <div class="h-detail-a-area-star d-flex">
                                                <span class="star lh-1">
                                                    <img src="/assets/images/star.svg" alt="2">
                                                </span>
                                                <span class="star lh-1">
                                                    <img src="/assets/images/star.svg" alt="2">
                                                </span>
                                            </div>
                                            <span class="h-detail-a-area f-12 mb-0  hide_oe">
                                                <i class="fa fa-map-marker f-14" aria-hidden="true"></i>
                                                <span> <a href="#">{{ hotels.address }} </a></span>
                                                <span class=" det_date position-relative ml-2">
                                                    <a href="#"> Show on the map</a>
                                                </span>
                                                <span class=" det_date position-relative ml-2">
                                                    <a href="#"> What else is nearby? </a>
                                                </span>
                                            </span>
                                            <!-- <span class="d-block text-muted f-12 "><b>&hairsp;m </b>from the center
                                                            <span class="det_date position-relative ml-2">
                                                                <b>310m</b>from the London center
                                                            </span>
                                                        </span> -->
                                        </div>
                                        <a class="backButton " href="#">Back to Result Page
                                        </a>
                                    </div>
                                    <!-- <div class="right_det text-right">
                                                    <h5 class="d-block mb-0 f-16 mb-2">
                                                    from GBP 1,082
                                                    </h5>
                                                    <a href="#" class="mb-2 btn btn-md f-14 btn-default d-inline-block">
                                                        Show rooms 
                                                    </a>
                                                    <span class="f-12 d-block">
                                                        Get 13 <img src="http://195.96.138.30/FE/public/images/loyalty_tag.svg" alt="">
                                                    </span>
                                                </div> -->
                                    <!-- <div class="right_det_mob text-right">
                                                    <a href="#" class="mb-2 btn btn-lg btn-md f-16 btn-default d-inline-block">
                                                        from GBP 1,557
                                                    </a>
                                                    <span class="f-12 d-block">
                                                        Price for 29 nights
                                                    </span>
                                                </div> -->
                                </div>
                                <div class="images_typ">
                                    <div class="row gy-4">
                                        <div class="col-xl-8 col-lg-8 col-md-8">
                                            <div class="images_view mw-100">
                                                <div class="tabs_collapse">
                                                    <a href="javascript:void(0);" class="images_type active mr-4"
                                                        type="button" data-toggle="collapse" data-target=".image_type"
                                                        aria-expanded="false" aria-controls="imagetype1 imagetype2">
                                                        Photographs of the hotel
                                                        <span class="this_pae det_date position-relative ml-2">
                                                            {{hotels.images.length}} </span>
                                                    </a>
                                                    <a href="javascript:void(0);" class="images_type" type="button"
                                                        data-toggle="collapse" data-target=".image_type"
                                                        aria-expanded="false" aria-controls="imagetype2 imagetype1">
                                                        Guests' photos
                                                        <span class="this_pae det_date position-relative ml-2">
                                                            1
                                                        </span>
                                                    </a>
                                                </div>
                                                <div class="tab_content">
                                                    <div class="image_type w-100" id="imagetype1">
                                                        <ul class="hotel_imagesList gallery" >

                                                            <!-- <li *ngIf="hotels.images.length > 0" class="gallery-item hotel_imageItem hi_itemOne">
                                                                <a [href]="hotels.images[0].replace('{size}', 'x500')" class="popup-link">
                                                                  <img [src]="hotels.images[0].replace('{size}', 'x500')">
                                                                </a>
                                                              </li> -->

                                                              <!-- Other lis with two images each -->
                                                            <!-- <li *ngFor="let pair of hotels.images.slice(1) | chunk: 2" class="gallery-item hotel_imageItem">
                                                                <a *ngFor="let image of pair" [href]="image.replace('{size}', 'x500')" class="popup-link">
                                                                <img [src]="image.replace('{size}', 'x500')">
                                                                </a>
                                                            </li> -->
                                                            <li *ngFor="let chunk of imageChunks" class="gallery-item hotel_imageItem" [ngClass]="{'hi_itemOne': chunk.length === 1}">
                                                                <a *ngFor="let image of chunk" [href]="image.replace('{size}', 'x500')" class="popup-link">
                                                                  <img [src]="image.replace('{size}', 'x500')" alt="">
                                                                </a>
                                                              </li>

                                                           
                                                            <!-- <li class="gallery-item hotel_imageItem ">
                                                                <a href="https://cdn.worldota.net/t/x500/content/f3/da/f3da3183d6d5e92a7abcac01e9d808e2d4e44c0b.jpeg"
                                                                    class="popup-link">
                                                                    <img src="https://cdn.worldota.net/t/x500/content/f3/da/f3da3183d6d5e92a7abcac01e9d808e2d4e44c0b.jpeg"
                                                                        alt="">
                                                                </a>
                                                                <a href="https://cdn.worldota.net/t/x500/content/60/ec/60ec6cfc30184dfa729da40ba0a4f66fa4c276e2.jpeg"
                                                                    class="popup-link">
                                                                    <img src="https://cdn.worldota.net/t/x500/content/60/ec/60ec6cfc30184dfa729da40ba0a4f66fa4c276e2.jpeg"
                                                                        alt="">
                                                                </a>
                                                            </li>
                                                            <li class="gallery-item hotel_imageItem ">
                                                                <a href="https://cdn.worldota.net/t/x500/content/2f/aa/2faaf5e69ec6ebe0f0a8680c27de7d7f9cbc0edc.jpeg"
                                                                    class="popup-link">
                                                                    <img src="https://cdn.worldota.net/t/x500/content/2f/aa/2faaf5e69ec6ebe0f0a8680c27de7d7f9cbc0edc.jpeg"
                                                                        alt="">
                                                                </a>
                                                                <a href="https://cdn.worldota.net/t/x500/content/26/14/26146c670cd2c3495053bca0b7c32611f254afd9.jpeg"
                                                                    class="popup-link">
                                                                    <img src="https://cdn.worldota.net/t/x500/content/26/14/26146c670cd2c3495053bca0b7c32611f254afd9.jpeg"
                                                                        alt="">
                                                                </a>
                                                            </li>
                                                            <li class="gallery-item hotel_imageItem ">
                                                                <a href="https://cdn.worldota.net/t/x500/content/3a/8f/3a8fd366d973965d2ea95646cbbd6008e3ddb4df.jpeg"
                                                                    class="popup-link">
                                                                    <img src="https://cdn.worldota.net/t/x500/content/3a/8f/3a8fd366d973965d2ea95646cbbd6008e3ddb4df.jpeg"
                                                                        alt="">
                                                                </a>
                                                                <a href="https://cdn.worldota.net/t/x500/content/a7/ef/a7ef5bde720f28a4bb5a62573350af8134974af0.jpeg"
                                                                    class="popup-link">
                                                                    <img src="https://cdn.worldota.net/t/x500/content/a7/ef/a7ef5bde720f28a4bb5a62573350af8134974af0.jpeg"
                                                                        alt="">
                                                                </a>
                                                            </li> -->

                
       
                                                            <!-- <li class="gallery-item hotel_imageItem ">
                                                                <a href="https://cdn.worldota.net/t/x500/content/26/c8/26c8bff0357035cfd6071aa3b624690edb2f6073.jpeg"
                                                                    class="popup-link">
                                                                    <img src="https://cdn.worldota.net/t/x500/content/26/c8/26c8bff0357035cfd6071aa3b624690edb2f6073.jpeg"
                                                                        alt="">
                                                                </a>
                                                            </li> -->
                                                        </ul>
                                                    </div>
                                                    <!-- <script>
                                                                    $('.hotel_imagesList').slick({
                                                                        rows: 2,
                                                                        dots: false,
                                                                        arrows: true,
                                                                        infinite: true,
                                                                        speed: 300,
                                                                        slidesToShow: 4,
                                                                        slidesToScroll:1
                                                                    });
                                                                </script> -->
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-xl-4 col-lg-4 col-md-4">
                                            <div class="av-rrom-outer rounded-3 mb-3 p-3 mt-3">
                                                <h6 class=" lh-1">
                                                    <b>Available rooms</b>
                                                </h6>
                                                <p class="f-14 ">
                                                    For 29 nights, for 1 adults
                                                </p>
                                                <span class="filter-sec room_filter room_filter_a">
                                                    <div class="">
                                                        <span class="filter-body">
                                                            <div
                                                                class="d-flex justify-content-between align-items-center mb-3 d-none">
                                                                <span class="f-20 mb-0">
                                                                    Filters
                                                                </span>
                                                                <span
                                                                    class="fa fa-close close_h close_fr text-muted f-20"></span>
                                                            </div>
                                                            <div class="options_c gap-1">
                                                                <div
                                                                    class="price-range price-range-beds  res_dropdown w-100 rounded-3">
                                                                    <div class="head p-0 border-0 in">
                                                                        <span class="d-block f-12">
                                                                            Beds
                                                                        </span>
                                                                        <span
                                                                            class="txt-upper text-muted value_selected">All
                                                                            Options</span>
                                                                        <span class="arrow-up"></span>
                                                                    </div>
                                                                    <div class="bdy bg-white rounded-3 p-0 shadow"
                                                                        style="display: none;">
                                                                        <ul class="unstyled-list">
                                                                            <li class="bdy_item room_filter"
                                                                                data-name="any"
                                                                                data-value="All Options">
                                                                                <a href="javascript:void(0);"
                                                                                    class="bdy_link active d-flex justify-content-between align-items-center">
                                                                                    All Options
                                                                                    <i class="fa fa-check"
                                                                                        aria-hidden="true"></i>
                                                                                </a>
                                                                            </li>
                                                                            <li class="bdy_item room_filter"
                                                                                data-name="double"
                                                                                data-value="Double bed">
                                                                                <a href="javascript:void(0);"
                                                                                    class="bdy_link d-flex justify-content-between align-items-center">
                                                                                    Double bed
                                                                                    <i class="fa fa-check"
                                                                                        aria-hidden="true"></i>
                                                                                </a>
                                                                            </li>
                                                                            <li class="bdy_item room_filter"
                                                                                data-name="single"
                                                                                data-value="Separate beds">
                                                                                <a href="javascript:void(0);"
                                                                                    class="bdy_link d-flex justify-content-between align-items-center">
                                                                                    Separate beds
                                                                                    <i class="fa fa-check"
                                                                                        aria-hidden="true"></i>
                                                                                </a>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    class="price-range price-range-meals res_dropdown w-100 rounded-3">
                                                                    <div class="head p-0 border-0 in">
                                                                        <span class="d-block f-12">
                                                                            Meals
                                                                        </span>
                                                                        <span
                                                                            class="txt-upper text-muted value_selected">All
                                                                            Options</span>
                                                                        <span class="arrow-up"></span>
                                                                    </div>
                                                                    <div class="bdy bg-white rounded-3 p-3 shadow"
                                                                        style="display: none;">
                                                                        <ul class="unstyled-list">
                                                                            <li class="bdy_item ">
                                                                                <div class="form-check mb-2">
                                                                                    <input
                                                                                        class="form-check-input room_filter"
                                                                                        type="checkbox" name="meals[]"
                                                                                        value="nomeal">
                                                                                    <label
                                                                                        class="form-check-label d-flex"
                                                                                        for="gridCheck1">
                                                                                        <span class="">
                                                                                            no meal
                                                                                        </span>
                                                                                        <span
                                                                                            class=" text-muted f-14 ml-auto d-inline-block">
                                                                                            RO
                                                                                        </span>
                                                                                    </label>
                                                                                </div>
                                                                            </li>
                                                                            <li class="bdy_item">
                                                                                <div class="form-check mb-2">
                                                                                    <input
                                                                                        class="form-check-input room_filter"
                                                                                        type="checkbox" name="meals[]"
                                                                                        value="breakfast">
                                                                                    <label
                                                                                        class="form-check-label d-flex"
                                                                                        for="gridCheck2">
                                                                                        <span class="">
                                                                                            breakfast
                                                                                        </span>
                                                                                        <span
                                                                                            class=" text-muted f-14 ml-auto d-inline-block">
                                                                                            BB
                                                                                        </span>
                                                                                    </label>
                                                                                </div>
                                                                            </li>
                                                                            <li class="bdy_item">
                                                                                <div class="form-check mb-2">
                                                                                    <input
                                                                                        class="form-check-input room_filter"
                                                                                        type="checkbox" name="meals[]"
                                                                                        value="half-board">
                                                                                    <label
                                                                                        class="form-check-label d-flex"
                                                                                        for="gridCheck3">
                                                                                        <span class="">
                                                                                            breakfast + lunch or dinner
                                                                                        </span>
                                                                                        <span
                                                                                            class=" text-muted f-14 ml-auto d-inline-block">
                                                                                            HB
                                                                                        </span>
                                                                                    </label>
                                                                                </div>
                                                                            </li>
                                                                            <li class="bdy_item">
                                                                                <div class="form-check mb-2">
                                                                                    <input
                                                                                        class="form-check-input room_filter"
                                                                                        type="checkbox" name="meals[]"
                                                                                        value="full-board">
                                                                                    <label
                                                                                        class="form-check-label d-flex"
                                                                                        for="gridCheck4">
                                                                                        <span class="">
                                                                                            breakfast, lunch, and dinner
                                                                                        </span>
                                                                                        <span
                                                                                            class=" text-muted f-14 ml-auto d-inline-block">
                                                                                            FB
                                                                                        </span>
                                                                                    </label>
                                                                                </div>
                                                                            </li>
                                                                            <li class="bdy_item">
                                                                                <div class="form-check mb-2">
                                                                                    <input
                                                                                        class="form-check-input room_filter"
                                                                                        type="checkbox" name="meals[]"
                                                                                        value="AI">
                                                                                    <label
                                                                                        class="form-check-label d-flex"
                                                                                        for="gridCheck5">
                                                                                        <span class="">
                                                                                            all inclusive
                                                                                        </span>
                                                                                        <span
                                                                                            class=" text-muted f-14 ml-auto d-inline-block">
                                                                                            AI
                                                                                        </span>
                                                                                    </label>
                                                                                </div>
                                                                            </li>
                                                                            <!-- <a href="#" class="text_blue">
                                                                                                        Reset
                                                                                                    </a> -->
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    class="price-range price-range-policy res_dropdown w-100 rounded-3">
                                                                    <div class="head p-0 border-0 in">
                                                                        <span class="d-block f-12">
                                                                            Cancellation policy
                                                                        </span>
                                                                        <span
                                                                            class="txt-upper text-muted value_selected">All
                                                                            Options</span>
                                                                        <span class="arrow-up"></span>
                                                                    </div>
                                                                    <div class="bdy bg-white rounded-3 p-0 shadow"
                                                                        style="display: none;">
                                                                        <ul class="unstyled-list">
                                                                            <li class="bdy_item room_filter"
                                                                                data-name="any"
                                                                                data-value="All Options">
                                                                                <a href="javascript:void(0);"
                                                                                    class="bdy_link active d-flex justify-content-between align-items-center">
                                                                                    All options
                                                                                    <i class="fa fa-check"
                                                                                        aria-hidden="true"></i>
                                                                                </a>
                                                                            </li>
                                                                            <li class="bdy_item room_filter"
                                                                                data-name="any"
                                                                                data-value="With free cancellation">
                                                                                <a href="javascript:void(0);"
                                                                                    class="bdy_link d-flex justify-content-between align-items-center">
                                                                                    With free cancellation
                                                                                    <i class="fa fa-check"
                                                                                        aria-hidden="true"></i>
                                                                                </a>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </span>
                                                        <a href="javascript:void(0);"
                                                            class="reviews_btn mt-1 mb-0 btn btn-block filter_roomB d-none">
                                                            Close
                                                        </a>
                                                        <div
                                                            class="mt-2 clear-filter d-flex justify-content-between align-items-center flex-wrap mt-1">
                                                            <!-- <span class="f-14">
                                                                                    18 options of 18 match your filters
                                                                                </span> -->
                                                            <a href="javascript:void(0);" class="f-12 clearfilter">
                                                                Clear filters
                                                            </a></div><a href="javascript:void(0);"
                                                            class="f-12 clearfilter">
                                                        </a>
                                                    </div><a href="javascript:void(0);" class="f-12 clearfilter">
                                                    </a>
                                                </span><a href="javascript:void(0);" class="f-12 clearfilter">
                                                </a>
                                            </div><a href="javascript:void(0);" class="f-12 clearfilter">
                                            </a>
                                            <div id="results" class=" search-results resultFlights px-1 mw-100 "><a
                                                    href="javascript:void(0);" class="f-12 clearfilter">
                                                </a>
                                                <div
                                                    class="mb-3 bg-white rounded-3 result_hotel d-flex flex-column pb-3 px-3">
                                                    <a href="javascript:void(0);" class="f-12 clearfilter">
                                                    </a>
                                                    <div class="expended_1 position-relative border-bottom pb-1"><a
                                                            href="javascript:void(0);" class="f-12 clearfilter">
                                                        </a>
                                                        <div class="d-flex"><a href="javascript:void(0);"
                                                                class="f-12 clearfilter">
                                                                <div class="left_sec mt-2">
                                                                    <h6 class="mb-0 lh-1">
                                                                    </h6>
                                                                    <span class="f-12 mb-0 text_blue hide_oe">0
                                                                        reviews</span>
                                                                </div>
                                                            </a>
                                                            <div
                                                                class="right_sec ms-auto d-flex flex-wrap justify-content-end">
                                                                <a href="javascript:void(0);" class="f-12 clearfilter">
                                                                </a>
                                                                <div class="top_secR"><a href="javascript:void(0);"
                                                                        class="f-12 clearfilter">
                                                                    </a><a href="#" class="rating_tag">
                                                                        0 </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        class="slider_infor mx-4 mt-3 mb-0 slick-initialized slick-slider">
                                                        <div class="slick-list draggable">
                                                            <div class="slick-track"
                                                                style="opacity: 1; width: 0px; transform: translate3d(0px, 0px, 0px);">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <a href="#review_section"
                                                        class="mt-auto btn btn-block mt-2 reviews_btn">
                                                        Read all reviews
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="av-room-outer rounded-3 mb-3 p-3">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <div>
                                            <!-- <h6 class="mb-0 lh-1">
                                                            <b>Available rooms</b>
                                                        </h6>
                                                        <p class="f-14 mb-0">
                                                            For 29 nights, for 1 adults
                                                        </p> -->
                                        </div>
                                    </div>
                                    <a href="javascript:void(0);"
                                        class="mb-3 mt-2 reviews_btn btn btn-block filter_roomB open_fr d-none">
                                        <svg version="1.1" id="Layer_1" width="20px" height="20px"
                                            xmlns="http://www.w3.org/2000/svg"
                                            xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                            viewBox="0 0 122.23 122.88" style="enable-background:new 0 0 122.23 122.88"
                                            xml:space="preserve">
                                            <style type="text/css">
                                                .st0 {
                                                    fill-rule: evenodd;
                                                    clip-rule: evenodd;
                                                }
                                            </style>
                                            <g>
                                                <path class="st0"
                                                    d="M122.23,12.35v10.54c0,1.29-1.21,2.35-2.69,2.35H77.85c-2.84,5.92-8.89,10.01-15.9,10.01 c-7,0-13.05-4.09-15.89-10.01H2.69C1.22,25.24,0,24.18,0,22.89V12.35C0,11.06,1.21,10,2.69,10h43.37c2.84-5.92,8.89-10,15.89-10 c7,0,13.05,4.09,15.89,10h41.69C121.02,10,122.23,11.06,122.23,12.35L122.23,12.35L122.23,12.35z M49.57,112.88 c-2.84,5.92-8.89,10-15.9,10c-7,0-13.05-4.08-15.89-10l-15.09,0c-1.48,0-2.69-1.06-2.69-2.35V99.99c0-1.29,1.21-2.35,2.69-2.35 l15.09,0c2.84-5.92,8.89-10.01,15.89-10.01c7,0,13.05,4.09,15.89,10.01h69.97c1.48,0,2.69,1.06,2.69,2.35v10.54 c0,1.29-1.22,2.35-2.69,2.35L49.57,112.88L49.57,112.88z M104.12,69.73c-2.84,5.92-8.89,10.01-15.89,10.01 c-7,0-13.05-4.09-15.9-10.01H2.69C1.22,69.73,0,68.67,0,67.38V56.85c0-1.29,1.21-2.35,2.69-2.35h69.64c2.84-5.92,8.89-10,15.89-10 c7,0,13.05,4.09,15.89,10h15.42c1.48,0,2.69,1.06,2.69,2.35v10.53c0,1.29-1.21,2.35-2.69,2.35H104.12L104.12,69.73z">
                                                </path>
                                            </g>
                                        </svg>
                                        &nbsp; Filters
                                    </a>
                                    <!-- <a href="#" class="reviews_btn btn btn-block">
                                                    <i class="fa fa-refresh" aria-hidden="true"></i> &nbsp;
                                                    Reload rates
                                                </a>-->
                                    <div class="room-loader" style="text-align: center; display: none;"> <img
                                            src="/assets/images/loadingRsltPage.gif"></div>
                                    <div id="room-results" *ngIf="hotels.room_groups.length > 0"  >

                                        <div *ngFor="let room of hotels.room_groups; let m = index" class="border room-block{{m}} card_sec card_secRoom mt-4">
                                            <div class="crd_header">
                                                <div class="position-relative">
                                                    <a class="popup-link"
                                                        [href]="room?.images[0]?.replace('{size}', 'x500')">
                                                        
                                                        <img [src]="room?.images[0]?.replace('{size}', 'x500')" alt="">
                                                        
                                                        </a>


                                                    <span class="badge f-12 position-absolute end-0 bottom-0 mb-2">
                                                        {{room.images.length}} Photos
                                                    </span>
                                                </div>
                                                <div class="right_side d-flex flex-column">
                                                    <div class="top_content">
                                                        <h6 class="f-15 mb-1"><b> {{room.name_struct.main_name}} </b></h6>
                                                        <p> {{room?.name_struct?.bedding_type}}</p>
                                                    </div>
                                                    <div class="d-flex spesci mt-auto flex-wrap">
                                                        <div class="spesci_Item" *ngFor="let amenities of room.room_amenities" >
                                                            <img *ngIf="amenities=='air-conditioning'" src="/assets/images/tb_2.svg" alt="">
                                                            <img *ngIf="amenities=='desk'" src="/assets/images/tb_12.svg" alt="">
                                                            <img *ngIf="amenities=='iron'" src="/assets/images/tb_11.svg" alt="">
                                                            <img *ngIf="amenities=='mirror'" src="/assets/images/tb_5.svg" alt="">
                                                            <img *ngIf="amenities=='private-bathroom'" src="/assets/images/tb_5.svg" alt="">
                                                            <img *ngIf="amenities=='telephone'" src="/assets/images/tb_5.svg" alt="">
                                                            <img *ngIf="amenities=='safe'" src="/assets/images/tb_3.svg" alt="">
                                                            <img *ngIf="amenities=='shower'" src="/assets/images/tb_9.svg" alt="">
                                                            <img *ngIf="amenities=='toiletries'" src="/assets/images/tb_8.svg" alt="">
                                                            <img *ngIf="amenities=='towels'" src="/assets/images/tb_7.svg" alt="">
                                                            <img *ngIf="amenities=='tv'" src="/assets/images/tb_6.svg" alt="">
                                                            <img *ngIf="amenities=='wi-fi'"src="/assets/images/tb_4.svg" alt="">
                                                            <img *ngIf="amenities=='soundproofing'"src="/assets/images/tb_10.svg" alt="">
                                                            
                                                            {{amenities}}
                                                        
                                                        </div>

                                                        
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="table-responsive">
                                                <table class="table table-bordered mb-0">
                                                    <thead class="thead-dark border-white">
                                                        <tr class="room-t-fetch-th">
                                                            <th scope="col" class="f-12">Room</th>
                                                            <th scope="col" class="f-12">Meals</th>
                                                            <th scope="col" class="f-12" width="15%">Cancellation</th>
                                                            <th scope="col" class="f-12">Price</th>
                                                            <th scope="col" class="f-12"></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>

<!-- Room Array : {{roomsData | json}} -->

                                                    <ng-container *ngFor="let rate_data of roomsData.hotels[0].rates; let i = index"> 
                                                        <tr *ngIf="rate_data.room_data_trans.main_name==room.name_struct.main_name && rate_data.room_data_trans.bedding_type==room.name_struct.bedding_type">
                                                            <td>
                                                                <span class="">
                                                                    <i class="fa fa-bed f-20" aria-hidden="true"></i>
                                                                    <span class="f-10">{{rate_data.allotment }} room</span>
                                                                </span>
                                                                <p class="f-15 text-muted mb-0">
                                                                    {{rate_data.room_data_trans.main_room_type}} </p>
                                                                <p class="f-14 mb-1 lh-1 text-danger">
                                                                    bed type is subject to availability </p>
                                                                <p class="f-14 mb-0 lh-1">Not guaranteed</p>
                                                            </td>
                                                            <td class="align-middle">
                                                                <span class="text-success">
                                                                    {{rate_data.meal}}
                                                                    <span class="text-muted mytooltip mytooltip_cyb fav_tooltip ml-1">
                                                                        <i class="fa fa-info-circle f-14"
                                                                            aria-hidden="true"></i>
                                                                        <div
                                                                            class="mytooltiptext text-left bg-dark border-dark">
                                                                            <div class="row">
                                                                                <div class="col-12 text-left">
                                                                                    <small
                                                                                        class="txt-small text-white">No
                                                                                        meals included in room
                                                                                        rate.</small>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </span> <!--  -->
                                                                </span>
                                                            </td>
                                                            <td class="align-middle">
                                                                No <span
                                                                    class="text-muted mytooltip mytooltip_cyb fav_tooltip ml-1">
                                                                    <i class="fa fa-info-circle f-14"
                                                                        aria-hidden="true"></i>
                                                                    <div
                                                                        class="mytooltiptext text-left bg-dark border-dark">
                                                                        <div class="row">
                                                                            <div class="col-12 text-left">
                                                                                <small class="txt-small text-white">
                                                                                    <b> Cancellation policy</b><br>
                                                                                    *your local time (UTC +01:00)
                                                                                    type<br>
                                                                                </small>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </span>
                                                            </td>
                                                            <td class="align-middle">
                                                                <span class="f-16">
                                                                    GBP
                                                                    {{rate_data.payment_options.payment_types[0].amount}}

                                                                </span>
                                                                <span class="d-block f-14">
                                                                    No surcharge
                                                                </span>
                                                            </td>
                                                            <td class="text-center align-middle">
                                                                <a href="#"
                                                                    class="chb">
                                                                    Choose
                                                                </a>

                                                            </td>
                                                        </tr>

                                                        <!-- <tbody *ngIf="(rate_data.room_data_trans.main_name!=room.name_struct.main_name || rate_data.room_data_trans.bedding_type!=room.name_struct.bedding_type) && i==0">
                                                            <tr>
                                                                <td colspan="5"> <b>Fully Reserved !!!! Try To Choose any
                                                                        other bed Type </b> </td>
                                                            </tr>
                                                        </tbody> -->
                                                        

                                                    </ng-container>
                                                     
                                                    <script>
                                                        $('.room-block{{m}}').hide();
                                                    </script>
                                                    

                                                        
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>


                                        <!-- <div class="border room-block2 card_sec card_secRoom mt-4">
                                            <div class="crd_header">
                                                <div class="position-relative">
                                                    <a class="popup-link"
                                                        href="https://cdn.worldota.net/t/x100/content/a5/24/a5247a47e38b63696eb7d093b94c2449335a0c64.jpeg"><img
                                                            src="https://cdn.worldota.net/t/x100/content/a5/24/a5247a47e38b63696eb7d093b94c2449335a0c64.jpeg"
                                                            alt=""></a>
                                                    <span class="badge f-12 position-absolute end-0 bottom-0 mb-2">
                                                        7 Photos
                                                    </span>
                                                </div>
                                                <div class="right_side d-flex flex-column">
                                                    <div class="top_content">
                                                        <h6 class="f-15 mb-1"><b> Standard room </b></h6>
                                                    </div>
                                                    <div class="d-flex spesci mt-auto flex-wrap">
                                                        <div class="spesci_Item">
                                                            <img src="/assets/images/tb_2.svg" alt="">
                                                            Air conditioning </div>
                                                        <div class="spesci_Item">
                                                            Blackout blinds </div>
                                                        <div class="spesci_Item">
                                                            Coffee </div>
                                                        <div class="spesci_Item">
                                                            <img src="/assets/images/tb_12.svg" alt="">
                                                            Desk </div>
                                                        <div class="spesci_Item">
                                                            Fridge </div>
                                                        <div class="spesci_Item">
                                                            Hairdryer </div>
                                                        <div class="spesci_Item">
                                                            Heating </div>
                                                        <div class="spesci_Item">
                                                            <img src="/assets/images/tb_11.svg" alt="">
                                                            Iron </div>
                                                        <div class="spesci_Item">
                                                            Microwave </div>
                                                        <div class="spesci_Item">
                                                            Pets allowed </div>
                                                        <div class="spesci_Item">
                                                            <img src="/assets/images/tb_5.svg" alt="">
                                                            Private bathroom </div>
                                                        <div class="spesci_Item">
                                                            <img src="/assets/images/tb_3.svg" alt="">
                                                            Safe </div>
                                                        <div class="spesci_Item">
                                                            <img src="/assets/images/tb_9.svg" alt="">
                                                            Shower </div>
                                                        <div class="spesci_Item">
                                                            <img src="/assets/images/tb_5.svg" alt="">
                                                            Telephone </div>
                                                        <div class="spesci_Item">
                                                            <img src="/assets/images/tb_8.svg" alt="">
                                                            Toiletries </div>
                                                        <div class="spesci_Item">
                                                            <img src="/assets/images/tb_7.svg" alt="">
                                                            Towels </div>
                                                        <div class="spesci_Item">
                                                            <img src="/assets/images/tb_6.svg" alt="">
                                                            Tv </div>
                                                        <div class="spesci_Item">
                                                            <img src="/assets/images/tb_4.svg" alt="">
                                                            Wi fi </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="table-responsive">
                                                <table class="table table-bordered mb-0">
                                                    <thead class="thead-dark border-white">
                                                        <tr class="room-t-fetch-th">
                                                            <th scope="col" class="f-12">Room</th>
                                                            <th scope="col" class="f-12">Meals</th>
                                                            <th scope="col" class="f-12" width="15%">Cancellation</th>
                                                            <th scope="col" class="f-12">Price</th>
                                                            <th scope="col" class="f-12"></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td colspan="5"> <b>Fully Reserved !!!! Try To Choose any
                                                                    other bed Type </b> </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div> -->

                                         



                                        

                                    </div>
                                </div>
                                <div class="bg-white rounded-3 mb-3 mt-3 p-4">
                                    <h6 class="mb-3 lh-1">
                                        <b>Description of the hotel</b>
                                    </h6>
                                    <div class="row collapse_div-1 col_exp-1" *ngFor="let desc of hotels.description_struct">
                                        <div class="col-md-9">
                                            <!-- <p class="f-16 mb-2">
                                                <img src="/assets/images/inrooms.svg" alt="">&nbsp;
                                                Location
                                            </p>
                                            <p class="f-14 text-muted">

                                                {{hotels.address}}
                                            </p> -->
                                         
                                            
                                            <div *ngFor="let desc of hotels.description_struct">

                                            <p class="f-16 mb-2">
                                                <img src="/assets/images/inrooms.svg" alt="">&nbsp;
                                                {{ desc.title }}
                                            </p>
                                             
                                            <p class="f-14 text-muted" *ngFor="let paragraph of desc.paragraphs">
                                                          {{paragraph}}
                                            </p>

                                            </div>
                                            
                                            
                                            
                                             
                                        </div>
                                        <div class="col-md-3">
                                            <p class="mb-2">
                                                Facts about the hotel
                                            </p>
                                            <span class="f-10 text-muted">
                                                Socket type
                                            </span>
                                            <p class="mb-1">
                                                British
                                                <span class="mytooltip mytooltip_cyb fav_tooltip ml-1">
                                                    <i class="fa fa-info-circle f-14" aria-hidden="true"></i>
                                                    <!-- <div class="mytooltiptext text-left bg-dark border-dark">
                                                                    <div class="row">
                                                                        <div class="col-12 text-left">
                                                                            <small class="txt-small text-white ">No meals included in room rate.</small>
                                                                        </div>
                                                                    </div>
                                                                </div> -->
                                                </span>
                                                <span class="d-block">
                                                    230 V / 50 Hz
                                                </span>
                                            </p>
                                            <span class="f-10 text-muted">
                                                Rooms and floors number
                                            </span>
                                            <p class="mb-2">
                                                297 rooms • 8 floors
                                            </p>
                                            <span class="f-10 text-muted">
                                                Internet
                                            </span>
                                            <p class="mb-2">
                                                Wi-Fi in rooms
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="bg-white rounded-3 mb-3 mt-3 p-4">
                                    <h6 class="mb-3 lh-1">
                                        <b>Location</b>
                                    </h6>
                                    <iframe class=""
                                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d462560.68281993904!2d54.897823313520995!3d25.076280448422047!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f43496ad9c645%3A0xbde66e5084295162!2sDubai%20-%20United%20Arab%20Emirates!5e0!3m2!1sen!2sin!4v1717688230244!5m2!1sen!2sin"
                                        width="100%" height="600" style="border:0;" allowfullscreen="" loading="lazy"
                                        referrerpolicy="no-referrer-when-downgrade"></iframe>
                                </div>
                                <div class="bg-white rounded-3 mb-3 mt-3 p-4">
                                    <h6 class="mb-3 lh-1">
                                        <b>What other amenities are there?</b>
                                    </h6>
                                    <div class="nearByPlaces">
                                        <div class="filter-sec">
                                            <div class="filter-body">
                                                <div class="amenities_box">

                                                    <ng-container *ngFor="let amenity_detail of hotels.amenity_groups; let i = index"> 
  
                                                    <div class="aminities_list">
                                                        <div class="price-range nearByP w-100 rounded-3">
                                                            <div class="head p-0 border-0">
                                                                
                                                                
                                                                <img *ngIf="amenity_detail.group_name=='General'" src="/assets/images/common-info.svg" alt="">
                                                                <img *ngIf="amenity_detail.group_name=='Rooms'" src="/assets/images/inrooms.svg" alt="">
                                                                <img *ngIf="amenity_detail.group_name=='Accessibility'" src="/assets/images/disabled-support.svg" alt="">
                                                                <img *ngIf="amenity_detail.group_name=='Services and amenities'" src="/assets/images/extra-services.svg" alt="">
                                                                <img *ngIf="amenity_detail.group_name=='Meals'" src="/assets/images/meal.svg" alt="">
                                                                <img *ngIf="amenity_detail.group_name=='Internet'" src="/assets/images/internet-w.svg" alt="">
                                                                <img *ngIf="amenity_detail.group_name=='Languages Spoken'" src="/assets/images/languages.svg" alt="">
                                                                <img *ngIf="amenity_detail.group_name=='Recreation'" src="/assets/images/entertainment.svg" alt="">
                                                                <img *ngIf="amenity_detail.group_name=='Parking'" src="/assets/images/common-info.svg" alt="">
                                                                <img *ngIf="amenity_detail.group_name=='Pets'" src="/assets/images/pets.svg" alt="">
                                                                <img *ngIf="amenity_detail.group_name=='Health and Safety Measures'" src="/assets/images/anticovid.svg" alt="">
                                                                <img *ngIf="amenity_detail.group_name=='Sports'" src="/assets/images/fitness.svg" alt="">
                                                                <img *ngIf="amenity_detail.group_name=='Business'" src="/assets/images/busyness.svg" alt="">
                                                                <img *ngIf="amenity_detail.group_name=='Pool and beach'" src="/assets/images/pool.svg" alt="">
                                                                <img *ngIf="amenity_detail.group_name=='Beauty and wellness'" src="/assets/images/barber-shop.svg" alt="">
                                                                <img *ngIf="amenity_detail.group_name=='Tourist services'" src="/assets/images/tours.svg" alt="">
                                                                <img *ngIf="amenity_detail.group_name=='Transfer'" src="/assets/images/shuttle.svg" alt="">
             



                                                                <span class="am_name"> {{ amenity_detail.group_name }}</span>
                                                                <span class="arrow-up"></span>
                                                            </div>
                                                            <div class="bdy bdy_near" style="display: none;">
                                                                <ul class="unstyled-list amenities_inner">

                                                                    <li *ngFor="let amenitie of amenity_detail.amenities; let a = index" class="bdy_item amenities_listItem">
                                                                        {{amenitie}} </li>

                                                                   
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    </ng-container>

                                                   

                                                     
                                                   
                                                    <!-- <div class="aminities_list">
                                                        <div class="price-range nearByP w-100 rounded-3">
                                                            <div class="head p-0 border-0">
                                                                <img src="/assets/images/anticovid.svg" alt="">
                                                                <span class="am_name"> Health and Safety Measures</span>
                                                                <span class="arrow-up"></span>
                                                            </div>
                                                            <div class="bdy bdy_near" style="display: none;">
                                                                <ul class="unstyled-list amenities_inner">
                                                                    <li class="bdy_item amenities_listItem">
                                                                        Personal protection equipment for staff </li>
                                                                    <li class="bdy_item amenities_listItem">
                                                                        Extra decontamination measures </li>
                                                                    <li class="bdy_item amenities_listItem">
                                                                        Contactless check-in and/or check-out </li>
                                                                    <li class="bdy_item amenities_listItem">
                                                                        Additional measures against COVID-19 </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div> -->
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="bg-white rounded-3 mb-3 mt-3 p-3">
                                    <h6 class="mb- lh-1 ml-2 mb-3">
                                        <b>Policies</b>
                                    </h6>
                                    <div class="card">
                                        <div class="card-header border-bottom-0 mb-3 py-3" style="background: #e5e5e5;">
                                            <p class="mb-0"><b>Check-in terms and conditions</b></p>
                                        </div>
                                        <div class="card-body p-3">
                                            <p class="mb-0 f-14"><b>
                                                    <img src="/assets/images/checkin.svg" alt="">
                                                    Check-in and check-out</b></p>
                                            <div class="d-flex checkIn">
                                                <div class="align-middle">
                                                    <p class="mb-0 text-muted">Check-in</p>
                                                    <p class="mb-0">After {{hotels.check_in_time}}</p>
                                                </div>
                                                <div class="PolicyBlock">
                                                    <div class="PolicyBlockBar">
                                                        <div class="PolicyBlock__timeRangeBarFiller--1MlWH"
                                                            style="width: 37.5%;left: 62.5%;">
                                                        </div>
                                                    </div>
                                                    <div class="PolicyBlock__timeRangeTime--hF2hv" style="left: 62.5%;">
                                                        {{hotels.check_in_time}}</div>
                                                    <div class="PolicyBlock__timeRangeTime--hF2hv" style="left: 100%;">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="d-flex checkIn">
                                                <div class="align-middle">
                                                    <p class="mb-0 text-muted">Check-out</p>
                                                    <p class="mb-0">Until {{hotels.check_out_time}}</p>
                                                </div>
                                                <div class="PolicyBlock">
                                                    <div class="PolicyBlockBar">
                                                        <div class="PolicyBlock__timeRangeBarFiller--1MlWH"
                                                            style="width: 45.8333%; left: 0%;">
                                                        </div>
                                                    </div>
                                                    <div class="PolicyBlock__timeRangeTime--hF2hv" style="left: 0%;">
                                                    </div>
                                                    <div class="PolicyBlock__timeRangeTime--hF2hv" style="left: 45.83%">
                                                        {{hotels.check_out_time}} </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card mt-3">
                                        <div class="card-header border-bottom-0 py-3" style="background: #e5e5e5;">
                                            <p class="mb-0"><b>Hotel important information</b></p>
                                        </div>
                                        <div class="card-body p-3"  >
                                            <ul class="">
                                                <li>
                                                    <p class="mb-0"><b>
                                                            Policy Extra Info
                                                        </b></p>
                                                    <p [innerHTML]="hotels.metapolicy_extra_info" >
                                                         
                                                    </p>
                                                     
                                                    <p></p>
                                                </li>
                                                <li>
                                                    <p class="mb-0"><b>
                                                            Chargeable Services
                                                        </b></p>

                                                        <div *ngFor="let key of getKeys(hotels.metapolicy_struct)">
                                                            <div *ngIf="hotels.metapolicy_struct[key].length > 0">
                                                               
                                                              <b><u>{{ key }}:</u></b>
                                                              <div *ngFor="let item of hotels.metapolicy_struct[key]">
                                                                <div *ngIf="item.price">
                                                                  <br> Price: {{ item.currency }} {{ item.price }}
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>


                                                    <!-- <p *ngFor="let value of hotels.metapolicy_struct ; let c=index;">   
                                                    <br><br><b><u>Children:</u> </b>
                                                    <br> Price: 0
                                                    <br><br><b><u>No show:</u> </b>
                                                    <br><br><b><u>Visa:</u> </b>
                                                    </p> -->
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="card mt-3">
                                        <div class="card-header border-bottom-0 py-3" style="background: #e5e5e5;">
                                            <p class="mb-0"><b>Additional information</b></p>
                                        </div>
                                        <div class="card-body p-3">
                                            <ul class="">

                                                <li *ngFor="let description_detail of hotels.description_struct; let d = index">
                                                    <p class="mb-0"><b>
                                                            {{ description_detail.title }}</b></p>
                                                    <p>
                                                        {{ description_detail.paragraphs[0] }}
                                                    </p>
                                                </li>

                                                <li>
                                                    <p class="mb-0"><b>
                                                            At the hotel </b></p>
                                                    <p>
                                                        You can stop by the bar. It’s time to have a nice meal! Stop by
                                                        the restaurant. Want to be always on-line? Wi-Fi is available.
                                                        If you travel by car, you can park in a parking zone for free.
                                                    </p>
                                                </li>
                                                <li>
                                                    <p class="mb-0"><b>
                                                            Room amenities </b></p>
                                                    <p>
                                                        In the room, there is an alarm clock and a TV. The room
                                                        equipment depends on its category.
                                                    </p>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="bg-white rounded-3 mb-3 mt-3">
                                    <div class="header_ratings border-bottom d-flex">
                                        <div class="badge_green">
                                            <div class="top_secR">
                                                <a href="#" class="rating_tag">
                                                    9
                                                </a>
                                            </div>
                                            <div class="rigth_contentR">
                                                <p class="f-16 mb-1 lh-1"><b>Super</b></p>
                                                <p class="mb-0 text-muted f-12 lh-1">
                                                    Based on 129 guests' reviews
                                                </p>
                                            </div>
                                        </div>
                                        <div class="rating_BarList">
                                            <div class="ratingList_item">
                                                <div class="gray_bg ">
                                                </div>
                                                <div class="green_bg" style="width:85%;">
                                                </div>
                                                <span class="f-10">Cleanliness</span>
                                                <span class="f-10">8.5</span>
                                            </div>
                                            <div class="ratingList_item">
                                                <div class="gray_bg ">
                                                </div>
                                                <div class="green_bg" style="width:0%;">
                                                </div>
                                                <span class="f-10">Hygiene products</span>
                                            </div>
                                            <div class="ratingList_item">
                                                <div class="gray_bg ">
                                                </div>
                                                <div class="green_bg" style="width:88%;">
                                                </div>
                                                <span class="f-10">Location</span>
                                                <span class="f-10">8.8</span>
                                            </div>
                                            <div class="ratingList_item">
                                                <div class="gray_bg ">
                                                </div>
                                                <div class="green_bg" style="width:80%;">
                                                </div>
                                                <span class="f-10">Meals</span>
                                                <span class="f-10">8</span>
                                            </div>
                                            <div class="ratingList_item">
                                                <div class="gray_bg ">
                                                </div>
                                                <div class="green_bg" style="width:86%;">
                                                </div>
                                                <span class="f-10">Value for money</span>
                                                <span class="f-10">8.6</span>
                                            </div>
                                            <div class="ratingList_item">
                                                <div class="gray_bg ">
                                                </div>
                                                <div class="green_bg" style="width:80%;">
                                                </div>
                                                <span class="f-10">Room</span>
                                                <span class="f-10">8</span>
                                            </div>
                                            <div class="ratingList_item">
                                                <div class="gray_bg ">
                                                </div>
                                                <div class="green_bg" style="width:80%;">
                                                </div>
                                                <span class="f-10">Service</span>
                                                <span class="f-10">8</span>
                                            </div>
                                            <div class="ratingList_item">
                                                <div class="gray_bg ">
                                                </div>
                                                <div class="green_bg" style="width:0%;">
                                                </div>
                                                <span class="f-10">Wifi quantity</span>
                                            </div>
                                        </div>
                                        <div class="hide_mob">
                                            <div class="text-left hide_oe border-left pl-3 pt-1">
                                                <p class="mb-1 lh-1 f-16">
                                                    <b>TripAdvisor</b>
                                                </p>
                                                <a href="#" class="ta_logo d-inline-block">
                                                    <img src="http://195.96.138.30/FE/public/images/LogoTA_40.svg"
                                                        alt="" class="d-block">
                                                </a>
                                                <span class="d-block f-12 lh-1">
                                                    <a href="#">0 reviews </a>
                                                </span>
                                            </div>
                                        </div>
                                        <div class="see_review">
                                            <a href="#" class="btn btn-default yellow-btn btn-block">
                                                Read reviews (129)
                                            </a>
                                        </div>
                                    </div>
                                    <div class="rating_bodyR" id="review_section">
                                        <div class="rating_bodyR_inner">
                                            <div class="">
                                                <div class="d-flex justify-content-between align-items-center mb-md-3">
                                                    <span class="f-24 mb-0">
                                                        <b>Reviews</b>
                                                    </span>
                                                    <!-- <span class="fa fa-close close_h close_fr text-muted f-20"></span> -->
                                                </div>
                                                <span class="text-muted f-16 mb-md-0 mb-3 d-inline-block">
                                                    {{hotel_id}} </span>
                                            </div>
                                            <div class="review_listV">
                                                <span class="filter-sec mb-3">
                                                    <span class="filter-body">
                                                        <div class="price-range res_dropdown rounded-3">
                                                            <div class="head p-0 border-0 in">
                                                                <span class="d-block f-12">
                                                                    Sort reviews
                                                                </span>
                                                                <span class="txt-upper">by creation date</span>
                                                                <span class="arrow-up"></span>
                                                            </div>
                                                            <div class="bdy bg-white rounded-3 p-0 shadow"
                                                                style="display: none;">
                                                                <ul class="unstyled-list">
                                                                    <li class="bdy_item ">
                                                                        <a href="#"
                                                                            class="bdy_link active d-flex justify-content-between align-items-center">
                                                                            by creation date
                                                                            <i class="fa fa-check"
                                                                                aria-hidden="true"></i>
                                                                        </a>
                                                                    </li>
                                                                    <li class="bdy_item">
                                                                        <a href="#"
                                                                            class="bdy_link d-flex justify-content-between align-items-center">
                                                                            high rating first
                                                                            <i class="fa fa-check"
                                                                                aria-hidden="true"></i>
                                                                        </a>
                                                                    </li>
                                                                    <li class="bdy_item">
                                                                        <a href="#"
                                                                            class="bdy_link d-flex justify-content-between align-items-center">
                                                                            low rating first
                                                                            <i class="fa fa-check"
                                                                                aria-hidden="true"></i>
                                                                        </a>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </span>
                                                </span>
                                                <div class="list_rvSec">
                                                </div>
                                                <!-- <a href="#" class="btn btn-block reviews_btn my-3 mb-5">
                                                                Next Page
                                                            </a> -->
                                            </div>
                                            <div class="fixed_btn">
                                                <a href="javascript:void(0);" class="btn btn-default btn-md btn-block">
                                                    Close
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xxl-12 col-lg-12 col-xl-12">
                            <span class="">
                                You've recently viewed
                                <div class="recent_viewList mt-2 ">
                                    <div class="row gy-4">
                                        <div class="col-lg-4 col-md-6">
                                            <div class="recent_viewItem bg-white rounded-3 shadow-sm ">
                                                <a href="#">
                                                </a>
                                                <div class="d-flex item_imgContent"><a href="#">
                                                        <img class="rounded-start" src="/assets/images/details_side1.jpeg">
                                                    </a>
                                                    <div class="right_c p-2 pt-1 w-100 d-flex flex-column"><a href="#">
                                                            <div class="top_c">
                                                                <div class="d-flex align-items-center">
                                                                    <span class="star lh-1">
                                                                        <img src="/assets/images/star.svg" alt="2">
                                                                    </span>
                                                                    <span class="star lh-1">
                                                                        <img src="/assets/images/star.svg" alt="2">
                                                                    </span>
                                                                </div>
                                                                <p class="f-12 lh-1 my-2">
                                                                    {{hotel_id}}
                                                                 </p>
                                                                <p class="mb-0 f-10 lh-1 text-muted">
                                                                    <span class="">
                                                                        3/21/24
                                                                    </span>
                                                                    -
                                                                    <span class="">
                                                                        3/30/24
                                                                    </span>
                                                                    <span class="">
                                                                        2 guests
                                                                    </span>
                                                                </p>
                                                            </div>
                                                        </a>
                                                        <div class="bottom_c mt-auto text-right pr-3"><a href="#">
                                                            </a><a href="#" class="text_blue f-13">
                                                                GBP 875
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-6">
                                            <div class="recent_viewItem bg-white rounded-3 shadow-sm">
                                                <a href="#">
                                                </a>
                                                <div class="d-flex item_imgContent"><a href="#">
                                                        <img class="rounded-start" src="/assets/images/details_side1.jpeg">
                                                    </a>
                                                    <div class="right_c p-2 pt-1 w-100 d-flex flex-column"><a href="#">
                                                            <div class="top_c">
                                                                <div class="d-flex align-items-center">
                                                                    <span class="star lh-1">
                                                                        <img src="/assets/images/star.svg" alt="">
                                                                    </span>
                                                                    <span class="star lh-1">
                                                                        <img src="/assets/images/star.svg" alt="">
                                                                    </span>
                                                                    <span class="star lh-1">
                                                                        <img src="/assets/images/star.svg" alt="">
                                                                    </span>
                                                                </div>
                                                                <p class="f-12 lh-1 my-2">
                                                                    {{hotel_id}} </p>
                                                                <p class="mb-0 f-10 lh-1 text-muted">
                                                                    <span class="">
                                                                        3/21/24
                                                                    </span>
                                                                    -
                                                                    <span class="">
                                                                        3/30/24
                                                                    </span>
                                                                    <span class="">
                                                                        2 guests
                                                                    </span>
                                                                </p>
                                                            </div>
                                                        </a>
                                                        <div class="bottom_c mt-auto text-right pr-3"><a href="#">
                                                            </a><a href="#" class="text_blue f-13">
                                                                GBP 875
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-6">
                                            <div class="recent_viewItem bg-white rounded-3 shadow-sm">
                                                <a href="#">
                                                </a>
                                                <div class="d-flex item_imgContent"><a href="#">
                                                        <img class="rounded-start" src="/assets/images/details_side1.jpeg">
                                                    </a>
                                                    <div class="right_c p-2 pt-1 w-100 d-flex flex-column"><a href="#">
                                                            <div class="top_c">
                                                                <div class="d-flex align-items-center">
                                                                    <span class="star lh-1">
                                                                        <img src="/assets/images/star.svg" alt="">
                                                                    </span>
                                                                    <span class="star lh-1">
                                                                        <img src="/assets/images/star.svg" alt="">
                                                                    </span>
                                                                    <span class="star lh-1">
                                                                        <img src="/assets/images/star.svg" alt="">
                                                                    </span>
                                                                </div>
                                                                <p class="f-12 lh-1 my-2">
                                                                    {{hotel_id}} </p>
                                                                <p class="mb-0 f-10 lh-1 text-muted">
                                                                    <span class="">
                                                                        3/21/24
                                                                    </span>
                                                                    -
                                                                    <span class="">
                                                                        3/30/24
                                                                    </span>
                                                                    <span class="">
                                                                        2 guests
                                                                    </span>
                                                                </p>
                                                            </div>
                                                        </a>
                                                        <div class="bottom_c mt-auto text-right pr-3"><a href="#">
                                                            </a><a href="#" class="text_blue f-13">
                                                                GBP 875
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-6">
                                            <div class="recent_viewItem bg-white rounded-3 shadow-sm">
                                                <a href="#">
                                                </a>
                                                <div class="d-flex item_imgContent"><a href="#">
                                                        <img class="rounded-start" src="/assets/images/details_side1.jpeg">
                                                    </a>
                                                    <div class="right_c p-2 pt-1 w-100 d-flex flex-column"><a href="#">
                                                            <div class="top_c">
                                                                <div class="d-flex align-items-center">
                                                                    <span class="star lh-1">
                                                                        <img src="/assets/images/star.svg" alt="">
                                                                    </span>
                                                                    <span class="star lh-1">
                                                                        <img src="/assets/images/star.svg" alt="">
                                                                    </span>
                                                                    <span class="star lh-1">
                                                                        <img src="/assets/images/star.svg" alt="">
                                                                    </span>
                                                                </div>
                                                                <p class="f-12 lh-1 my-2">
                                                                    {{hotel_id}} </p>
                                                                <p class="mb-0 f-10 lh-1 text-muted">
                                                                    <span class="">
                                                                        3/21/24
                                                                    </span>
                                                                    -
                                                                    <span class="">
                                                                        3/30/24
                                                                    </span>
                                                                    <span class="">
                                                                        2 guests
                                                                    </span>
                                                                </p>
                                                            </div>
                                                        </a>
                                                        <div class="bottom_c mt-auto text-right pr-3"><a href="#">
                                                            </a><a href="#" class="text_blue f-13">
                                                                GBP 875
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-6">
                                            <div class="recent_viewItem bg-white rounded-3 shadow-sm">
                                                <a href="#">
                                                </a>
                                                <div class="d-flex item_imgContent"><a href="#">
                                                        <img class="rounded-start" src="/assets/images/details_side1.jpeg">
                                                    </a>
                                                    <div class="right_c p-2 pt-1 w-100 d-flex flex-column"><a href="#">
                                                            <div class="top_c">
                                                                <div class="d-flex align-items-center">
                                                                    <span class="star lh-1">
                                                                        <img src="/assets/images/star.svg" alt="">
                                                                    </span>
                                                                    <span class="star lh-1">
                                                                        <img src="/assets/images/star.svg" alt="">
                                                                    </span>
                                                                </div>
                                                                <p class="f-12 lh-1 my-2">
                                                                    {{hotel_id}} </p>
                                                                <p class="mb-0 f-10 lh-1 text-muted">
                                                                    <span class="">
                                                                        3/21/24
                                                                    </span>
                                                                    -
                                                                    <span class="">
                                                                        3/30/24
                                                                    </span>
                                                                    <span class="">
                                                                        2 guests
                                                                    </span>
                                                                </p>
                                                            </div>
                                                        </a>
                                                        <div class="bottom_c mt-auto text-right pr-3"><a href="#">
                                                            </a><a href="#" class="text_blue f-13">
                                                                GBP 875
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-6">
                                            <div class="recent_viewItem bg-white rounded-3 shadow-sm">
                                                <a href="#">
                                                </a>
                                                <div class="d-flex item_imgContent"><a href="#">
                                                        <img class="rounded-start" src="/assets/images/details_side1.jpeg">
                                                    </a>
                                                    <div class="right_c p-2 pt-1 w-100 d-flex flex-column"><a href="#">
                                                            <div class="top_c">
                                                                <div class="d-flex align-items-center">
                                                                    <span class="star lh-1">
                                                                        <img src="/assets/images/star.svg" alt="">
                                                                    </span>
                                                                    <span class="star lh-1">
                                                                        <img src="/assets/images/star.svg" alt="">
                                                                    </span>
                                                                    <span class="star lh-1">
                                                                        <img src="/assets/images/star.svg" alt="">
                                                                    </span>
                                                                </div>
                                                                <p class="f-12 lh-1 my-2">
                                                                    {{hotel_id}} </p>
                                                                <p class="mb-0 f-10 lh-1 text-muted">
                                                                    <span class="">
                                                                        3/21/24
                                                                    </span>
                                                                    -
                                                                    <span class="">
                                                                        3/30/24
                                                                    </span>
                                                                    <span class="">
                                                                        2 guests
                                                                    </span>
                                                                </p>
                                                            </div>
                                                        </a>
                                                        <div class="bottom_c mt-auto text-right pr-3"><a href="#">
                                                            </a><a href="#" class="text_blue f-13">
                                                                GBP 875
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-6">
                                            <div class="recent_viewItem bg-white rounded-3 shadow-sm">
                                                <a href="#">
                                                </a>
                                                <div class="d-flex item_imgContent"><a href="#">
                                                        <img class="rounded-start" src="/assets/images/details_side1.jpeg">
                                                    </a>
                                                    <div class="right_c p-2 pt-1 w-100 d-flex flex-column"><a href="#">
                                                            <div class="top_c">
                                                                <div class="d-flex align-items-center">
                                                                    <span class="star lh-1">
                                                                        <img src="/assets/images/star.svg" alt="">
                                                                    </span>
                                                                    <span class="star lh-1">
                                                                        <img src="/assets/images/star.svg" alt="">
                                                                    </span>
                                                                    <span class="star lh-1">
                                                                        <img src="/assets/images/star.svg" alt="">
                                                                    </span>
                                                                </div>
                                                                <p class="f-12 lh-1 my-2">
                                                                    {{hotel_id}} </p>
                                                                <p class="mb-0 f-10 lh-1 text-muted">
                                                                    <span class="">
                                                                        3/21/24
                                                                    </span>
                                                                    -
                                                                    <span class="">
                                                                        3/30/24
                                                                    </span>
                                                                    <span class="">
                                                                        2 guests
                                                                    </span>
                                                                </p>
                                                            </div>
                                                        </a>
                                                        <div class="bottom_c mt-auto text-right pr-3"><a href="#">
                                                            </a><a href="#" class="text_blue f-13">
                                                                GBP 875
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-6">
                                            <div class="recent_viewItem bg-white rounded-3 shadow-sm">
                                                <a href="#">
                                                </a>
                                                <div class="d-flex item_imgContent"><a href="#">
                                                        <img class="rounded-start" src="/assets/images/details_side1.jpeg">
                                                    </a>
                                                    <div class="right_c p-2 pt-1 w-100 d-flex flex-column"><a href="#">
                                                            <div class="top_c">
                                                                <div class="d-flex align-items-center">
                                                                    <span class="star lh-1">
                                                                        <img src="/assets/images/star.svg" alt="">
                                                                    </span>
                                                                    <span class="star lh-1">
                                                                        <img src="/assets/images/star.svg" alt="">
                                                                    </span>
                                                                    <span class="star lh-1">
                                                                        <img src="/assets/images/star.svg" alt="">
                                                                    </span>
                                                                </div>
                                                                <p class="f-12 lh-1 my-2">
                                                                    {{hotel_id}} </p>
                                                                <p class="mb-0 f-10 lh-1 text-muted">
                                                                    <span class="">
                                                                        3/21/24
                                                                    </span>
                                                                    -
                                                                    <span class="">
                                                                        3/30/24
                                                                    </span>
                                                                    <span class="">
                                                                        2 guests
                                                                    </span>
                                                                </p>
                                                            </div>
                                                        </a>
                                                        <div class="bottom_c mt-auto text-right pr-3"><a href="#">
                                                            </a><a href="#" class="text_blue f-13">
                                                                GBP 875
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <a href="javascript:void(0);" class="btn btn-block reviews_btn mt-4">
                                    Show 3 more
                                </a>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--Modal Start Here-->
    <!--ShareModal-->
    <div class="modal fade" id="shareModal" role="dialog">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Share Itinerary</h4>
                    <button type="button" class="close" data-dismiss="modal">×</button>
                </div>
                <form class="share_data-form" id="share_data_from_form" name="flight_searchs">
                    <div class="modal-body">
                        <div class="row"></div>
                        <div class="flight-details" id="share_datas"></div>
                        <div class="sharing-details">
                            <div class="form-group has-error">
                                <label>To</label>
                                <input type="email" name="mail_id" id="mail_id" class="form-control required"
                                    placeholder="To">
                            </div>
                            <div class="form-group has-error">
                                <label>Enter your message here</label>
                                <textarea name="messages" class="form-control required" rows="2"
                                    id="messages"></textarea>
                            </div>
                            <input type="hidden" name="journyType" id="journyType" value="R">
                        </div>
                    </div>
                    <div class="modal-footer">
                        <div class="button-end">
                            <button type="submit" id="share_data_button" class="btn-default"
                                style="color:#fff !important">Share</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <!-- ShareModal -->
    <!--ModifySearch-->
    <!--Loader Modal-->
    <div id="loadingModal" class="modal fade" role="dialog">
        <div class="modal-dialog">
            <!-- Modal content-->
            <div class="modal-content">
                <div class="modal-body">
                    <div class="">
                        <div class="">
                            <div>
                                <a href="#"><img src="http://195.96.138.30/FE/public/images/logo.png"
                                        class="img-responsive loader-logo"></a>
                            </div>
                            <img src="/assets/images/loadingRsltPage.gif" class="img-responsive loader-img">
                            <h5 style="margin-top:10px;text-align:center;font-size:14px;">We are searching
                                the Best Fares for you.This will take only few seconds.</h5>
                        </div>
                    </div>
                    <div class="" style="margin-top:15px;">
                        <div class="from-to-row">
                            <div class="row inner-row">
                                <div class="col-3 col-sm-4 col-md-4 flight-inf">
                                    <div class="inner-divs" style="width:35px;">
                                        <img src="http://195.96.138.30/FE/public/images/depart-icon.png">
                                    </div>
                                    <div class="inner-divs">
                                        <b>LHR</b>
                                        <p>
                                            London </p>
                                    </div>
                                </div>
                                <div class="col-5 col-sm-4 col-md-4 date-inner">
                                    <p>
                                        01 Jan 2024 </p>
                                </div>
                                <div class="col-3 col-sm-4 col-md-4 flight-inf">
                                    <div class="inner-divs return" style="width:35px;">
                                        <img src="http://195.96.138.30/FE/public/images/depart-icon.png">
                                    </div>
                                    <div class="inner-divs">
                                        <b>DXB</b>
                                        <p>
                                            Dubai </p>
                                    </div>
                                </div>
                            </div>
                            <div class="row inner-row">
                                <div class="col-3 col-sm-4 col-md-4 flight-inf">
                                    <div class="inner-divs" style="width:35px;">
                                        <img src="http://195.96.138.30/FE/public/images/depart-icon.png">
                                    </div>
                                    <div class="inner-divs">
                                        <b>DXB</b>
                                        <p>
                                            Dubai </p>
                                    </div>
                                </div>
                                <div class="col-5 col-sm-4 col-md-4 date-inner">
                                    <p>
                                        04 Jan 2024 </p>
                                </div>
                                <div class="col-3 col-sm-4 col-md-4 flight-inf">
                                    <div class="inner-divs return" style="width:35px;">
                                        <img src="http://195.96.138.30/FE/public/images/depart-icon.png">
                                    </div>
                                    <div class="inner-divs">
                                        <b>LHR</b>
                                        <p>
                                            London </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--Session Expire Modal-->
    <div id="compareMessage" class="modal fade" role="dialog">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="text-right">
                    <button type="button" class="close" data-dismiss="modal" style="float:none;">×</button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-xs-12 col-md-12" style="text-align:center;">
                            <img src="http://195.96.138.30/FE/public/images/warn2.jpg" alt="warning-image">
                            <h2 id="compareMsg"></h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--End Of Modal-->
    <!-- notification -->
    <div class="notification" style="display:none;">
        <div class="alert alert-success">
            <strong id="quote_message"></strong>
        </div>
    </div>
</div>