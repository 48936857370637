import { ApplicationConfig } from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { provideClientHydration } from '@angular/platform-browser';
import { withFetch, provideHttpClient } from '@angular/common/http'; // Import HttpClientModule
// import { MatFormFieldModule } from '@angular/material/form-field';
// import { MatOptionModule } from '@angular/material/core'; // or '@angular/material/option'
// import { MatAutocompleteModule } from '@angular/material/autocomplete';
// import { NgModule } from '@angular/core';
// import { MatInputModule } from '@angular/material/input';
 
 
 
export const appConfig: ApplicationConfig = {
  providers: [provideRouter(routes), provideClientHydration(), provideHttpClient(withFetch())]
};